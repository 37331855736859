import React, { useState } from "react";
import logoAcedmyIcone from "../assets/LogoAcademyLogo.svg";
import { loginPagesText } from "../CommonJquery/WebsiteText";
import Loader from "./Loader.js";
import {
  APL_LINK,
  server_post_data,
  login_to_superadmin,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleEmailChange,
  handlePasswordChange,
  check_vaild_save,
  combiled_form_data,
} from "../CommonJquery/CommonJquery";
import { storeData } from "../LocalConnection/LocalConnection.js";
import { useNavigate } from "react-router-dom";
import "./Css/Login.css";

function Login() {
  const navigate = useNavigate();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    const vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      const compiledFormData = combiled_form_data(form_data, null);

      try {
        const response = await server_post_data(url_for_save, compiledFormData);
        setshowLoaderAdmin(false);

        if (response.data.error) {
          alert(response.data.message);
        } else {
          const Response_data = response.data.message.data_admin;
          let Response_designation = response.data.message.data_designation;
          let data_admin_image = response.data.message.data_admin_image;
          let show_mr = "";
          if (Response_data.admin_gender === "Male") {
            show_mr = "Mr. ";
          } else if (Response_data.admin_gender === "Female") {
            show_mr = "Ms. ";
          }
          storeData("useremail", Response_data.admin_email);
          storeData("admin_name", Response_data.admin_name);
          storeData(
            "admin_name_with_gender",
            show_mr + Response_data.admin_name
          );
          storeData("designation_name", Response_designation.designation_name);
          storeData(
            "admin_image",
            APL_LINK + data_admin_image + Response_data.admin_image
          );
          navigate("/Dashboard");
          //console.log(Response_data);
        }
      } catch (error) {
        setshowLoaderAdmin(false);
        console.log(error);
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    const emailError = document.getElementById("emailError");
    emailError.textContent = "";
  };

  const handlePasswordChange = (e) => {
    const passwordError = document.getElementById("passwordError");
    passwordError.textContent = "";
  };

  const validateAndSubmit = () => {
    const userEmail = document.getElementById("useremail").value;
    const userPassword = document.getElementById("userpassword").value;
    const emailError = document.getElementById("emailError");
    const passwordError = document.getElementById("passwordError");
    const emailInput = document.getElementById("useremail");
    const passwordInput = document.getElementById("userpassword");

    let isValid = true;

    // Validate email
    if (userEmail === "") {
      emailError.textContent = "Please enter the email";
      emailInput.classList.add("input-error", "shake");
      isValid = false;
    } else if (!validateEmail(userEmail)) {
      emailError.textContent = "Please enter a correct email";
      emailInput.classList.add("input-error", "shake");
      isValid = false;
    } else if (userEmail !== "admin@logoacademy.co") {
      emailError.textContent = "Email not found";
      emailInput.classList.add("input-error", "shake");
      isValid = false;
    } else {
      emailInput.classList.remove("input-error");
    }

    // Validate password
    if (userPassword === "") {
      passwordError.textContent = "Please enter the password";
      passwordInput.classList.add("input-error", "shake");
      isValid = false;
    } else if (userPassword !== "Triosoft@2023") {
      passwordError.textContent =
        "Wrong password, please enter the correct password";
      passwordInput.classList.add("input-error", "shake");
      isValid = false;
    } else {
      passwordInput.classList.remove("input-error");
    }

    // Remove the shake class after animation completes
    setTimeout(() => {
      emailInput.classList.remove("shake");
      passwordInput.classList.remove("shake");
    }, 300);

    if (isValid) {
      handleSaveChangesdynamic("loginFormData", login_to_superadmin);
    }
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <div className="login">
      {showLoaderAdmin && <Loader />}
      <div className="login_container">
        <div className="row m-0 justify-content-center align-items-center h-100">
          <div className="loginRight2">
            <div className="loginRight">
              <div className="loginFormCol">
                <form id="loginFormData">
                  <div className="logoAcedamyIc">
                    <img className="icnLog" src={logoAcedmyIcone} alt="logo" />
                  </div>
                  <div className="loginFormContainer">
                    <h5>{loginPagesText.logingetstarted}</h5>
                    <span className="invalid_data"></span>
                    <div className="loginInputs">
                      <div className="LoginTxt">
                        <label>Email</label>
                      </div>

                      <div className="email_image">
                        <input
                          type="text"
                          name="useremail"
                          id="useremail"
                          className="trio_email trio_mandatory form-control input_field_custom EmailBGClr"
                          maxLength={75}
                          minLength={3}
                          onChange={(e) => handleEmailChange(e)}
                          autoFocus
                          tabIndex={1}
                        />
                        <span
                          className="condition_error"
                          id="emailError"
                        ></span>
                      </div>
                      <div className="LoginTxt">
                        <div>
                          <label>Password</label>
                        </div>
                      </div>
                      <div className="password_image image_icon_position">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="userpassword"
                          id="userpassword"
                          maxLength={40}
                          minLength={3}
                          className="trio_password trio_mandatory form-control input_field_custom EmailBGClr"
                          onChange={(e) => handlePasswordChange(e)}
                          tabIndex={2}
                        />
                        <span
                          className="eye_icon"
                          onClick={() => setShowPassword(!showPassword)}
                          tabIndex={3}
                        >
                          {showPassword ? (
                            <div className="eye_open"></div>
                          ) : (
                            <div className="eye_close"></div>
                          )}
                        </span>
                        <span
                          className="condition_error"
                          id="passwordError"
                        ></span>
                      </div>
                    </div>
                    <button
                      style={{ color: "white" }}
                      className="loginBtn"
                      type="button"
                      onClick={validateAndSubmit}
                      tabIndex={4}
                    >
                      {loginPagesText.login}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
