import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Header from "./Header";
import Loader from "./Loader.js";
import editImg from "../assets/edit_square.png";
import GreyArrow from "../assets/greyLeftAr.png";
import Person from "../assets/person.png";
import { AddTestimonialText } from "./../CommonJquery/WebsiteText";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleAphabetsChange,
  cencelChanges,
  handleIaphabetnumberChange,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_Testi,
  get_all_testi,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import { useLocation } from "react-router-dom";
function AddTestimonial() {
  const location = useLocation();

  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [editOldImageData, seteditOldImageData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);

  const { id } = useParams();

  console.log(id);

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];
    let new_file_name = keyname + "_show";

    if (!file) {
      alert("Please select an image.");
      return;
    }

    if (file.type.startsWith("image/")) {
      // Validate file size
      if (file.size > 500 * 1024) {
        // 500KB in bytes
        alert("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data, dynaicimage);
    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("old_image_link", editOldImageData);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_testestimonial");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
          handleError("network");
        });
    }
  };

  useEffect(() => {
    const url = currentUrl;
    const parts = url.split("/");
    const start_date = "";
    const end_date = "";
    const flag = "3";
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const master_data_get = async () => {
  const url = currentUrl;
  const parts = url.split("/");
  let call_id = "0";
  
  if (parts.length !== 1) {
    call_id = parts[1];
  }
  
  if (call_id != "0") {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("call_id", call_id);
    fd.append("flag", "3");
    
    await server_post_data(get_all_testi, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const data = Response.data.message.data;
          
          if (data.length > 0) {
            // Check if primary_id exists, then filter data by primary_id
            const filteredData = data.filter(item => item.primary_id == call_id);
            
            if (filteredData.length > 0) {
              const selectedData = filteredData[0];
              
              // Set the data in the variables
              setEditorDatMainID(selectedData.primary_id);
              seteditStaffData(selectedData);
              seteditOldImageData(selectedData.image_link);
              
              setDynaicimage({
                event_list_image_show:
                  APL_LINK +
                  Response.data.message.image_path +
                  selectedData.image_link,
              });
            } else {
              // Handle case where no matching data is found
              handleError("No data found for the provided ID.");
            }
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  }
};

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                {location.pathname.includes("/edit_staff") ? (
                  <p>{AddTestimonialText.update_testi}</p>
                ) : (
                  <p>{AddTestimonialText.Addtesti}</p>
                )}
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="addStaffForms">
                <form id="addNewStaff">
                  <div className="row m-0">
                    <div className="personalForm">
                      <h3>{AddTestimonialText.TestiDetail}</h3>
                      <div className="Testiii_container">
                        <div className="nameAndText">
                          <div className="addstaffInputs">
                            <div
                              className={
                                dynaicimage ? "" : " inputDiv1 imgInputPic"
                              }
                            >
                              <label
                                htmlFor="event_list_image"
                                className="imgInptImg"
                              >
                                {dynaicimage &&
                                dynaicimage.event_list_image_show ? (
                                  <div
                                    className="imgDiv"
                                    htmlFor="event_list_image"
                                  >
                                    <img
                                      src={dynaicimage.event_list_image_show}
                                      onError={(e) => (e.target.src = Person)}
                                      alt="Preview"
                                    />
                                    <img
                                      className="profileImgEdit"
                                      src={editImg}
                                      alt="Barley's Dashboard"
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <img
                                      src={Person}
                                      alt="Barley's Dashboard"
                                    />
                                    <p className="m-0">
                                      {AddTestimonialText.Add_Image}
                                    </p>
                                    <div className="imgValidationalert ">
                                      <p>
                                        {AddTestimonialText.Image_Validation}
                                      </p>
                                      <p>
                                        {AddTestimonialText.Image_Validation2}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </label>

                              <div
                                className={
                                  "inpContainer imginputContainer flex-column "
                                }
                              >
                                <input
                                  name="upload_img_new"
                                  id="event_list_image"
                                  type="file"
                                  onChange={handleFileChangedynamic(
                                    "event_list_image"
                                  )}
                                  className={
                                    dynaicimage &&
                                    dynaicimage.event_list_image_show
                                      ? "form-control"
                                      : " form-control"
                                  }
                                  accept=".jpg,.jpeg,.png"
                                  hidden
                                />
                                <span className="condition_error"></span>
                              </div>
                            </div>

                            <div className="NmetxtCont">
                              <div className="col-xl-5">
                                <label
                                  style={{
                                    fontSize: "13px",
                                    color: "#6f6f6f",
                                  }}
                                >
                                  Name
                                </label>
                                <div className="person_image image_icon_position image_icon_position1">
                                  <input
                                    type="text"
                                    id="name"
                                    name="name_data"
                                    tabIndex="1"
                                    placeholder={
                                      AddTestimonialText.Placeholder_Name
                                    }
                                    minLength={3}
                                    maxLength={75}
                                    className=" trio_mandatory form-control  input_field_custom1 "
                                    onInput={handleAphabetsChange}
                                    defaultValue={editStaffData.name || ""}
                                  />
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                {" "}
                                <div className="inpContainer">
                                  <label
                                    style={{
                                      fontSize: "13px",
                                      color: "#6f6f6f",
                                    }}
                                  >
                                    Designation
                                  </label>
                                  <div className="person_image image_icon_position image_icon_position1">
                                    <input
                                      type="text"
                                      id="designation"
                                      name="designation_data"
                                      tabIndex="1"
                                      placeholder={
                                        AddTestimonialText.Placeholder_Name2
                                      }
                                      minLength={3}
                                      maxLength={75}
                                      className=" trio_mandatory form-control  input_field_custom1 "
                                      onInput={handleAphabetsChange}
                                      defaultValue={
                                        editStaffData.designation || ""
                                      }
                                    />
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="inpContainer">
                            <div className="msgContainer">
                              <div className="inpContainer">
                                <label
                                  style={{
                                    fontSize: "13px",
                                    color: "#6f6f6f",
                                  }}
                                >
                                  Message
                                </label>
                                <div className="person_image image_icon_position image_icon_position1">
                                  <textarea
                                    type="text"
                                    id="testimony_msg"
                                    name="testimony_msg_data"
                                    onInput={handleIaphabetnumberChange}
                                    className=" trio_mandatory form-control  input_field_custom1 "
                                    placeholder={
                                      AddTestimonialText.Placeholder_Name3
                                    }
                                    defaultValue={
                                      editStaffData.testimony_msg || ""
                                    }
                                  />
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>

                            <div
                              className="saveFormBtns"
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <button
                                className="btnCancel"
                                type="button"
                                onClick={cencelChanges}
                              >
                                {AddTestimonialText.cencel_text}
                              </button>

                              <button
                                className="btn btn-primary Create_Right_Btn btnSave"
                                type="button"
                                onClick={() =>
                                  handleSaveChangesdynamic(
                                    "addNewStaff",
                                    save_update_Testi
                                  )
                                }
                              >
                                {location.pathname.includes(
                                  "/edit_testestimonial"
                                )
                                  ? AddTestimonialText.update_text
                                  : AddTestimonialText.save_text}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTestimonial;
