import React, { useState, useEffect } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import Delete from "../assets/delete.svg";
import EmployeeImg from "../assets/person_pin_circle.svg";
import EditBtn from "../assets/edit_square.png";

import { Modal, Button } from "react-bootstrap";
import { ViewTestimonialText } from "./../CommonJquery/WebsiteText";
import {
  server_post_data,
  APL_LINK,
  get_all_testi,
  delete_testimonial_field,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  formatDateStringdot,
  handleConfimDeleteClick,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
import { Link } from "react-router-dom";

function Viewtestimonial() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [DelteTestinomonial, setDelteTestinomonial] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);

    await server_post_data(get_all_testi, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfilteredData(Response.data.message.data);
          setsStaffImageLinkData(Response.data.message.image_path);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  const master_data_action_update = async (call_id, for_status_final) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("main_id", call_id);
    console.log(call_id);
    fd.append("flag", "d");

    await server_post_data(delete_testimonial_field, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          master_data_get("", "", 1, "0");
        }
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  console.log(filteredData);

  //delete and upd
  const [expanded, setExpanded] = useState({});

  const toggleMessage = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handlePackModalDelete = (id) => {
    setSelectedItemId(id);

    setDelteTestinomonial(true);
  };
  const handleconfirmDelete = () => {
    if (selectedItemId) {
      master_data_action_update(selectedItemId);
      setDelteTestinomonial(false);
    }
  };
  const handleCancelDelete = () => {
    setDelteTestinomonial(false);
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head py-3">
              <div className="pageNameDiv">
                <p>{ViewTestimonialText.View_testi}</p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
              <div className="addTestiBtn">
                <Link to="/add_testestimonial">
                  <button type="button" className="btnAddStaff add_staff">
                    {ViewTestimonialText.Add_testi}
                  </button>
                </Link>
              </div>
            </div>

            <div className=" page_body">
              <div className="viewStaff">
                <div className="viewStaff_head viewTestiDataTitle">
                  <div className="row m-0">
                    <div className="col-xl-1 col-md-4 centerAlgin">
                      {ViewTestimonialText.img}
                    </div>
                    <div className="col-xl-2 col-md-4 centerAlgin startDattt">
                      {ViewTestimonialText.Start_Date}
                    </div>
                    <div className="col-xl-2 col-md-4 centerAlgin">
                      {ViewTestimonialText.Name}
                    </div>
                    <div className="col-xl-2 col-md-4 centerAlgin designation">
                      {ViewTestimonialText.Designation_Txt}
                    </div>
                    <div className="col-xl-3 col-md-4 centerAlgin">
                      {ViewTestimonialText.testimony_msg}
                    </div>
                    <div className="col-xl-2 col-md-4 centerAlgin ActionTitllle">
                      {ViewTestimonialText.ActionBtn}
                    </div>
                  </div>
                </div>

                <div className="viewStaff_table">
                  <div className="viewStaff_table_container">
                    {!filteredData
                      ? []
                      : filteredData.map((option, index) => (
                          <div className="row m-0 mb-2" key={index}>
                            <div className="col-md-12">
                              <div className="viewStaff_table_Body">
                                <div className="viewStaff_table_item">
                                  <div className="row m-0">
                                    <div className="col-xl-1 col-md-4 p-0">
                                      <div className="prsnlDetails">
                                        <img
                                          src={
                                            APL_LINK +
                                            StaffImageLinkData +
                                            option.image_link
                                          }
                                          onError={(e) =>
                                            (e.target.src = EmployeeImg)
                                          }
                                          alt="Barley's Dashboard"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xl-2 col-md-4 centerAlgin">
                                      <div className="prsnlDetailsText">
                                        <p>
                                          {" "}
                                          {formatDateStringdot(
                                            option.entry_date
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-xl-2 col-md-4 centerAlgin ">
                                      <div className="prsnlDetailsText paddingLeftCLassName ">
                                        <p>{option.name} </p>
                                      </div>
                                    </div>
                                    <div className="col-xl-2 col-md-4 centerAlgin">
                                      <div className="prsnlDetailsText empDetailsText paddingLeftCLassDesign">
                                        <p>{option.designation}</p>
                                      </div>
                                    </div>
                                    <div className="col-xl-2 col-md-4 centerAlgin">
                                      <div className="prsnlDetailsText empDetailsText paddingLeftCLass">
                                        <p>
                                          {expanded[option.primary_id]
                                            ? option.testimony_msg
                                            : option.testimony_msg.length > 50
                                            ? option.testimony_msg.substring(
                                                0,
                                                50
                                              ) + "..."
                                            : option.testimony_msg}
                                        </p>
                                        {option.testimony_msg.length > 50 && (
                                          <button
                                            className="moreTestiBtn"
                                            onClick={() =>
                                              toggleMessage(option.primary_id)
                                            }
                                          >
                                            {expanded[option.primary_id]
                                              ? "Read Less"
                                              : "Read More"}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-xl-3 col-md-12 paddingZero centerAlginHorizontal">
                                      <div className="editstaff_container">
                                        <div className="actionsSections">
                                          <Link
                                            to={`/edit_testestimonial/${option.primary_id}`}
                                          >
                                            <button
                                              className="editStaffBtn Edti_Staff_btn"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="left"
                                              title="Edit staff"
                                            >
                                              <img
                                                src={EditBtn}
                                                alt="Barley's Dashboard"
                                              />
                                            </button>
                                          </Link>
                                        </div>
                                        <button
                                          onClick={() => {
                                            console.log(option.primary_id);
                                            handlePackModalDelete(
                                              option.primary_id
                                            );
                                          }}
                                          style={{
                                            background: "transparent",
                                            border: "none",
                                          }}
                                        >
                                          <img
                                            src={Delete}
                                            alt="Barley's Dashboard"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    {filteredData.length === 0 && (
                      <div className="viewStaff_table_head">
                        <div className="row m-0">
                          <div className="col-xl-12 centerAlgin text_align_center">
                            No Results Found
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/**----Delete the template---- */}
      <Modal
        className="deleteModddl"
        show={DelteTestinomonial}
        onHide={handleCancelDelete}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <div className="btttonDelete">
            {" "}
            <button onClick={handleCancelDelete}>No</button>
            <button onClick={handleconfirmDelete}>Yes</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Viewtestimonial;
