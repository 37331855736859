import React, { useRef, useEffect, useState } from "react";
import fontFam from "../assets/fontFam.svg";
import fontFamilYblueee from "../assets/fontFamilYblueee.svg";
import Header from "./Header";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import Select from "react-select/creatable";
import { components } from "react-select";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BluePemcil from "../assets/bluePencil.svg";
import BlackAllLogoIcn from "../assets/blackAllLogoIcon.svg";
import $ from "jquery";
import axios from "axios";
import AllLogoIcn from "../assets/AllLogo.svg";
import EDitIcn from "../assets/PencilIconEditor.svg";
import dropdown from "../assets/arrow_drop_down_24px.svg";
import crossIcn from "../assets/crossIcon.png";
import PlusIconAdd from "../assets/plusIcon_copy.svg";
import redoIcn from "../assets/redoIcn.svg";
import Down23 from "../assets/down-arrow23.png";
import redoIcnBlack from "../assets/redoIcnBlack.svg";
import Delet from "../assets/deletWhite.png";
import DeletRed from "../assets/delete.svg";
import tickmark from "../assets/TickImg3.svg";
import plus from "../assets/plusIcon.svg";
import MinusIcon from "../assets/lessIcn.svg";
import undoIcn from "../assets/undoIcn.svg";
import undoIcnBlack from "../assets/undoIcnBlack.svg";
import lessIcn from "../assets/lessIcn.svg";
import graterIcn from "../assets/graterIcn.svg";

import CaptallSmallIcn from "../assets/aA.svg";
import BoldIcn from "../assets/bold-01.svg";
import IteLicIcn from "../assets/italics-01.svg";
import possitionIcn from "../assets/PossItionIcn.svg";
import uploadImgOptions from "../assets/threeDotNav.svg";
import letterIcon from "../assets/letterIcon.svg";
import letterIconBlue from "../assets/letterIconBlue.svg";
import backLayer from "../assets/backLayer.svg";
import frontLayer from "../assets/frontLayer.svg";
import letterSpacing from "../assets/letterSpacing.svg";
import EmogiIcn from "../assets/add_reaction.svg";
import add_reactionBlue from "../assets/add_reactionBlue.svg";
import TxtIcn from "../assets/TextIcn.svg";
import TextIcnBlue from "../assets/TextIcnBlue.svg";
import TemplGridIcn from "../assets/TempltIcn.svg";
import TemplGridIcnBlue from "../assets/TemplGridIcnBlue.svg";
import ShapeIcn from "../assets/shapesIcn.svg";
import ShapeIcnBlue from "../assets/ShapeIcnBlue.svg";
import shape1 from "../assets/shape1.svg";
import { toast } from "react-toastify";

import shape2 from "../assets/shape2.svg";
import line1 from "../assets/line1.svg";
import fram1 from "../assets/fram1.svg";
import framicn from "../assets/framsicn.svg";
import UploadIcn from "../assets/UploadIcn.svg";
import UploadIcnBlue from "../assets/UploadIcnBlue.svg";

import objectSvg from "../assets/objectSvg.svg";

import BackIcn from "../assets/back-arrow.svg";
import checkListTemplate from "../assets/checkListEditor.svg";
import objectDuplicateIcn from "../assets/objDuplicate.svg";
import objectDltIcn from "../assets/DltIcon.svg";
import BackgroundWindowIcn from "../assets/BackgroundIcn.svg";
import BackgroundWindowIcnBlue from "../assets/BackgroundWindowIcnBlue.svg";
import OpeSityObjctIcn from "../assets/opacityObject.svg";
import topALigment from "../assets/topALigment.svg";
import centerALigment from "../assets/centerALigment.svg";

import removeIcn from "../assets/removeIcn.svg";
import downloadicn from "../assets/downloadicn.svg";
import rightmakImg from "../assets/rightmakImg.svg";
import crossIconModa from "../assets/crossIconModa.png";
import markRght from "../assets/markRght.svg";
import questionMark from "../assets/questionMark.svg";
import aligmentLeft from "../assets/aligmentLeft.svg";
import aligmentRight from "../assets/aligmntRight.svg";
import aligmntIcn from "../assets/align-center.svg";
import { fabric } from "fabric";
// import "fabric-history";
import Icons from "./Icons.js";
import {
  ViewtemplateText,
  options_select_drop_feedback,
} from "./../CommonJquery/WebsiteText";
import "../Components/Css/TextEdit.css";
import "../Components/Css/Canvas.css";
import "../Components/Css/ShowLogos.css";
import {
  storeData,
  retrieveData,
  storeDataSubscription,
} from "../LocalConnection/LocalConnection.js";
import {
  server_post_data,
  save_update_admin_templates,
  APL_LINK,
  Remove_upload_img,
  get_admin_template_by_id,
  get_canvas_editor_data,
  insert_img_data,
  post_customfont_data,
  savecolorcombination,
  get_color_combinations,
  save_update_industry,
  save_update_shape,
  delete_shape,
  get_more_image_uploads_blog,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleConfimDeleteClick,
  handleError,
  handleAphabetsChange,
  handleSuccess,
  formatDateStringdot,
} from "../CommonJquery/CommonJquery";
import { saveAs } from "file-saver";
import Loader from "./Loader.js";
import { useParams } from "react-router-dom";
import IndustryModal from "./industryModal.js";

let mainCanvas;
let colorCombinations;
let ChangeIconSelect;
let Haveselected = 0;
let objectBeingCropped = null;
let deletedObjects = [];
let redoObjects = [];
let clipboard = null;
let colorTimeout;
let customFonts = [];
let selectedColorIndex;
let letUndoStack = [];
let state = [];
let mods = 0;
let firstcanvas = 1;
let isRightImg;
let isshapbcg;
let startindexelement = 50;
let endindexelement = 200;
let stopelement = 0;
let iconSelectionArray = [];
let isMoving = false;
let isSpacingChanged = false;
let isColorChanged1 = false;
let isColorChanged2 = false;
let selectedLogo = ["0", "0", "0", "0", "0", "0", "0", "0", "0"];

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
for (let i = 0; i < selectedLogo.length; i++) {
  selectedLogo[i] = getRandomInt(0, 4).toString();
}

const CanvasEditor = () => {
  let main_id = 0;
  const dropdownItems = [
    "Abstract Marks",
    "Letterfroms",
    "Pictorial Marks",
    "Wordmarks",
    "Monogram",
    "Negative Space Marks",
    "Logo System",
    "Pictograms",
  ];
  // const dropdownItems2 = [
  //   "Logo",
  //   "Letterhead",
  //   "Envelop",
  //   "Business Card",
  //   "Invitation Card",
  // ];

  const [selectedDescription, setSelectedDescription] = useState("Logo");
  const [GroupUngroup, setGroupUngroup] = useState("Group");
  const images1 = [
    {
      description: "Logo",
      route: "/createlogohome",
      height: "400",
      width: "400",
    },
    {
      description: "Letterhead",
      route: "/home/Letterhead",
      height: "1124.41",
      width: "793.70",
    },
    {
      description: "Envelop",
      route: "/home/Envelops",
      height: "415.75",
      width: "793.70",
    },
    {
      description: "Business Card",
      route: "/home/Businesscard",
      height: "211.42",
      width: "362.20",
    },
    {
      description: "Invitation",
      route: "/home/InvitationCard",
      height: "672",
      width: "480",
    },
  ];

  //const dropdownItems2 = ["Logo","Letterhead"];
  const dropdownItems2 = images1;
  const [selectedItem, setSelectedItem] = useState(dropdownItems[0]);
  const [selectedItem2, setSelectedItem2] = useState();
  const [isbgcolorshape, setisbgcolorshape] = useState(true);
  let data_type_id = dropdownItems[0];
  const { id, data_type } = useParams();
  if (id) {
    main_id = id;
  }
  if (data_type) {
    data_type_id = data_type;
  }
  const location = useLocation();
  const mainCanvasRef = useRef(null);
  const resizeRef = useRef(null);
  const downloadRef = useRef(null);
  const navRef = useRef(null);
  const aligmentRef = useRef(null);
  const PosstionLayer = useRef(null);
  const FlipLayer = useRef(null);
  const HideDwnUpRef = useRef(null);
  const uploadRef = useRef(null);

  const colorPickerRef = useRef(null);

  const opacityInputRef = useRef(null);

  const containerRefs = useRef([]);
  const letterRef = useRef(null);
  const ShapesCont = useRef(null);

  const [canvasColor, setCanvasColor] = useState("white");
  const [company_nametext, setcompany_nametext] = useState(false);
  const [slogantext, setslogantext] = useState(false);
  const [lettertext1, setslettertext1] = useState(false);
  const [lettertext2, setslettertext2] = useState(false);
  const [lettertext3, setslettertext3] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [secondaryCanvasDataURL, setSecondaryCanvasDataURL] = useState("");

  const [templatejson, setTemplateJson] = useState("");
  const [selectedText, setSelectedText] = useState(null);
  const [selectedFontSize, setSelectedFontSize] = useState(20);
  const [isOpenshape, setIsOpenshape] = useState(false);
  const [isOpentext, setIsOpentext] = useState(false);
  const [isOpenBg, setIsOpenBg] = useState(false);
  const [isOpenicon, setIsOpenicon] = useState(data_type === "letterhead");
  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const [isOpenuploads, setIsOpenuploads] = useState(false);
  const [isColor, setIsColor] = useState(false);
  const [isletter, setIsletter] = useState(false);
  const [isTextToolVisible, setIsTextToolVisible] = useState(false);
  const [isObjectSelected, setIsObjectSelected] = useState(false);
  const [isGroupObj, setIsGroupObj] = useState(false);
  const [opacity, setOpacity] = useState(100);
  const [showOpacityInput, setShowOpacityInput] = useState(false);
  //shapes new screen
  const [currentContent, setCurrentContent] = useState("main");
  const [filteredData, setfilteredData] = useState([]);
  const [showFlipButtons, setShowFlipButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSecondButton, setShowSecondButton] = useState(false);
  const [showSecondButton2, setShowSecondButton2] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [GetUploadImg, SetUploadImg] = useState(false);
  const [Gettemplates, Settemplates] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible4, setIsVisible4] = useState(false);

  const [isManualSetup, setIsManualSetup] = useState(false);
  const [isPossitionsLayers, SetPossitionsLayers] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [, setRender] = useState(0); // Dummy state to force re-render
  const [hovered7, setHovered7] = useState(false);
  const [hovered8, setHovered8] = useState(false);
  const [hovered9, setHovered9] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [selectedTab, setSelectedTab] = useState("company_name");

  const [activeColor, setActiveColor] = useState(null);
  const [showResize, setShowResize] = useState(false);
  const [showDownload, setDownload] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);

  const [isActive7, setIsActive7] = useState(false);
  const [isActive8, setIsActive8] = useState(false);
  const [isActive9, setIsActive9] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiveTwo, setIsActiveTwo] = useState(false);

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const [position, setPosition] = useState({ left: 100, top: 100 });
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [ShowNext, setShowNext] = useState(false);
  const [logos, setlogos] = useState([]);
  const [selectedLogoData, setSelectedLogoData] = useState(null);
  const [fonts, setFonts] = useState([]);
  const [buttonColor, setButtonColor] = useState("#6473fd");
  const [zoomLevel, setZoomLevel] = useState(100);
  const [IMAGEPATH, setIMAGEPATH] = useState("main");
  const [IconShape, SetIconShape] = useState([]);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [colorcodeloop, setcolorcodeloop] = useState([]);
  const [isCropMode, setIsCropMode] = useState(false);
  const [selectedFontFamily, setSelectedFontFamily] = useState("");
  const [categorySearchQuery, setCategorySearchQuery] = useState("");
  const [fontSearchQuery, setFontSearchQuery] = useState("");
  const [initialFontCount, setInitialFontCount] = useState(12);
  const [filteredFonts, setFilteredFonts] = useState([]);
  const [ShowIndustryModal, setShowIndustry] = useState(false);

  const [selectedFontCanvasFamily, setSelectedCanvasFontFamily] = useState("");
  const [uploadedFonts, setUploadedFonts] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [colorCount, setColorCount] = useState(0);

  const inputRefs = [useRef(null), useRef(null), useRef(null)];
  const [selectedIconId, setSelectedIconId] = useState(null);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [isButtonChecked, setIsButtonChecked] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [checkedIconId, setCheckedIconId] = useState(null);
  const [category, setCategory] = useState("");
  const [file, setFile] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [fontCategories, setFontCategories] = useState([]);
  const [showFontModal, setshowFontModal] = useState(false);
  const [replaceText, setReplaceText] = useState(false);
  const [currentTextObject, setCurrentTextObject] = useState(null);
  const [selectedLetter, setSelectedLetter] = useState("Letter_first");
  const initialColor = ["", "", ""]; // Initialize with empty strings for easier management
  const [setColors, setSetColors] = useState([]);
  const [color, setColor] = useState(initialColor);
  const [iconchngcolor, seticonchngcolor] = useState("#000000");
  const [isLetterSpacingVisible, setLetterSpacingVisible] = useState(false);
  const [letterSpacingValue, setLetterSpacingValue] = useState(0);
  const [alignmentPosition, setAlignmentPosition] = useState("left");
  const [inputValue, setInputValue] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsCata, setSelectedOptionsCata] = useState([]);
  // const [industryModal, setIndustryModal] = useState(false);
  const [addIndustryModal, setAddIndustryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    value: "All",
    label: "All",
  });
  const [CanvasVariant, setCanvasVariant] = useState([]);
  const [CanvasColorVariant, setCanvasColorVariant] = useState();
  const [heightSpacingValue, setheightSpacingValue] = useState(0);

  const [selectedCategories, setSelectedCategories] = useState([]); // State for each new industry's selected categories
  //home icone change on the hver
  const [isHovered, setIsHovered] = useState(false);
  const [shopcolor, setshopcolor] = useState(true);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [currentOptionsType, setCurrentOptionsType] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1); // Keep track of the active icon
  const [activeIndexImg, setActiveIndexImg] = useState(); // Keep track of the active icon
  const navigate = useNavigate();
  useEffect(() => {
    master_data_get_full();
    get_color_combinations_fn();
    select_dropdown2(retrieveData("templatetype"));
    setSelectedDescription("Logo");
  }, []);

  // console.log(selectedOptions);

  useEffect(() => {}, [CanvasColorVariant]);

  const hoverImage = (index) => {
    setHoveredImage(index);
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  //colorCombinations.forEach((group) => shuffleArray(group)); ///if i comment this so shuffle stop

  console.log("iconchngcolor :", iconchngcolor)


  useEffect(() => {}, [colorcodeloop]);
  const master_data_get_full = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("call_id", main_id);
    fd.append("template_type", data_type_id);
    fd.append("template_id", main_id);
    //fd.append("colorCombinations", JSON.stringify(colorCombinations));
    await server_post_data(get_canvas_editor_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // console.log(Response.data.message.data_shapes);
          // if (
          //   Response.data.message.data_template &&
          //   Response.data.message.data_template.length > 0
          // ) {
          //   setTemplateJson(
          //     Response.data.message.data_template[0].template_canvas
          //   );
          //   whenRenderFunction(
          //     true,
          //     Response.data.message.data_template[0].template_canvas
          //   );
          //   ChangeIconSelect =
          //     Response.data.message.data_template[0].change_icon_id;
          // }

          //console.log(Response.data.message.data_template[0].change_icon_id);
          const currentUrl = location.pathname.substring(1);
          if (currentUrl != "admin_canvas_new") {
            const colorsbck =
              Response.data.message.data_template[0].preferred_color.split(",");
            for (let i = 0; i < 3; i++) {
              if (!colorsbck[i]) {
                colorsbck[i] = ""; // Ensure the element is blank if it's empty or undefined
              }
            }
            const newColors = [colorsbck[0], colorsbck[1], colorsbck[2]];
            setColor(newColors);

            const logotype = Response.data.message.data_template[0].logotype;
            //const selectedIteming =  dropdownItems.indexOf(logotype);
            setSelectedItem(logotype);
            setSelectedItem2(
              Response.data.message.data_template[0].template_type
            );
            setTemplateName(
              Response.data.message.data_template[0].template_name
            );

            const dataIndustry = Response.data.message.data_industry;

            // Create a mapping of industries to their related industries
            const industryMapping = dataIndustry.reduce((acc, industry) => {
              acc[industry.label] = industry.related_industry_name
                ? industry.related_industry_name
                    .split(",")
                    .map((name) => ({ label: name.trim(), value: name.trim() }))
                : [];
              return acc;
            }, {});

            const templateArray =
              Response.data.message.data_template[0].template_industry
                .split(",")
                .map((item) => ({
                  label: item.trim(),
                  value: item.trim(),
                  relatedIndustries: industryMapping[item.trim()] || [],
                }));
            setSelectedindustry(templateArray);

            // console.log(
            //   Response.data.message.data_template[0].different_color_shade
            // );

            const different_color_shadeString =
              Response.data.message.data_template[0].different_color_shade;
            if (different_color_shadeString) {
              let different_color_shade;
              different_color_shade = JSON.parse(different_color_shadeString);
              let idCounter = 0;
              for (let item in different_color_shade) {
                if (different_color_shade[item] != "same") {
                  selectedLogo[idCounter] = different_color_shade[item].index;
                } else {
                  selectedLogo[idCounter] = 0;
                }
                idCounter++;
              }
            }
          }

          ///console.log(Response.data.message.image_uploads_blog)

          SetUploadImg(Response.data.message.image_uploads_blog);
          setfilteredData(Response.data.message.data_shapes);
          SetIconShape(Response.data.message.data_shapes);

          setIMAGEPATH(Response.data.message.image_path);

          setUploadedFonts(Response.data.message.custom_font);

          setIndustryData(Response.data.message.data_industry);

          const fontlist = Response.data.message.fontlist;
          const customFonts = Response.data.message.custom_font;
          const combinedFonts = [...fontlist, ...customFonts];
          //SetFonts(combinedFonts);
          setFonts(combinedFonts);
          const allCategories = [
            ...new Set([
              ...fontlist.map((font) => font.category),
              ...customFonts.map((font) => font.category),
            ]),
          ];
          setFontCategories(allCategories);
          filterFonts(fontSearchQuery, selectedCategory);
          //loadFonts(combinedFonts, Response);
          combinedFonts.forEach((font) => {
            const fontFace = new FontFace(font.family, `url(${font.src_file})`);
            fontFace
              .load()
              .then((loadedFace) => {
                document.fonts.add(loadedFace);
              })
              .catch((error) => {
                ///console.error(`Error loading font "${font.family}":`, error);
              });
          });

          // Filter and map fonts for style tag
          const fontStyles = Response.data.message.fontlist
            .filter((font) => font.files && font.files.regular)
            .map((font) => {
              return `@font-face {
                font-family: '${font.family}';
                font-style: normal;
                font-weight: 400;
                src: url(${font.files.regular});
              }`;
            })
            .join("\n");

          const styleElement = document.createElement("style");
          styleElement.textContent = fontStyles;
          document.head.appendChild(styleElement);

          if (
            Response.data.message.data_template &&
            Response.data.message.data_template.length > 0
          ) {
            setTemplateJson(
              Response.data.message.data_template[0].template_canvas
            );
            whenRenderFunction(
              true,
              Response.data.message.data_template[0].template_canvas
            );
            ChangeIconSelect =
              Response.data.message.data_template[0].change_icon_id;
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };
  // console.log(industryData);
  const master_data_action = async (call_id) => {
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("main_id", call_id);
      fd.append("flag", "3");
      try {
        const Response = await server_post_data(Remove_upload_img, fd);
        setshowLoaderAdmin(false);

        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setIsVisible4(false);
          handleSuccess("Item deleted successfully!");
          document.querySelector(".deletediv" + call_id)?.remove();
          //master_data_get_full();
        }
      } catch (error) {
        setshowLoaderAdmin(false);
        handleError("An error occurred. Please try again.");
      }
    }
  };

  const master_data_action_shapes = async (call_id) => {
    console.log(call_id);
    if (handleConfimDeleteClick()) {
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("main_id", call_id);
      fd.append("flag", "3");
      try {
        const Response = await server_post_data(delete_shape, fd);
        setshowLoaderAdmin(false);

        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setIsVisible4(false);
          handleSuccess("Item deleted successfully!");
          master_data_get_full();
        }
      } catch (error) {
        setshowLoaderAdmin(false);
        handleError("An error occurred. Please try again.");
      }
    }
  };

  const get_more_elements = async () => {
    if (stopelement == 0) {
      stopelement = 1; //taki multiple scroll applied nhi ho same time pe
      setshowLoaderAdmin(true);
      const fd = new FormData();
      fd.append("main_id", "");
      fd.append("startindexelement", startindexelement);
      fd.append("endindexelement", endindexelement);
      try {
        const Response = await server_post_data(
          get_more_image_uploads_blog,
          fd
        );
        if (Response.data.error) {
          setshowLoaderAdmin(false);
          handleError(Response.data.message);
        } else {
          setshowLoaderAdmin(false);
          //colorCombinations = Response.data.data;
          if (startindexelement == 0) {
            SetUploadImg(Response.data.message.image_uploads_blog);
          } else {
            SetUploadImg((prevImages) => [
              ...prevImages,
              ...Response.data.message.image_uploads_blog,
            ]);
          }
          startindexelement = startindexelement + 150;
          endindexelement = endindexelement + 150;
          if (Response.data.message.image_uploads_blog.length < 150) {
            stopelement = 1;
          } else {
            stopelement = 0;
          }
          //console.log(colorCombinations);
        }
      } catch (error) {
        setshowLoaderAdmin(false);
        handleError("An error occurred. Please try again.");
      }
    }
  };

  const get_color_combinations_fn = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", call_id);
    try {
      const Response = await server_post_data(get_color_combinations, fd);
      if (Response.data.error) {
        handleError(Response.data.message);
      } else {
        colorCombinations = Response.data.data;
        //console.log(colorCombinations);
      }
    } catch (error) {
      handleError("An error occurred. Please try again.");
    }
  };

  const master_data_templates = async (flag, call_id, use_flag = 0) => {
    setshowLoaderAdmin(true);

    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("use_flag", use_flag);

    await server_post_data(get_admin_template_by_id, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Settemplates(
            Response.data.message.data.map((item) => {
              try {
                return JSON.parse(item.template_canvas);
              } catch (error) {
                console.error(error);
                return null; // Or handle the error appropriately
              }
            })
          );
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const convertCanvasToJPG = (canvs) => {
    return new Promise((resolve, reject) => {
      try {
        // Convert the canvas to a JPG data URL
        const canvas = canvs;
        const jpgDataURL = canvas.toDataURL("image/jpeg", 1.0);
        resolve(jpgDataURL);
      } catch (error) {
        reject(error);
      }
    });
  };

  const base64ToBlob = (base64, mime) => {
    // Check if base64 string is correct
    if (!base64 || !mime) {
      throw new Error("Invalid Base64 string or MIME type");
    }

    // Handle Data URL format
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

    // Create a buffer and Uint8Array to handle byte data
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mime || mimeString });
  };

  const saveCanvasAsJSON = async () => {
    let canvaschng = 0;
    mainCanvas._objects.forEach((object, index) => {
      if (object.type === "image") {
        canvaschng++;
      }
      // Check if the object is of type 'text', 'i-text', or 'textbox'
      else if (
        object.type === "text" ||
        object.type === "i-text" ||
        object.type === "textbox"
      ) {
        canvaschng++;
      } else {
        //console.log(`Object ${index} is of type: ${object.type}.`); // Logs other types if present
      }
    });
    if (canvaschng > 0) {
      const template_name = document.getElementById("template_name").value;

      const template_industry = Selectedindustry.map(
        (industry) => industry.label
      ).join(",");

      //console.log(template_industry);
      let trimvl;
      let trimvl2;
      let trimvl3;
      let resultary;
      let colorsavearray = {};
      $(".selectedLgo").each(function () {
        trimvl = $(this).attr("keyattr");
        trimvl2 = trimvl.split("_"); //colorindex
        trimvl3 = trimvl2[0].split("selectedLgo"); //index
        for (let array of colorCombinations[trimvl2[1]]) {
          //console.log(array.index);
          //resultary = array.find((item) => item.index === trimvl3[1]);
          //if (resultary) break;
          if (array.index == trimvl3[1]) {
            resultary = array;
            if (resultary) break;
          }
        }
        //console.log(resultary);
        //console.log(trimvl2[1]);
        //console.log(colorCombinations);
        //console.log(trimvl3[1]);
        //console.log(resultary);
        //resultary = colorCombinations[trimvl2[1]][0];
        const colorItemKey = Object.keys(colorItems[trimvl2[1]]).find(
          (key) => colorItems[trimvl2[1]][key] === parseInt(trimvl2[1])
        );
        if (colorItemKey) {
          colorsavearray[colorItemKey] = resultary;
        }
      });
      //console.log(colorsavearray);
      //return;
      if (color[0]) {
        colorsavearray[color[0]] = "same";
      }
      if (color[1]) {
        colorsavearray[color[1]] = "same";
      }
      if (color[2]) {
        colorsavearray[color[2]] = "same";
      }

      const template_icon = "";

      const data = mainCanvas.getObjects().map((obj) => {
        let objData = obj.toObject();
        objData.flag = 0; // Append flag 0
        objData.color = obj.fill || "";
        objData.customWidth = obj.customWidth || obj.width;
        // Log customWidth to console
        console.log("Object ID:", obj.id, "Custom Width:", objData.customWidth);

        // Add mergedFonts to each object and include font source
        return objData;
      });
      const PriorityWiseColor = getPrioritizedColorNames(color, colorMapping);

      const canvasData = {
        objects: data,
        background: mainCanvas.backgroundColor,
        selectedColors: selectedColors,
      };

      const canvasImageURL = await convertCanvasToJPG(mainCanvas);
      const blob = base64ToBlob(canvasImageURL, "image/jpeg");

      let template_colors = mainCanvas.backgroundColor;
      if (selectedColors.length > 0) {
        template_colors = template_colors + "," + selectedColors.join(", ");
      }
      const currentUrl = location.pathname.substring(1);
      const extractedPart = currentUrl.split("/")[0];
      let save_update;
      if (extractedPart == "admin_canvas") {
        save_update = "update";
      } else {
        save_update = "save";
      }
      let fd_from = new FormData();
      fd_from.append("file", blob, "canvas-image.jpg");
      fd_from.append("main_id", main_id);
      fd_from.append("template_name", template_name);
      fd_from.append("template_colors", template_colors);
      fd_from.append("template_icon", template_icon);
      fd_from.append("template_industry", template_industry);
      //fd_from.append("template_industry", extractedIndustryValues);
      fd_from.append("template_canvas_data", JSON.stringify(canvasData));
      fd_from.append("template_type", selectedItem2); //code by mk
      fd_from.append("logotype", selectedItem); //code by mk
      fd_from.append("preferred_color", color); //code by mk
      fd_from.append("different_color_shade", JSON.stringify(colorsavearray)); //code by mk
      fd_from.append("PriorityWiseColor", PriorityWiseColor); //code by mk
      fd_from.append("ChangeIconSelect", ChangeIconSelect); //code by mk
      fd_from.append("use_flag_data", "0");
      fd_from.append("template_fav", "0");
      fd_from.append("save_update", save_update);
      setShowNext(false);
      setshowLoaderAdmin(true);

      // await server_post_data(save_update_admin_templates, fd_from)
      //   .then((Response) => {
      try {
        const Response = await axios.post(
          save_update_admin_templates,
          fd_from,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
            },
          }
        );
        if (Response.status === 200) {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccess(Response.data.message);
            navigate("/view_templates");
          }
          setshowLoaderAdmin(false);
        }
      } catch (error) {
        setshowLoaderAdmin(false);
        handleError("network");
      }
    } else {
      handleError("Please Add Creative");
    }
  };

  const saveCanvasAsJSON2 = async (form_data, url_for_save) => {
    let canvaschng = 0;
    mainCanvas._objects.forEach((object, index) => {
      if (object.type === "image") {
        canvaschng++;
      }
      // Check if the object is of type 'text', 'i-text', or 'textbox'
      else if (
        object.type === "text" ||
        object.type === "i-text" ||
        object.type === "textbox"
      ) {
        canvaschng++;
      } else {
        //console.log(`Object ${index} is of type: ${object.type}.`); // Logs other types if present
      }
    });

    if (canvaschng > 0) {
      let vaild_data = check_vaild_save(form_data);

      const mandatory_input = (inputmand) => {
        $("." + inputmand).css({
          border: "1px solid red",
          width: "50px !important",
        });
        $("." + inputmand)
          .nextAll(".condition_error:first")
          .html("Please Fill The Mandatory Information")
          .show();
        vaild_data = false;
      };

      if (vaild_data) {
        const template_name = document.getElementById("template_name").value;

        const template_industry = Selectedindustry.map(
          (industry) => industry.label
        ).join(",");

        let colorsavearray = {};

        const template_icon = "";

        const data = mainCanvas.getObjects().map((obj) => {
          let objData = obj.toObject();
          objData.flag = 0; // Append flag 0
          objData.color = obj.fill || "";
          objData.customWidth = obj.customWidth || obj.width;
          // Log customWidth to console
          console.log(
            "Object ID:",
            obj.id,
            "Custom Width:",
            objData.customWidth
          );

          // Add mergedFonts to each object and include font source
          return objData;
        });
        const PriorityWiseColor = "";

        const canvasData = {
          objects: data,
          background: mainCanvas.backgroundColor,
          selectedColors: selectedColors,
        };

        const canvasImageURL = await convertCanvasToJPG(mainCanvas);
        const blob = base64ToBlob(canvasImageURL, "image/jpeg");

        let template_colors = mainCanvas.backgroundColor;
        if (selectedColors.length > 0) {
          template_colors = template_colors + "," + selectedColors.join(", ");
        }
        const currentUrl = location.pathname.substring(1);
        const extractedPart = currentUrl.split("/")[0];
        let save_update;
        if (extractedPart == "admin_canvas") {
          save_update = "update";
        } else {
          save_update = "save";
        }
        let fd_from = new FormData();
        fd_from.append("file", blob, "canvas-image.jpg");
        fd_from.append("main_id", main_id);
        fd_from.append("template_name", template_name);
        fd_from.append("template_colors", template_colors);
        fd_from.append("template_icon", template_icon);
        fd_from.append("template_industry", template_industry);
        fd_from.append("template_canvas_data", JSON.stringify(canvasData));
        fd_from.append("template_type", selectedItem2); //code by mk
        fd_from.append("logotype", selectedItem); //code by mk
        fd_from.append("preferred_color", color); //code by mk
        fd_from.append("different_color_shade", JSON.stringify(colorsavearray)); //code by mk
        fd_from.append("PriorityWiseColor", PriorityWiseColor); //code by mk
        fd_from.append("ChangeIconSelect", ChangeIconSelect); //code by mk
        fd_from.append("use_flag_data", "0");
        fd_from.append("template_fav", "0");
        fd_from.append("save_update", save_update);
        setShowNext(false);
        setshowLoaderAdmin(true);

        try {
          const Response = await axios.post(
            save_update_admin_templates,
            fd_from,
            {
              headers: {
                "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
              },
            }
          );
          if (Response.status === 200) {
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              handleSuccess(Response.data.message);
              navigate("/view_templates");
            }
            setshowLoaderAdmin(false);
          }
        } catch (error) {
          setshowLoaderAdmin(false);
          handleError("network");
        }
      }
    } else {
      handleError("Please Add Creative");
    }
  };

  const handleUpload = async (file, svgContent) => {
    setshowLoaderAdmin(true);
    const formData = new FormData();
    formData.append("upload_img_new", file);
    formData.append("svg_content", svgContent);
    await server_post_data(insert_img_data, formData)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          startindexelement = 0;
          endindexelement = 150;
          stopelement = 0;
          get_more_elements();
          //master_data_get_full();
        }
        setshowLoaderAdmin(true);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
        handleError("network");
      });
  };

  const handleShapeUpload = async (file, svgContent, shape_type) => {
    setshowLoaderAdmin(true);

    // Convert file to base64
    const convertFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    try {
      const base64File = await convertFileToBase64(file);

      console.log(base64File);

      const formData = new FormData();
      formData.append("shape_url_data", base64File); // Append base64 file data
      formData.append("svg_content_data", svgContent);
      formData.append("shape_type_data", shape_type);

      // Send data to server
      const Response = await server_post_data(save_update_shape, formData);
      if (Response.data.error) {
        handleError(Response.data.message);
      } else {
        master_data_get_full();
      }
    } catch (error) {
      handleError("network");
    } finally {
      setshowLoaderAdmin(false);
    }
  };

  useEffect(() => {
    if (data_type !== "letterhead") {
      setIsOpenicon(false);
    }
  }, [data_type]);
  //target
  const loadFonts = async (fontName) => {
    ///return 0;
    if (fontName) {
      const fontNameing = fonts.find((font) => font.family === fontName);

      if (fontNameing && fontNameing.src_file) {
        // Load the font using FontFace
        const newFont = new FontFace(fontName, `url(${fontNameing.src_file})`);
        return newFont.load().then(function (loadedFont) {
          document.fonts.add(loadedFont); // Add the loaded font to the document

          // Wait for the font to be ready before continuing
          return document.fonts.ready.then(() => {
            console.log(`${fontName} has been loaded and is ready`);
          });
        });
      } else {
        // If the font doesn't have a direct src_file, inject it as a @font-face
        const fontStyles = `
          @font-face {
            font-family: '${fontNameing.family}';
            font-style: normal;
            font-weight: 400;
            src: url(${fontNameing.files.regular});
          }
        `;

        const styleElement = document.createElement("style");
        styleElement.textContent = fontStyles;
        document.head.appendChild(styleElement);

        // Wait for the font to be loaded and ready
        return document.fonts.ready.then(() => {
          console.log(`${fontNameing.family} has been injected and is ready`);
        });
      }
    }
  };

  useEffect(() => {
    const logoObject = images1.find(
      (itemr) => itemr.description === selectedItem2
    );
    const currentUrl = location.pathname.substring(1);
    ///if (currentUrl != "admin_canvas_new") {
    let containerWidth;
    let containerHeight;
    if (logoObject) {
      containerWidth = logoObject.width;
      containerHeight = logoObject.height;
    } else {
      containerWidth = 400;
      containerHeight = 400;
    }
    if (1 == 1) {
      mainCanvas = new fabric.Canvas(mainCanvasRef.current, {
        width: containerWidth,
        height: containerHeight,
        backgroundColor: templatejson.background || canvasColor,
      });

      const gridManager = createGridLines(mainCanvas);

      if (templatejson || !mainCanvas) {
        const addObjectsSequentially = (index = 0) => {
          if (index >= templatejson.objects.length) {
            mainCanvas.requestRenderAll();
            return; // Stop when all objects are added
          }

          const objData = templatejson.objects[index];

          if (objData.type === "i-text") {
            if (objData.metadata && objData.metadata.role === "company_name") {
              setcompany_nametext(true);
            }
            if (objData.metadata && objData.metadata.role === "slogan") {
              setslogantext(true);
            }

            if (objData.metadata && objData.metadata.role === "Letter_first") {
              setslettertext1(true);
            }

            if (objData.metadata && objData.metadata.role === "Letter_Second") {
              setslettertext2(true);
            }

            if (objData.metadata && objData.metadata.role === "Letter_Third") {
              setslettertext3(true);
            }
          }

          fabric.util.enlivenObjects([objData], (objects) => {
            const origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
            mainCanvas.renderOnAddRemove = false;

            objects.forEach((obj) => {
              if (obj.type === "i-text") {
                const customWidth = obj.customWidth || obj.width;
                obj.set({
                  width: customWidth,
                  scaleX: 1,
                  scaleY: 1,
                });
              }

              obj.set({
                borderColor: "#9b57ff",
                cornerColor: "white",
                cornerStrokeColor: "#ececec",
                strokeWidth: 2,
                transparentCorners: false,
                cornerRadius: 100,
              });

              obj.hoverCursor = "pointer";

              obj.on("mouseover", function () {
                mainCanvas.renderAll();
                //whenRenderFunction(true);
              });

              obj.on("mouseout", function () {
                obj.set("stroke", "transparent");
                mainCanvas.renderAll();
                //whenRenderFunction(true);
              });

              if (obj.type === "i-text") {
                obj.on("mouseover", function () {
                  obj.set("fill", obj.fill);
                  mainCanvas.renderAll();
                  //whenRenderFunction(true);
                });
              }

              obj.controls = {
                ...fabric.Object.prototype.controls,
              };

              if (obj.type === "i-text") {
                console.log(obj.fontFamily);
                loadFonts(obj.fontFamily).then(() => {
                  ///console.log('yes load');
                  obj.on("scaling", function () {
                    const minTextWidth = obj.calcTextWidth(); // Get the minimum width based on text content
                    const newWidth = obj.width * obj.scaleX;
                    if (newWidth < minTextWidth) {
                      obj.set({
                        width: minTextWidth,
                      });
                    } else {
                      obj.set({
                        width: newWidth,
                      });
                    }

                    obj.set({
                      scaleX: 1, // Prevent further horizontal scaling
                      scaleY: 1, // Prevent vertical scaling if needed
                    });

                    obj.customWidth = obj.width; // Update the customWidth to the new width
                    obj.setCoords(); // Update object coordinates
                    mainCanvas.requestRenderAll(); // Re-render the canvas
                  });

                  // obj.on("changed", function() {
                  //   // Calculate the new width based on scaling
                  //   const newWidth = obj.width * obj.scaleX;
                  //   obj.set({
                  //     width: newWidth,
                  //     scaleX: 1, // Reset scaling to avoid compounding effects
                  //     scaleY: 1, // Reset scaling to avoid compounding effects
                  //   });

                  //   // Store the custom width for consistency
                  //   obj.customWidth = newWidth;

                  //   // Adjust the text alignment
                  //   if (obj.textAlign === 'center') {
                  //     obj.set({ left: obj.left - (newWidth / 2) + (obj.canvas.width / 2) });
                  //   } else if (obj.textAlign === 'right') {
                  //     obj.set({ left: obj.left - newWidth + obj.canvas.width });
                  //   }

                  //   // Re-render the canvas
                  //   obj.setCoords();
                  //   obj.canvas.requestRenderAll();
                  // });

                  obj.on("editing:entered", function () {
                    obj.set({
                      width: obj.customWidth || obj.width,
                      scaleX: 1,
                      scaleY: 1,
                    });
                    obj.setCoords();

                    mainCanvas.requestRenderAll();
                  });

                  obj.on("changed", function () {
                    // Save the current cursor position
                    const selectionStart = obj.selectionStart;
                    const selectionEnd = obj.selectionEnd;
                    const textBeforeChange = obj.text;

                    const textWidth = obj.getBoundingRect().width;
                    const maxWidth = obj.customWidth || obj.width;
                    const text = obj.text;

                    if (textWidth > maxWidth) {
                      console.log(
                        "Text width exceeds max width up:",
                        textWidth,
                        obj.customWidth,
                        obj.width
                      );
                      obj.set({
                        width: obj.customWidth,
                        scaleX: 1,
                        scaleY: 1,
                      });
                      obj.setCoords();

                      mainCanvas.requestRenderAll();

                      const words = text.split(" ");
                      let lines = [];
                      let currentLine = "";
                      let currentLineWidth = 0;

                      for (let i = 0; i < words.length; i++) {
                        const word = words[i] + " ";
                        const wordText = new fabric.Text(word, {
                          fontSize: obj.fontSize,
                          fontFamily: obj.fontFamily,
                        });
                        const wordWidth = wordText.getBoundingRect().width;

                        if (currentLineWidth + wordWidth > maxWidth) {
                          lines.push(currentLine.trim());
                          currentLine = word;
                          currentLineWidth = wordWidth;
                        } else {
                          currentLine += word;
                          currentLineWidth += wordWidth;
                        }
                      }

                      if (currentLine.length > 0) {
                        lines.push(currentLine.trim());
                      }
                      console.log(lines);
                      // Update text with wrapped lines

                      obj.set({
                        text: lines.join("\n"),
                      });
                      obj.set({
                        width: obj.customWidth || obj.width,
                        scaleX: 1,
                        scaleY: 1,
                      });
                      obj.setCoords();
                      console.log(
                        "Text width exceeds max width down:",
                        textWidth,
                        obj.customWidth,
                        obj.width
                      );
                      // Calculate new cursor position
                      const addedLines =
                        lines.length -
                        (textBeforeChange.match(/\n/g) || []).length;
                      const newCursorPos = Math.min(
                        selectionStart + addedLines,
                        obj.text.length
                      );

                      // Restore the cursor position after updating text
                      //obj.setSelectionStart(newCursorPos);
                      //obj.setSelectionEnd(newCursorPos);
                    } else {
                      // Reset properties if within bounds
                      obj.set({
                        width: obj.customWidth || obj.width,
                        scaleX: 1,
                        scaleY: 1,
                      });
                    }

                    // Ensure the canvas is updated correctly
                    obj.setCoords();
                    mainCanvas.requestRenderAll(); // Update the canvas to reflect changes

                    // Optionally, you can set a timeout to ensure the canvas has time to update
                    setTimeout(() => {
                      //obj.setSelectionStart(selectionStart);
                      // obj.setSelectionEnd(selectionEnd);
                    }, 0);
                  });

                  obj.on("editing:exited", function () {
                    //console.log('Editing ended. Current alignment:',obj.customWidth , obj.textAlign);
                    obj.set({
                      width: obj.customWidth || obj.width,
                      scaleX: 1,
                      scaleY: 1,
                    });
                    obj.setCoords();

                    mainCanvas.requestRenderAll();
                  });
                  mainCanvas.add(obj);
                });
              }
              if (obj.type !== "i-text") {
                mainCanvas.add(obj);
              }
            });

            mainCanvas.renderOnAddRemove = origRenderOnAddRemove;
            mainCanvas.requestRenderAll();

            UserChangingIconSelection();

            // Recursively add the next object
            addObjectsSequentially(index + 1);
          });
        };

        // Start the sequential addition
        addObjectsSequentially();
        setCanvasBackground(mainCanvas, templatejson.background);
      }

      mainCanvas.preserveObjectStacking = true;
      mainCanvas.on("object:added", handleCanvasUpdate);
      mainCanvas.on("object:modified", handleCanvasUpdate);
      mainCanvas.on("object:removed", handleCanvasUpdate);
      mainCanvas.on("selection:cleared", handleObjectDeselection);

      // mainCanvas.on("object:scaling", function (e) {
      //   const target = e.target;

      //   if (target.type === "i-text") {
      //     const newWidth = target.width * target.scaleX;
      //     target.set({
      //       width: newWidth,
      //       scaleX: 1,
      //       scaleY: 1,
      //     });
      //     target.setCoords();
      //     target.customWidth = newWidth;
      //     mainCanvas.requestRenderAll();
      //     //console.log("Scaling event. New width:", newWidth);
      //   }
      // });

      mainCanvas.on("object:scaling", function (e) {
        const obj = e.target;
        if (obj.type === "i-text") {
          const minTextWidth = obj.calcTextWidth(); // Get the minimum width based on text content
          const newWidth = obj.width * obj.scaleX;
          if (newWidth < minTextWidth) {
            obj.set({
              width: minTextWidth,
            });
          } else {
            obj.set({
              width: newWidth,
            });
          }

          obj.set({
            scaleX: 1, // Prevent further horizontal scaling
            scaleY: 1, // Prevent vertical scaling if needed
          });

          obj.customWidth = obj.width; // Update the customWidth to the new width
          obj.setCoords(); // Update object coordinates
          mainCanvas.requestRenderAll(); // Re-render the canvas
        }
      });

      mainCanvas.on("mouse:down", handleMouseDown);

      //window.addEventListener("resize", updateCanvasDimensions(400, 400));
      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keydown", handleSelectAll);

      mainCanvas.on("mouse:down", function (options) {
        handleTargetSelection(options.target);
      });

      return () => {
        mainCanvas.dispose();
        mainCanvas.off("selection:cleared", handleObjectDeselection);
        mainCanvas.off("mouse:down", handleMouseDown);
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keydown", handleSelectAll);
        window.removeEventListener("resize", updateCanvasDimensions);

        setTimeout(() => {
          handleCanvasUpdate();
        }, 1000);
        gridManager.removeGridLines();
      };
    }
  }, [templatejson]);

  //Target

  // const handleTargetSelection = (target) => {
  //   if (target) {
  //     const activeObjects = mainCanvas.getActiveObjects();

  //     if (activeObjects.length > 1) {
  //       setIsTextToolVisible(false);
  //       setIsObjectSelected(false);
  //       setIsGroupObj(true);
  //       setshopcolor(true);
  //       setButtonVisible(false);
  //       console.log("Multiple objects selected, showing group toolbar.");
  //     } else if (target.type === "i-text") {
  //       const oldTextColor = target.fill;
  //       console.log("Old Text Color:", oldTextColor);
  //       setIsTextToolVisible(true);
  //       setIsObjectSelected(false);
  //       setIsGroupObj(false);
  //       setshopcolor(true);
  //       setSelectedCanvasFontFamily(target.fontFamily);
  //       setSelectedText(target);
  //       setSelectedFontSize(target.fontSize);
  //       setButtonVisible(false);
  //     } else if (target.type === "image") {
  //       if (target.metadata) {
  //         if (
  //           target.metadata.role === "icon" ||
  //           target.metadata.role === "Shapes"
  //         ) {
  //           const iconColor = target.color;
  //           console.log("Selected Icon - Old Color:", iconColor);
  //           seticonchngcolor(iconColor);
  //           setshopcolor(true);
  //           if (target.borderColor) {
  //             seticonchngcolor(target.borderColor);
  //           } else if (target.color) {
  //             seticonchngcolor(target.color);
  //           }
  //           console.log("Icon role detected, shopcolor set to true.");
  //         } else {
  //           setshopcolor(false);
  //           console.log("Non-icon role detected, shopcolor set to false.");
  //         }
  //       } else {
  //         setshopcolor(true);
  //       }
  //       setIsTextToolVisible(false);
  //       setIsObjectSelected(true);
  //       setIsGroupObj(false);
  //       setSelectedIconId(target.get("id"));
  //       setIsButtonEnabled(true);
  //       setIsButtonChecked(
  //         sessionStorage.getItem("checkedIconId") === target.get("id")
  //       );
  //       console.log("Selected Icon ID:", target.get("id"));
  //     } else if (target.type === "group") {
  //       setIsTextToolVisible(false);
  //       setIsObjectSelected(false);
  //       setIsGroupObj(true);
  //       setshopcolor(true);
  //       setButtonVisible(false);
  //       console.log("Group selected, group toolbar visible.");
  //     } else if (target.shape_type) {
  //       // Shape object selected
  //       setIsTextToolVisible(false);
  //       setIsObjectSelected(true);
  //       setIsGroupObj(false);
  //       setshopcolor(true); // Ensure shopcolor is set to true for shapes
  //       setButtonVisible(false);
  //       console.log("Shape selected, shopcolor set to true.");
  //     } else {
  //       setIsTextToolVisible(false);
  //       setIsObjectSelected(true);
  //       setIsGroupObj(false);
  //       setshopcolor(true);
  //       if (target.globalCompositeOperation) {
  //         console.log(target);
  //         handleImageClick(target.globalCompositeOperation, 1);
  //       }
  //       setButtonVisible(false);
  //     }
  //   } else {
  //     setIsTextToolVisible(false);
  //     setIsObjectSelected(false);
  //     setIsGroupObj(false);
  //     setshopcolor(true);
  //     setSelectedCanvasFontFamily("");
  //     setButtonVisible(false);
  //   }
  // };

  const handleTargetSelection = async(target) => {
    if (target) {
      const activeObjects = mainCanvas.getActiveObjects();
      console.log(activeObjects);
      if (activeObjects.length > 1) {
        // Multiple objects selected
        setIsTextToolVisible(false);
        setIsObjectSelected(false);
        setIsGroupObj(true);
        setshopcolor(true);
        setButtonVisible(false);
        setisbgcolorshape(true);
        console.log("Multiple objects selected, showing group toolbar.");
      } else if (target.type === "i-text") {
        setGroupUngroup("Group");
        // Text object selected
        const oldTextColor = target.fill;
        console.log("Old Text Color:", oldTextColor);
        setsetTextColor(oldTextColor);
        setIsTextToolVisible(true);
        setIsObjectSelected(false);
        setIsGroupObj(false);
        setshopcolor(true);
        setSelectedCanvasFontFamily(target.fontFamily);
        setSelectedText(target);
        setSelectedFontSize(target.fontSize);
        setButtonVisible(false);
        if (target.fontStyle === "italic") {
          setTextstyle(true);
        } else if (target.fontStyle !== "italic")  {
          setTextstyle(false);
        }
        if (target.fontWeight === "bold") {       
          setTextweight(true);
        } else {
          setTextweight(false);
        }
      } else if (target.type === "image") {
        setGroupUngroup("Group");
        // Image object selected
        if (target.metadata) {
          if (target.metadata.role === "icon") {
            // Icon detected
            const iconColor = target.color;
            let svgDataUrl = target.src;
            
            if (!svgDataUrl) {
              svgDataUrl = target.getSrc();
            }
      
            try {
              // Fetching SVG data asynchronously
              const response = await fetch(svgDataUrl);
              const svgData = await response.text();
      
              // Process the SVG to extract fill color
              const fillColor = getFillColorFromSVG(svgData);
              console.log(fillColor);
              seticonchngcolor(fillColor);
            } catch (error) {
              console.error("Error fetching SVG data:", error);
            }

            console.log("Selected Icon - Old Color:", iconColor);
            
            // setshopcolor(true);
            // setisbgcolorshape(false); // Hide the button for icons
            // if (target.borderColor) {
            //   seticonchngcolor(target.borderColor);
            // } else if (target.color) {
            //   seticonchngcolor(target.color);
            // }
          } else if (target.metadata.role === "Shapes") {
            const shapeColor = target.color;

            seticonchngcolor(shapeColor);
            setshopcolor(true);
            setisbgcolorshape(true);

            // const iconColor = target.color;
            let svgDataUrl = target.src;
            
            if (!svgDataUrl) {
              svgDataUrl = target.getSrc();
            }
      
            try {
              // Fetching SVG data asynchronously
              const response = await fetch(svgDataUrl);
              const svgData = await response.text();
      
              // Process the SVG to extract fill color
              const fillColor = getFillColorFromSVG(svgData);
              console.log(fillColor);
              seticonchngcolor(fillColor);
            } catch (error) {
              console.error("Error fetching SVG data:", error);
            }
            // if (target.borderColor) {
            //   seticonchngcolor(target.borderColor);
            // } else if (target.color) {
            //   seticonchngcolor(target.color);
            // }
            // console.log(isshapbcg);
            // console.log(activeObjects[0].MkIconId);
            // console.log(activeObjects);
            if (activeObjects && isshapbcg === activeObjects[0].MkIconId) {
              isRightImg = true;
            } else {
              isRightImg = false;
            }
            setRender((prev) => prev + 1);
          } else {
            setisbgcolorshape(false);
            setshopcolor(false);
          }
        } else {
          setisbgcolorshape(false);
          setshopcolor(true);
        }
        setIsTextToolVisible(false);
        setIsObjectSelected(true);
        setIsGroupObj(false);
        setSelectedIconId(target.get("id"));
        setIsButtonEnabled(true);
        setIsButtonChecked(
          sessionStorage.getItem("checkedIconId") === target.get("id")
        );
        console.log("Selected Icon ID:", target.get("id"));
      } else if (target.type === "group") {
        setGroupUngroup("Group");
        // Group object selected
        setIsTextToolVisible(false);
        setIsObjectSelected(false);
        setIsGroupObj(true);
        setshopcolor(true);
        setButtonVisible(false);
        setisbgcolorshape(false); // Hide the button for groups
        console.log("Group selected, group toolbar visible.");
      } else if (target.shape_type) {
        setGroupUngroup("Group");
        // Shape object selected
        setIsTextToolVisible(false);
        setIsObjectSelected(true);
        setIsGroupObj(false);
        setshopcolor(true); // Ensure shopcolor is set to true for shapes
        setButtonVisible(false);
        setisbgcolorshape(true); // Show the button for shapes
        console.log("Shape selected, shopcolor set to true.");
      } else {
        setGroupUngroup("Group");
        // Other objects
        setIsTextToolVisible(false);
        setIsObjectSelected(true);
        setIsGroupObj(false);
        setisbgcolorshape(false); // Hide the button for other objects
        setshopcolor(true);
        if (target.globalCompositeOperation) {
          console.log(target);
          handleImageClick(target.globalCompositeOperation, 1);
        }
        setButtonVisible(false);
      }
    } else {
      // No target
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setIsGroupObj(false);
      setshopcolor(true);
      setSelectedCanvasFontFamily("");
      setButtonVisible(false);
      setisbgcolorshape(false); // Hide the button when no target
    }

    console.log("isbgcolorshape state:", isbgcolorshape); // Debugging log
  };

  const [SetTextcolor, setsetTextColor] = useState();
  const [Textstyle, setTextstyle] = useState(false);
  const [Textweight, setTextweight] = useState(false);

  // console.log(Textweight)

  // Function to create and manage grid lines
  const createGridLines = (mainCanvas) => {
    let verticalLine, horizontalLine;

    // Function to create grid lines centered on the canvas
    const createCenteredGridLines = () => {
      const canvasWidth = mainCanvas.width;
      const canvasHeight = mainCanvas.height;
      const centerX = canvasWidth / 2;
      const centerY = canvasHeight / 2;

      // Create vertical center line
      verticalLine = new fabric.Line([centerX, 0, centerX, canvasHeight], {
        stroke: "rgba(155, 87, 255, 0.5)",
        strokeWidth: 1,
        selectable: false,
        evented: false,
        perPixelTargetFind: true,
      });

      // Create horizontal center line
      horizontalLine = new fabric.Line([0, centerY, canvasWidth, centerY], {
        stroke: "rgba(155, 87, 255, 0.5)",
        strokeWidth: 1,
        selectable: false,
        evented: false,
        perPixelTargetFind: true,
      });

      mainCanvas.add(verticalLine, horizontalLine);
    };

    // Function to update grid lines based on object position
    const updateGridLines = (object) => {
      if (!verticalLine || !horizontalLine) return;

      const { left, top, width, height } = object.getBoundingRect();
      const centerX = left + width / 2;
      const centerY = top + height / 2;
      const canvasWidth = mainCanvas.width;
      const canvasHeight = mainCanvas.height;

      // Show vertical line if the object is near the vertical center
      const showVertical =
        centerX <= canvasWidth / 2 + 10 && centerX >= canvasWidth / 2 - 10;
      // Show horizontal line if the object is near the horizontal center
      const showHorizontal =
        centerY <= canvasHeight / 2 + 10 && centerY >= canvasHeight / 2 - 10;

      verticalLine.set("visible", showVertical);
      horizontalLine.set("visible", showHorizontal);

      mainCanvas.requestRenderAll();
    };

    // Function to show grid lines on hover or mouseover
    const showGridLines = (object) => {
      if (!verticalLine || !horizontalLine) return;
      verticalLine.set("visible", true);
      horizontalLine.set("visible", true);
      updateGridLines(object);
    };

    // Function to hide grid lines
    const hideGridLines = () => {
      if (!verticalLine || !horizontalLine) return;
      verticalLine.set("visible", false);
      horizontalLine.set("visible", false);
      mainCanvas.requestRenderAll();
    };

    // Initial setup of grid lines
    createCenteredGridLines();
    hideGridLines(); // Start with grid lines hidden

    // Event listener for object moving
    mainCanvas.on("object:moving", (options) => {
      const { target } = options;
      if (target) {
        isMoving = true;
        updateGridLines(target);
      }
    });

    mainCanvas.on("mouse:up", () => {
      if (isMoving) {
        setTimeout(() => {
          whenRenderFunction(true);
          isMoving = false; // Reset the flag after rendering
        }, 100);
      }
      if (isSpacingChanged) {
        setTimeout(() => {
          whenRenderFunction(true);
          isSpacingChanged = false; // Reset the flag after rendering
        }, 100);
      }
      if (isColorChanged1) {
        setTimeout(() => {
          whenRenderFunction(true);
          isColorChanged1 = false; // Reset the flag after rendering
        }, 100);
      }
      if (isColorChanged2) {
        setTimeout(() => {
          whenRenderFunction(true);
          isColorChanged2 = false; // Reset the flag after rendering
        }, 100);
      }
    });

    // Event listener for object hover
    mainCanvas.on("mouse:over", (options) => {
      const { target } = options;
      if (target) {
        showGridLines(target);
      }
    });

    // Event listener for object hover out
    mainCanvas.on("mouse:out", () => {
      hideGridLines();
    });

    // Cleanup function to remove grid lines
    const removeGridLines = () => {
      if (verticalLine) mainCanvas.remove(verticalLine);
      if (horizontalLine) mainCanvas.remove(horizontalLine);
    };

    return {
      removeGridLines,
    };
  };

  useEffect(() => {
    // Update the background color whenever the canvasColor state changes
    if (mainCanvas) {
      mainCanvas.setBackgroundColor(canvasColor, () => {
        mainCanvas.renderAll();
        handleCanvasUpdate(); //canvas change appy
        //whenRenderFunction(true);
      });
    }
  }, [canvasColor,Textstyle,Textweight]);

  const handleFontUpload = async () => {
    if (!file || typeof category !== "string") {
      setValidationMessage("Please choose a file and ensure category is valid");
      return;
    }

    const fontName = file.name.split(".").slice(0, -1).join(".");
    const formData = new FormData();
    formData.append("file_example_download", file);
    formData.append("font_name", fontName);
    formData.append("category", category);

    try {
      setshowLoaderAdmin(true);
      const response = await server_post_data(post_customfont_data, formData);
      //console.log(response.data.message);
      //console.log(response.data.message);
      if (response.data.error) {
        handleError(response.data.message);
        return;
      }
      //console.log('yes come to here 1');
      const reader = new FileReader();
      reader.onload = async (e) => {
        ///const fontFace = new FontFace(fontName, `url('https://api.logoacademy.co/assets/'+${response.data.message.data[0].font_file})`);

        const fontFace = new FontFace(
          fontName,
          `url(${response.data.message.src_file})`
        );
        //console.log(fontFace);
        //console.log('https://api.logoacademy.co/assets/'+response.data.message.src_file);
        //console.log(response.data.message);
        try {
          //console.log('yes come to here 2');
          const loadedFace = await fontFace.load();
          document.fonts.add(loadedFace);
          //console.log('yes come to here 3');
          setUploadedFonts((prevFonts) => [
            {
              family: fontName,
              src: e.target.result,
              category: category, // Use the dynamic category here
            },
            ...prevFonts,
          ]);
          //console.log('yes come to here 5');

          const newCategory = {
            label: category,
            value: category.toLowerCase(),
          };

          setFontCategories((prevCategories) => [
            newCategory,
            ...prevCategories,
          ]);

          setFonts((prevFonts) => [
            {
              family: fontName,
              src: e.target.result,
              category: category, // Use the dynamic category here
            },
            ...prevFonts,
          ]);
        } catch (error) {
          console.error("Error loading font:", error);
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error uploading font:", error);
      handleError("network");
    } finally {
      setshowLoaderAdmin(false);
      setshowFontModal(false);
    }
  };

  const handleKeyDownSelect = (e) => {
    if (e.ctrlKey && e.key === "a") {
      e.preventDefault();
      const index = e.target.dataset.index;
      if (inputRefs[index] && inputRefs[index].current) {
        inputRefs[index].current.select();
      }
    }
  };

  const filterFonts = (query, category) => {
    const combinedFonts = [...fonts, ...uploadedFonts];
    const uniqueFonts = combinedFonts.filter(
      (font, index, self) =>
        index ===
        self.findIndex(
          (f) => f.family.toLowerCase() === font.family.toLowerCase()
        )
    );

    let filtered = uniqueFonts;

    // Handle custom category logic
    if (category && category.value === "Custom") {
      filtered = filtered.filter((font) =>
        uploadedFonts.some((customFont) => customFont.family === font.family)
      );
    } else if (category && category.value !== "All") {
      filtered = filtered.filter(
        (font) =>
          font.category &&
          font.category.toLowerCase() === category.value.toLowerCase()
      );
    }

    if (query && query.trim() !== "") {
      filtered = filtered.filter(
        (font) =>
          font.family && font.family.toLowerCase().includes(query.toLowerCase())
      );
    }

    // Filter out fonts with 'Material' in their family name
    filtered = filtered.filter(
      (font) => !font.family.toLowerCase().includes("material")
    );

    setFilteredFonts(filtered);
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategory(selectedOptions || { value: "All", label: "All" });
    filterFonts(
      fontSearchQuery,
      selectedOptions || { value: "All", label: "All" }
    );
    scrollToTop();
  };
  const loadMoreFonts = () => {
    setInitialFontCount((prevCount) => prevCount + 12);
  };

  const handleFontSelection = (selectedFont) => {
    const fontFamily = selectedFont.family;
    const activeObject = mainCanvas.getActiveObject();
    if (replaceText) {
      if (activeObject && activeObject.type === "i-text") {
        activeObject.set({ fontFamily });

        // Preserve custom width after font family change
        const customWidth = activeObject.customWidth || activeObject.width;
        activeObject.set({
          width: customWidth, // Reapply custom width
          scaleX: 1,
          scaleY: 1,
        });

        mainCanvas.renderAll();
        //whenRenderFunction(true);
        console.log("Font changed. Custom width preserved:", customWidth);
      }
    } else {
      if (selectedItem2 == "Logo") {
        let ol = 1;
        if (selectedTab === "company_name" && company_nametext === false) {
          setcompany_nametext(true);
          ol = 0;
        }
        if (selectedTab === "slogan" && slogantext === false) {
          setslogantext(true);
          ol = 0;
        }
        // Create a new text input if not replacing
        if (ol === 0) {
          createTextInput(selectedTab, "Add Text", fontFamily);
        } else {
          if (selectedTab === "company_name") {
            alert("Please delete your company text before adding a new one.");
          }
          if (selectedTab === "slogan") {
            alert("Please delete your slogan text before adding a new one.");
          }
        }
      } else {
        createTextInput(selectedTab, "Add Text", fontFamily);
      }
    }

    setSelectedFontFamily(fontFamily);
  };

  const scrollToTop = () => {
    const container = uploadRef.current;
    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth", // You can use 'auto' if you don't want smooth scrolling
      });
    }
  };

  const handleFontButtonClick = () => {
    // Toggle the states for 'isOpentext' and 'isActive2'
    setIsOpentext(true);
    console.log(isOpentext);
    setIsActive2(true);
    //setReplaceText((prev) => !prev);
    setReplaceText(true);
    // Deactivate other states and their active classes
    setIsOpenicon(false);
    setIsOpenBg(false);
    setIsOpenshape(false);
    setIsOpenTemplate(false);
    setIsOpenAbout(false);
    setIsOpenuploads(false);
    setIsColor(false);
    setIsletter(false);

    setIsActive3(false);
    setIsActive4(false);
    setIsActive5(false);

    setIsActive7(false);
    setIsActive8(false);
    setIsActive9(false);

    // Set the flag to replace text

    // Apply font filter
    filterFonts(fontSearchQuery, selectedCategory);
  };
  const truncateText = (text, maxLength) => {
    const replacedText = text.replace(/-/g, " ");
    return replacedText.length > maxLength
      ? `${replacedText.substring(0, maxLength)}...`
      : replacedText;
  };

  const maxLength = 12; // Maximum length for truncation
  const truncatedFontFamily = truncateText(selectedFontCanvasFamily, maxLength);

  const buttonClass =
    replaceText && selectedFontFamily === selectedFontCanvasFamily
      ? "activeButton"
      : "";

  const imgSrc =
    replaceText && selectedFontFamily === selectedFontCanvasFamily
      ? fontFam
      : fontFamilYblueee;

  //Mobile view hide class toolsidebar
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to handle click outside events
    const handleOutsideClick = (ref, handler) => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handler(false);
        }
      };

      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    };

    // Handlers for outside click events
    const navOutsideClickHandler = () => setShowSecondButton(false);
    const OpecityHideHandler = () => setShowOpacityInput(false);
    const downloadOutsideClickHandler = () => setShowSecondButton2(false);
    const alignmentOutsideClickHandler = () => {
      setIsVisible(false);
      setIsVisible2(false);
    };
    const positionOutsideClickHandler = () => SetPossitionsLayers(false);
    const flipOutsideClickHandler = () => setShowFlipButtons(false);

    const resizeOutsideClickHandler = () => setShowResize(false);

    // Add event listeners for each ref
    const cleanupFunctions = [
      handleOutsideClick(navRef, navOutsideClickHandler),
      handleOutsideClick(downloadRef, downloadOutsideClickHandler),
      handleOutsideClick(aligmentRef, alignmentOutsideClickHandler),
      handleOutsideClick(PosstionLayer, positionOutsideClickHandler),
      handleOutsideClick(FlipLayer, flipOutsideClickHandler),

      handleOutsideClick(resizeRef, resizeOutsideClickHandler),
      handleOutsideClick(opacityInputRef, OpecityHideHandler),
    ];

    // Clean-up function to remove all event listeners
    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [showSecondButton, showSecondButton2, showResize]);

  // Effect for resizing
  useEffect(() => {
    const handleClickOutsideResize = (event) => {
      if (resizeRef.current && !resizeRef.current.contains(event.target)) {
        setShowResize(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideResize);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideResize);
    };
  }, [showResize]);
  const handleClickOutLetterside = (event) => {
    if (letterRef.current && !letterRef.current.contains(event.target)) {
      setLetterSpacingVisible(false);
    }
  };

  // Add and remove event listener on mount and unmount
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutLetterside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutLetterside);
    };
  }, []);
  // Dependencies that trigger cleanup

  const handleIconData = (iconData) => {
    fetch(iconData.url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((svgContent) => {
        const modifiedSvgData = svgContent.replace(
          'viewBox="0 0 100 100"',
          'viewBox="0 0 100 100" fill="#000000"'
        );
        handleImageClickIcon(iconData.url, modifiedSvgData, "#000000");
      })
      .catch((error) => {
        console.error("There was a problem fetching the SVG content:", error);
      });
  };
  const handleIconData2 = (icon, color) => {
    // Get the svg_content directly from the icon data
    const shapeContent = icon.svg_content;

    // Modify the SVG fill color dynamically
    const modifiedSvgData = shapeContent.replace(
      'fill="black"',
      `fill="${color}"`
    );

    handleImageClickIcon(modifiedSvgData, color);
  };

  const updateCanvasDimensions = (width, height) => {
    const containerWidth = mainCanvasRef.current.clientWidth;
    const containerHeight = mainCanvasRef.current.clientHeight;

    mainCanvas.setDimensions({
      width: width || containerWidth,
      height: height || containerHeight,
    });
  };

  // canvas show in the le
  //zoom  in and out the canvas
  const handleZoomIn = () => {
    const zoomStep = 10;
    setZoomLevel((prevZoom) => prevZoom + zoomStep);
  };

  const handleZoomOut = () => {
    const zoomStep = 10;
    setZoomLevel((prevZoom) => prevZoom - zoomStep);
  };
  const zoomStyle = {
    transform: `scale(${zoomLevel / 100})`,
    transformOrigin: "center center",
  };
  const containerClassName =
    data_type === "letterhead" ? "convasBGcontainer2" : "convasBGcontainer";
  //canvas dimention change
  //upload img remove last used img   //Delete Get Uploads img

  const handleContentChange = (content) => {
    setCurrentContent(content);
    const filteredDatashow = IconShape.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(content.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };

  //Upload img

  const handleCanvasUpdate = () => {
    // Clear the secondary canvas
    // Get the dimensions of the main canvas
    const mainCanvasWidth = mainCanvas.width;
    const mainCanvasHeight = mainCanvas.height;

    // Define width and height of the T-shirt canvas (configurable)
    const tshirtCanvasWidth = 160;
    const tshirtCanvasHeight = 160;

    // Calculate scaling factors for width and height
    const scaleX = tshirtCanvasWidth / mainCanvasWidth;
    const scaleY = tshirtCanvasHeight / mainCanvasHeight;

    // Take the minimum scaling factor to ensure content fits
    const scaleFactor = Math.min(scaleX, scaleY);
    // Get the data URL of the main canvas
    const mainCanvasDataURL = mainCanvas.toDataURL();

    // Create an image object
    const img = new Image();

    // Set the source of the image and handle errors
    img.src = mainCanvasDataURL;
    img.onerror = () => {
      console.error("Error loading image from main canvas data URL");
    };

    // When the image is loaded, draw it onto the secondary canvas
    img.onload = function () {
      const imageWidth = img.width;
      const imageHeight = img.height;

      // Calculate scaled width and height based on scaleFactor
      const scaledWidth = imageWidth * scaleFactor;
      const scaledHeight = imageHeight * scaleFactor;

      // Draw the scaled image onto the secondary canvas
      const ctx = mainCanvas.getContext("2d");

      // Center the image if there's extra space (optional)
      if (
        scaledWidth < tshirtCanvasWidth ||
        scaledHeight < tshirtCanvasHeight
      ) {
        const horizontalOffset = (tshirtCanvasWidth - scaledWidth) / 2;
        const verticalOffset = (tshirtCanvasHeight - scaledHeight) / 2;
        ctx.drawImage(
          img,
          horizontalOffset,
          verticalOffset,
          scaledWidth,
          scaledHeight
        );
      }

      // Update the data URL of the secondary canvas
      setSecondaryCanvasDataURL(mainCanvasDataURL);
    };
  };

  const handleImageClickIcon = async (src, svg_data, color_code) => {
    if (!mainCanvas) return;
    try {
      const svgDataUrl = `data:image/svg+xml;base64,${btoa(svg_data)}`;

      const imgElement = new Image();
      imgElement.onload = function () {
        const iconId = `icon-${mainCanvas.getObjects("image").length}`; // Assign unique ID
        const timestamp = new Date().getTime().toString(36); // Convert the current time to a base-36 string
        const randomNumber = Math.floor(Math.random() * 1000000000).toString(
          36
        );
        const fabricImage = new fabric.Image(imgElement, {
          left: 100,
          top: 100,
          fill: color_code,
          id: iconId, // Assign unique ID
          MkIconId: `MkIconId_${timestamp}_${randomNumber}`, // Assign unique ID
          metadata: {
            role: "icon",
            timestamp: new Date().toISOString(),
          },
        });
        fabricImage.MkIconId = new Date().toISOString();
        fabricImage.metadata = {
          role: "icon",
          timestamp: new Date().toISOString(),
        };
        //console.log("Fabric Image Object:", fabricImage); // Should log the fabric image object
        //console.log("Metadata:", fabricImage.metadata); // Should log the metadata object
        mainCanvas.add(fabricImage).setActiveObject(fabricImage);
        mainCanvas.renderAll();
        UserChangingIconSelection();
        whenRenderFunction(true); //when icon add
        const addedObject = mainCanvas.getActiveObject();
        //console.log("Metadata from mainCanvas:", addedObject.metadata);
        //console.log("Added Icon ID:", iconId); // Log added icon ID
      };
      imgElement.src = svgDataUrl;
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };

  const handleButtonClickCheckIcon = () => {
    if (!isButtonEnabled || !selectedIconId) return;

    const storedCheckedIconId = sessionStorage.getItem("checkedIconId");
    const isCurrentlyChecked = storedCheckedIconId === selectedIconId;

    if (isCurrentlyChecked) {
      // Uncheck the currently checked icon
      setCheckedIconId(null);
      sessionStorage.removeItem("checkedIconId");
    } else {
      // Uncheck the previously checked icon if any
      if (storedCheckedIconId) {
        setCheckedIconId(null);
        sessionStorage.removeItem("checkedIconId");
      }

      // Check the new icon
      setCheckedIconId(selectedIconId);
      sessionStorage.setItem("checkedIconId", selectedIconId);
    }
  };

  const handleSameAsbg = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (isRightImg) {
        isRightImg = false;
        isshapbcg = "";
      } else {
        isRightImg = true;
        isshapbcg = activeObject.MkIconId;
      }
      setRender((prev) => prev + 1);
    }
  };

  const isButtonDisabled =
    !isButtonEnabled ||
    !selectedIconId ||
    (checkedIconId && checkedIconId !== selectedIconId);
  const handleBackClick = () => {
    setCurrentContent("main");
  };

  const handleCanvasColorChange = (event, gradientColors) => {
    const newColor = event.target.value;
    setCanvasColor(newColor);

    if (mainCanvas) {
      const context = mainCanvas.getContext("2d");
      context.clearRect(0, 0, mainCanvas.width, mainCanvas.height);

      if (newColor.startsWith("linear-gradient")) {
        const gradient = context.createLinearGradient(
          0,
          0,
          mainCanvas.width,
          0
        );
        gradientColors.forEach((color, index) => {
          gradient.addColorStop(index / (gradientColors.length - 1), color);
        });

        context.fillStyle = gradient;
      } else {
        context.fillStyle = newColor;
      }

      context.fillRect(0, 0, mainCanvas.width, mainCanvas.height);
    }
  };
  const fontSizes = [
    6, 8, 10, 12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88,
    96, 104, 120, 144,
  ];

  //increase Decrase fonts on the edit tool bar
  const handleFontSizeChange = (sizeChange) => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      let currentFontSize = activeObject.get("fontSize");
      let newFontSize = currentFontSize + sizeChange;

      // Ensure font size doesn't go below 1px
      if (newFontSize < 1) newFontSize = 1;

      // Log font size before change
      console.log("Current Font Size:", currentFontSize);
      console.log("Current Custom Width:", activeObject.customWidth);

      // Set the new font size on the active object
      activeObject.set("fontSize", newFontSize);

      // Update customWidth proportionally based on the new font size
      const scaleFactor = newFontSize / currentFontSize;
      activeObject.customWidth =
        (activeObject.customWidth || activeObject.width) * scaleFactor;

      // Apply the new dimensions
      activeObject.set({
        width: activeObject.customWidth,
        scaleX: 1,
        scaleY: 1,
      });

      // Log new font size and custom width
      console.log("New Font Size:", newFontSize);
      console.log("New Custom Width:", activeObject.customWidth);

      // Re-render the canvas to apply the changes
      mainCanvas.requestRenderAll();

      // Update the selected font size state
      setSelectedFontSize(newFontSize);
    }
  };

  // Function to change font style

  const handleFontStyleChange = (toggleBold, toggleItalic) => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      // Preserve custom width
      const customWidth = activeObject.customWidth || activeObject.width;

      if (toggleBold) {
        const isCurrentlyBold = activeObject.fontWeight === "bold";
        activeObject.set("fontWeight", isCurrentlyBold ? "normal" : "bold");
        
      }

      if (toggleItalic) {
        const isCurrentlyItalic = activeObject.fontStyle === "italic";
        activeObject.set("fontStyle", isCurrentlyItalic ? "normal" : "italic");
      }

      // Restore the custom width after changing style
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
        scaleX: 1,
        scaleY: 1,
      });

      mainCanvas.renderAll();
      //whenRenderFunction(true);
    }
  };

  const handleLetterSpacingChange = (e) => {
    const value = e.target.value;
    setLetterSpacingValue(value);

    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      // Preserve custom width
      const customWidth = activeObject.customWidth || activeObject.width;

      // Apply letter spacing
      activeObject.set("charSpacing", value * 10);

      // Restore custom width after changing letter spacing
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
        scaleX: 1,
        scaleY: 1,
      });

      mainCanvas.renderAll();
      isSpacingChanged = true;
    }
  };

  const handleheightSpacingChange = (e) => {
    const value = e.target.value;
    setheightSpacingValue(value);

    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "i-text") {
      // Preserve custom width
      const customWidth = activeObject.customWidth || activeObject.width;

      // Apply line height
      activeObject.set("lineHeight", value / 50); // Adjust the divisor as needed for proper scaling

      // Restore custom width after changing line height
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
        scaleX: 1,
        scaleY: 1,
      });

      mainCanvas.renderAll();
      //whenRenderFunction(true);
    }
  };

  //upd   comment text input
  // const createTextInput = (
  //   text,
  //   fontFamily,
  //   left = 50,
  //   top = 50,
  //   isEditable = true,
  //   fontSize = selectedFontSize
  // ) => {
  //   const existingTextObject = mainCanvas.getActiveObject();
  //   if (existingTextObject && existingTextObject.type === "i-text") {
  //     alert("Please delete your previous text before adding a new one.");
  //     return;
  //   }

  //   const offset = 20;
  //   const randomLeft = left + Math.random() * offset - offset / 2;
  //   const randomTop = top + Math.random() * offset - offset / 2;

  //   const newText = new fabric.IText(text, {
  //     left: randomLeft,
  //     top: randomTop,
  //     fill: "black",
  //     fontFamily: fontFamily,
  //     fontSize: fontSize,
  //     charSpacing: letterSpacingValue * 10,
  //     editable: isEditable,
  //     metadata: {
  //       role: selectedTab,
  //       timestamp: new Date().toISOString(),
  //     },
  //     textAlign: "left",
  //   });

  //   // Initialize customWidth
  //   newText.customWidth = newText.width;

  //   // Set initial properties
  //   newText.set({
  //     width: newText.customWidth,
  //     scaleX: 1,
  //     scaleY: 1,
  //   });

  //   newText.hasControls = true;
  //   newText.hasBorders = true;

  //   mainCanvas.add(newText);
  //   mainCanvas.setActiveObject(newText);

  //   if (isEditable) {
  //     newText.enterEditing();
  //     newText.selectAll();
  //   }

  //   newText.on("selected", () => {
  //     setSelectedFontSize(newText.get("fontSize"));
  //   });

  //   newText.on("changed", () => {
  //     // Preserve custom width during changes
  //     newText.customWidth = newText.customWidth || newText.width;
  //     console.log("Text changed. Custom width preserved:", newText.customWidth); // Debugging
  //     newText.set({
  //       width: newText.customWidth,
  //       scaleX: 1,
  //       scaleY: 1,
  //     });
  //     newText.setCoords();
  //     mainCanvas.requestRenderAll();
  //   });

  //   // newText.on("scaling", (e) => {
  //   //   const target = e.target;
  //   //   if (target && target.type === "i-text") {
  //   //     // Ensure customWidth is applied during scaling
  //   //     target.set({
  //   //       width: target.customWidth || target.width,
  //   //       scaleX: 1,
  //   //       scaleY: 1,
  //   //     });
  //   //   }
  //   // });
  //   newText.on("changed", () => {
  //     const textLength = newText.text.length;
  //     const textWidth = newText.width;
  //     if (textWidth >= newText.customWidth) {
  //       const additionalWidth =
  //         (textLength - newText.customWidth / fontSize) * 2;
  //       newText.customWidth = newText.customWidth + additionalWidth;
  //       console.log(
  //         "Text changed. Custom width adjusted:",
  //         newText.customWidth
  //       );
  //     }

  //     newText.set({
  //       width: newText.customWidth,
  //       scaleX: 1,
  //       scaleY: 1,
  //     });
  //     newText.setCoords();
  //     mainCanvas.requestRenderAll();
  //   });
  //   newText.on("scaling", (e) => {
  //     const target = e.target;
  //     if (target && target.type === "i-text") {
  //       const minWidth = target.calcTextWidth();
  //       const newWidth = Math.max(minWidth, target.width * target.scaleX);

  //       target.set({
  //         width: newWidth,
  //         customWidth: newWidth,
  //         scaleX: 1,
  //         scaleY: 1,
  //       });

  //       console.log(
  //         `Scaling event. New width: ${newWidth}, Min width: ${minWidth}`
  //       );
  //     }
  //   });
  //   newText.on("editing:entered", () => {
  //     // Save customWidth when editing starts
  //     newText.customWidth = newText.width;
  //     console.log("Editing started. Custom width saved:", newText.customWidth); // Debugging
  //   });

  //   newText.on("editing:exited", () => {
  //     // Restore customWidth if it is set, otherwise use default width
  //     newText.set({
  //       width: newText.customWidth || newText.width,
  //       scaleX: 1,
  //       scaleY: 1,
  //     });
  //     newText.setCoords();
  //     mainCanvas.requestRenderAll();
  //     console.log("Editing ended. Custom width restored:", newText.customWidth); // Debugging
  //   });

  //   setCurrentTextObject(newText);
  // };
  //commeted text old bckp

  // old text
  const createTextInput = (
    sclttab,
    text,
    fontFamily,
    left = 50,
    top = 50,
    isEditable = true,
    fontSize = selectedFontSize
  ) => {
    const existingTextObject = mainCanvas.getActiveObject();
    // if (existingTextObject && existingTextObject.type === "i-text") {
    //   alert("Please delete your previous text before adding a new one.1");
    //   return;
    // } //hide by mk

    const offset = 20;
    const randomLeft = left + Math.random() * offset - offset / 2;
    const randomTop = top + Math.random() * offset - offset / 2;

    const newText = new fabric.IText(text, {
      left: randomLeft,
      top: randomTop,
      fill: "black",
      fontFamily: fontFamily,
      fontSize: fontSize,
      charSpacing: letterSpacingValue * 10,
      editable: isEditable,
      metadata: {
        role: sclttab,
        timestamp: new Date().toISOString(),
      },
      textAlign: "left",
    });

    // Initialize customWidth
    newText.customWidth = newText.width;

    // Set initial properties
    newText.set({
      width: newText.customWidth,
      scaleX: 1,
      scaleY: 1,
    });

    newText.hasControls = true;
    newText.hasBorders = true;

    mainCanvas.add(newText);
    mainCanvas.setActiveObject(newText);

    if (isEditable) {
      newText.enterEditing();
      newText.selectAll();
    }

    newText.on("selected", () => {
      setSelectedFontSize(newText.get("fontSize"));
    });

    // newText.on("changed", () => {
    //   // Preserve custom width during changes
    //   newText.customWidth = newText.customWidth || newText.width;
    //   console.log("Text changed. Custom width preserved:", newText.customWidth); // Debugging
    //   newText.set({
    //     width: newText.customWidth,
    //     scaleX: 1,
    //     scaleY: 1,
    //   });
    //   newText.setCoords();
    //   mainCanvas.requestRenderAll();
    // });

    newText.on("scaling", (e) => {
      const target = e.target;
      if (target && target.type === "i-text") {
        // Ensure customWidth is applied during scaling
        target.set({
          width: target.customWidth || target.width,
          scaleX: 1,
          scaleY: 1,
        });
      }
    });

    // newText.on("editing:entered", () => {
    //   // Save customWidth when editing starts
    //   newText.customWidth = newText.width;
    //   console.log("Editing started. Custom width saved:", newText.customWidth); // Debugging
    // });

    // newText.on("editing:exited", () => {
    //   // Restore customWidth if it is set, otherwise use default width
    //   newText.set({
    //     width: newText.customWidth || newText.width,
    //     scaleX: 1,
    //     scaleY: 1,
    //   });
    //   newText.setCoords();
    //   mainCanvas.requestRenderAll();
    //   console.log("Editing ended. Custom width restored:", newText.customWidth); // Debugging
    // });

    setCurrentTextObject(newText);
    whenRenderFunction(true);
  };

  //text convert into capital
  const convertTextToUpperCase = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (!activeObject || activeObject.type !== "i-text") return;

    // Preserve custom width
    const customWidth = activeObject.customWidth || activeObject.width;

    if (activeObject.originalText) {
      // Revert to original text
      activeObject.set({ text: activeObject.originalText });
      delete activeObject.originalText;
    } else {
      // Save the original text and convert to uppercase
      activeObject.originalText = activeObject.text;
      const upperCaseText = activeObject.text.toUpperCase();
      activeObject.set({ text: upperCaseText });
    }

    // Restore the custom width after changing text
    activeObject.set({
      width: customWidth,
      customWidth,
      scaleX: 1,
      scaleY: 1,
    });

    mainCanvas.renderAll();
    whenRenderFunction(true);
  };

  // Function to change text color
  const handleTextColorChange = (color) => {
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "i-text") {
      const selectionStart = activeObject.selectionStart;
      const selectionEnd = activeObject.selectionEnd;

      if (selectionStart === selectionEnd) {
        activeObject.set("fill", color);
      } else {
        for (let i = selectionStart; i < selectionEnd; i++) {
          activeObject.setSelectionStyles({ fill: color }, i, i + 1);
        }
      }

      activeObject.dirty = true;
      mainCanvas.requestRenderAll();
      isColorChanged1 = true;
    }
  };

  const handleIconColorChange = async (newColor, color_id) => {
    clearTimeout(colorTimeout); // Clear any existing timeout
    let oldColor = $("#" + color_id).attr("color_code");
    const activeObject = mainCanvas.getActiveObject();
    const objData = activeObject;
    let svgDataUrl = objData.src;
    if (!svgDataUrl) {
      svgDataUrl = objData.getSrc();
    }
    const newcolor_code = newColor;

    if (newcolor_code) {
      try {
        const response = await fetch(svgDataUrl);
        const svgData = await response.text();

        const fillColor = getFillColorFromSVG(svgData);
        const modifiedSvgData = replaceColorInSVG(
          svgData,
          fillColor,
          newcolor_code
        );
        console.log("data:image/svg+xml;base64," + btoa(modifiedSvgData));
        // Set the modified SVG data to the object
        activeObject.setSrc(
          "data:image/svg+xml;base64," + btoa(modifiedSvgData),
          () => {
            mainCanvas.renderAll(); // Re-render the canvas
            isColorChanged2 = true;
          }
        );
      } catch (error) {
        console.error("Error fetching SVG data:", error);
      }
    }

    return;
  };

  const replaceColorInSVG = (svgData, oldColor, newColor) => {
    // Replace the old color with the new color where fill exists
    let modifiedSvgData = svgData.replace(
      new RegExp(`fill\\s*=\\s*["']${oldColor}["']`, "gi"),
      `fill="${newColor}"`
    );

    // Add fill attribute where it's missing
    modifiedSvgData = modifiedSvgData.replace(
      /(<(svg)[^>]*)(>)/gi,
      (match, p1, p2, p3) => {
        if (!/fill\s*=/.test(match)) {
          // If no fill attribute is present, add it
          return `${p1} fill="${newColor}"${p3}`;
        }
        return match;
      }
    );
    //console.log(modifiedSvgData);
    return modifiedSvgData;
  };

  //redo objects

  const redoDelete = () => {
    if (redoObjects.length > 0) {
      const objToRedo = redoObjects.pop();
      mainCanvas.remove(objToRedo);
      deletedObjects.push(objToRedo);

      mainCanvas.renderAll();

      updateButtons();
    }
  };
  //undo objects
  const undoDelete = () => {
    if (deletedObjects.length > 0) {
      const obj = deletedObjects.pop();
      if (obj) {
        mainCanvas.add(obj);
        redoObjects.push(obj);
        mainCanvas.renderAll();
        updateButtons();
      }
    }
  };
  //delete objects

  // const whenRenderFunction = (savehistory, firstload = 0) => {
  //   if (savehistory === true) {
  //     if (firstload == 0) {
  //       console.log("change");

  //       let myjson = JSON.stringify(mainCanvas);

  //       state.push(myjson);
  //       console.log(state);
  //       console.log(state.length);
  //     } else {
  //       console.log("first load");
  //       state.push(firstload);

  //     }
  //   }
  // };

  // // Set the Custom width
  // const handleUndo = () => {
  //   console.log("yes come");
  //   //ctrl + Z
  //   let index = state.length - 1 - mods - 1;
  //   if (index >= 0) {
  //     console.log(index);
  //     console.log(mods);
  //     mods++;
  //     const stateToLoad = state[index];

  //     if (stateToLoad) {
  //       //mainCanvas.clear().renderAll();
  //       mainCanvas.loadFromJSON(stateToLoad, () => {
  //         mainCanvas.getObjects().forEach((obj) => {
  //                     if (obj.type === "i-text" && obj.customWidth) {
  //                       console.log(obj.customWidth);
  //                       obj.set({
  //                         width: obj.customWidth,
  //                         scaleX: 1,
  //                         scaleY: 1,
  //                       });
  //                       obj.setCoords();
  //                     }
  //                   });
  //         mainCanvas.renderAll();
  //       });
  //     }
  //   }
  // };

  // const handleRedo = () => {
  //   //if (mods > 0) { // Ensure mods don't go negative
  //   //const index = 1;
  //   //const index = state.length - mods;
  //   let index = state.length - 1 - mods + 1;

  //   if (mods >= 1) {
  //     console.log(index);
  //     console.log(mods);
  //     mods--;
  //     const stateToLoad = state[index];
  //     if (stateToLoad) {
  //       //mainCanvas.clear().renderAll();
  //       mainCanvas.loadFromJSON(stateToLoad, () => {
  //         mainCanvas.getObjects().forEach((obj) => {
  //           if (obj.type === "i-text" && obj.customWidth) {
  //             console.log(obj.customWidth);
  //             obj.set({
  //               width: obj.customWidth,
  //               scaleX: 1,
  //               scaleY: 1,
  //             });
  //             obj.setCoords();
  //           }
  //         });
  //         mainCanvas.renderAll();
  //       });
  //     }
  //   }
  // };

  let history = [];
  let redoStack = [];
  let currentIndex = -1;

  const whenRenderFunction = (savehistory = true) => {
    if (savehistory) {
      saveState();
    }
  };

  const saveState = () => {
    // Remove redo states if any new state is added
    if (currentIndex < history.length - 1) {
      history = history.slice(0, currentIndex + 1);
      redoStack = [];
    }

    const currentState = JSON.stringify(mainCanvas);
    history.push(currentState);
    currentIndex++;
    console.log("State saved:", history.length);
  };

  // Undo operation
  const handleUndo = () => {
    if (currentIndex > 0) {
      redoStack.push(history[currentIndex]);
      currentIndex--;
      const stateToLoad = history[currentIndex];

      if (stateToLoad) {
        mainCanvas.loadFromJSON(stateToLoad, () => {
          mainCanvas.getObjects().forEach((obj) => {
            if (obj.type === "i-text" && obj.customWidth) {
              obj.set({
                width: obj.customWidth,
                scaleX: 1,
                scaleY: 1,
              });
              obj.setCoords();
            }
          });
          mainCanvas.renderAll();
        });
      }
    }
  };

  // Redo operation
  const handleRedo = () => {
    if (redoStack.length > 0) {
      const stateToRedo = redoStack.pop();
      history.push(stateToRedo);
      currentIndex++;

      if (stateToRedo) {
        mainCanvas.loadFromJSON(stateToRedo, () => {
          mainCanvas.getObjects().forEach((obj) => {
            if (obj.type === "i-text" && obj.customWidth) {
              obj.set({
                width: obj.customWidth,
                scaleX: 1,
                scaleY: 1,
              });
              obj.setCoords();
            }
          });
          mainCanvas.renderAll();
        });
      }
    }
  };

  const deleteSelectedObject = () => {
    setIsObjectSelected(true);
    setshopcolor(false);
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "activeSelection") {
      // Handle active selection (multiple objects selected)
      //console.log('group');
      const selectedObjects = activeObject.getObjects();
      selectedObjects.forEach((obj) => {
        handleDelete(obj);
      });
      mainCanvas.discardActiveObject();
      mainCanvas.renderAll();
      UserChangingIconSelection();
    } else if (activeObject) {
      // Handle single object selected
      //console.log('else');
      handleDelete(activeObject);
      mainCanvas.renderAll();
      UserChangingIconSelection();
    }
    whenRenderFunction(true);
    redoObjects = [];
    updateButtons();
  };

  const handleDelete = (obj) => {
    if (obj.type === "i-text") {
      handleTextDelete(obj);
    } else {
      handleObjectDelete(obj);
    }
  };

  const handleTextDelete = (obj) => {
    if (obj.type === "i-text") {
      if (obj.isEditing) {
        // If currently editing, delete a single character
        const text = obj.text;
        const cursorStart = obj.selectionStart;
        const cursorEnd = obj.selectionEnd;
        if (cursorStart !== cursorEnd) {
          // Delete selected text
          obj.removeChars(cursorStart, cursorEnd - cursorStart);
        } else {
          // Delete single character at cursor position
          if (cursorStart > 0) {
            obj.removeChars(cursorStart - 1, 1);
          }
        }
      } else {
        // If not editing, delete the entire object
        if (
          obj.metadata &&
          (obj.metadata.role === "company_name" ||
            obj.metadata.role === "slogan" ||
            obj.metadata.role === "Letter_first" ||
            obj.metadata.role === "Letter_Second" ||
            obj.metadata.role === "Letter_Third")
        ) {
          if (obj.metadata.role === "company_name") {
            setcompany_nametext(false);
          } else if (obj.metadata.role === "slogan") {
            setslogantext(false);
          } else if (obj.metadata.role === "Letter_first") {
            setslettertext1(false);
          } else if (obj.metadata.role === "Letter_Second") {
            setslettertext2(false);
          } else if (obj.metadata.role === "Letter_Third") {
            setslettertext3(false);
          }
        }
        mainCanvas.remove(obj);
        deletedObjects.push(obj);
      }
    }
  };

  const handleObjectDelete = (obj) => {
    // If not a text object, delete the entire object
    mainCanvas.remove(obj);
    deletedObjects.push(obj);
    setIsObjectSelected(true);
    setshopcolor(false);
  };
  const handleDeleteClick = () => {
    deleteSelectedObject();
    handleDeleteText();
  };
  const updateButtons = () => {
    const undoButton = document.getElementById("undoButton");
    const redoButton = document.getElementById("redoButton");
    const undoImageSrc = deletedObjects.length === 0 ? redoIcn : redoIcnBlack;
    const redoImageSrc = redoObjects.length === 0 ? undoIcn : undoIcnBlack;

    undoButton.innerHTML = `<img src="${undoImageSrc}" alt="Undo">`;
    redoButton.innerHTML = `<img src="${redoImageSrc}" alt="Redo">`;
  };
  const handleKeyDown = (event) => {
    if (event.key === "Delete") {
      deleteSelectedObject();
      redoDelete(); // Function to redo deletion
    } else if (event.ctrlKey && event.key === "z") {
      //event.preventDefault();
      handleUndo();
      //console.log("click on z");
      //mainCanvas.undo()
    } else if (event.ctrlKey && event.key === "y") {
      //event.preventDefault();
      handleRedo();
      //mainCanvas.redo()
    } else if (event.ctrlKey && event.key === "x") {
      cutSelectedObject(); // Function to cut selected object(s)
    } else if (event.ctrlKey && event.key === "c") {
      copySelectedObject(); // Function to copy selected object(s)
    } else if (event.ctrlKey && event.key === "v") {
      pasteCopiedObject(); // Function to paste copied object(s)
    } else if (event.ctrlKey && event.key === "a") {
      handleSelectAll(event); // Function to select all objects
    } else if (event.ctrlKey && event.key === "b") {
      handleFontStyleChange(true, false);
    } else if (event.ctrlKey && event.key === "i") {
      handleFontStyleChange(false, true);
    } else if (event.ctrlKey && (event.key === "." || event.key === "=")) {
      console.log("Increasing font size with shortcut.");
      handleFontSizeChange(1);
    } else if (event.ctrlKey && event.key === ",") {
      console.log("Decreasing font size with shortcut.");
      handleFontSizeChange(-1);
    } else if (event.ctrlKey && event.key === "d") {
      event.preventDefault();
      duplicateObject();
    } else if (event.ctrlKey && event.shiftKey && event.key === "L") {
      event.preventDefault();
      toggleAlignment("left"); // Left align text
    } else if (event.ctrlKey && event.shiftKey && event.key === "C") {
      event.preventDefault();
      toggleAlignment("center"); // Center align text
    } else if (event.ctrlKey && event.shiftKey && event.key === "R") {
      event.preventDefault();
      toggleAlignment("right"); // Right align text
    } else if (event.ctrlKey && event.altKey && event.key === ".") {
      event.preventDefault();
      console.log("Increasing letter spacing.");
      handleLetterSpacingChange({
        target: { value: (parseFloat(letterSpacingValue) || 0) + 1 },
      });
    } else if (event.ctrlKey && event.altKey && event.key === ",") {
      event.preventDefault();
      handleLetterSpacingChange({
        target: { value: (parseFloat(letterSpacingValue) || 0) - 1 },
      });
    } else if (event.ctrlKey && event.shiftKey && event.key === "K") {
      event.preventDefault();

      convertTextToUpperCase();
    }
  };

  // Select All Objects shortcut
  const handleSelectAll = (event) => {
    if (event.key === "a" && event.ctrlKey) {
      event.preventDefault();
      const objects = mainCanvas.getObjects();
      mainCanvas.discardActiveObject();

      if (objects.length > 0) {
        const selection = new fabric.ActiveSelection(objects, {
          canvas: mainCanvas,
        });
        mainCanvas.setActiveObject(selection);
        mainCanvas.requestRenderAll();
        setIsGroupObj(true);
        setIsTextToolVisible(false);
        setIsObjectSelected(false); // Hide object-selected toolbar
        //console.log("All objects selected, showing group toolbar.");
      }
    }
    const activeObject = mainCanvas.getActiveObject();
    if (!activeObject) return;

    if (event.key === "ArrowUp") {
      event.preventDefault();
      activeObject.set("top", activeObject.top - 1);
      mainCanvas.requestRenderAll();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      activeObject.set("top", activeObject.top + 1);
      mainCanvas.requestRenderAll();
    } else if (event.key === "ArrowLeft") {
      event.preventDefault();
      activeObject.set("left", activeObject.left - 1);
      mainCanvas.requestRenderAll();
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      activeObject.set("left", activeObject.left + 1);
      mainCanvas.requestRenderAll();
    }
  };

  // Function to cut selected object(s)
  const cutSelectedObject = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "i-text") {
        // Do nothing if the active object is a text object
        return;
      }
      copyToClipboard(activeObject); // Copy to clipboard
      deleteSelectedObject(); // Delete selected object(s)
    }
  };

  // Function to copy selected object(s)
  const copySelectedObject = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "i-text") {
        return;
      }
      copyToClipboard(activeObject); // Copy to clipboard
    }
  };

  // Function to paste copied object(s)
  const pasteCopiedObject = () => {
    if (clipboard) {
      fabric.util.enlivenObjects([clipboard], (objects) => {
        const pastedObject = objects[0];
        pastedObject.set({
          left: 10,
          top: 10,
        });
        mainCanvas.add(pastedObject);
        mainCanvas.setActiveObject(pastedObject);
        mainCanvas.requestRenderAll();
        whenRenderFunction(true);
      });
    }
  };

  // Function to copy object to clipboard
  const copyToClipboard = (object) => {
    clipboard = fabric.util.object.clone(object);
  };
  //open color pallate
  const handleButtonClick = () => {
    colorPickerRef.current.click();
  };

  //canvas Background color change

  const colors = [
    {
      title: "Gradient Colors",
      list: [
        ["#ff7e5f", "#feb47b"],
        ["#6a11cb", "#2575fc"],
        ["#f953c6", "#b91d73"],
        ["#00f260", "#0575e6"],
        ["#e1eec3", "#f05053"],
        ["#ffb6b9", "#f0a6ca"],
        ["#11998e", "#38ef7d"],
        ["#5c258d", "#4389a2"],
        ["#4da0b0", "#d39d38"],
        ["#0b486b", "#3b8686"],
      ],
    },
    {
      title: "Logo Colors",
      list: ["#310b00", "#fefefe", "#757475", "#b6b6b6"],
    },

    {
      title: "Default Colors",
      list: [
        "#ebff00",
        "#ff7448",
        "#ff000f",
        "#374bff",
        "#FF616A",
        "#ca6ce6",
        "#8c53ff",
        "#5272ff",
        "#5ce1e6",
        "#7ed958",
        "#c9e265",
        "#ffbd59",
        "#ffffff",
      ],
    },
    {
      title: "Blue",
      list: [
        "#f0f8ff",
        "#b0c4de",

        "#6495ed",
        "#4169e1",
        "#1e90ff",
        "#4682b4",

        "#00bfff",
        "#87ceeb",
        "#5f9ea0",
        "#afeeee",
        "#87cefa",
        "#00ced1",
      ],
    },
    {
      title: "Teal",
      list: [
        "#00FF00",
        "#00FA00",
        "#00F500",
        "#00F000",
        "#00EB00",
        "#00E600",
        "#00E100",
        "#00DC00",
        "#00D700",
        "#00D200",
        "#00CD00",
        "#00C800",
        "#00C300",
        "#00BE00",
        "#00B900",
        "#00B400",
        "#00AF00",
        "#00AA00",
        "#00A500",
        "#00A000",
      ],
    },
  ];

  //edting tool baar toggles

  const toggle = (toggleType) => {
    setReplaceText(false); //means sidebar se click hoga to font type replace nhi hoga
    // Map each toggle type to its corresponding state setter
    const toggleStateMap = {
      icon: setIsOpenicon,
      text: setIsOpentext,
      background: setIsOpenBg,
      shapes: setIsOpenshape,
      template: setIsOpenTemplate,
      AboutIcon: setIsOpenAbout,
      uploads: setIsOpenuploads,
      color: setIsColor,
      letter: setIsletter,
    };

    // Toggle the state of the clicked toggle
    toggleStateMap[toggleType]((prevState) => !prevState);

    // Hide other toggles if the clicked toggle was already open
    Object.keys(toggleStateMap).forEach((type) => {
      if (type !== toggleType) {
        toggleStateMap[type](false);
      }
    });
    setClicked(!clicked);
    setClicked2(!clicked2);
    setIsActive2(toggleType === "text");
    setIsActive3(toggleType === "background");
    setIsActive4(toggleType === "shapes");
    setIsActive5(toggleType === "template");

    setIsActive7(toggleType === "uploads");
    setIsActive8(toggleType === "color");
    setIsActive9(toggleType === "letter");
  };

  //close
  const handleClose = () => {
    console.log("come2");
    setIsOpentext(false);
    setIsOpenBg(false);
    setIsOpenshape(false);
    setIsOpenTemplate(false);
    setIsOpenAbout(false);
    setIsOpenuploads(false);
    setIsColor(false);
    setIsletter(false);
  };

  //when you click the shpe toolbar show

  const handleObjectDeselection = () => {
    setIsObjectSelected(false);
    setshopcolor(true);
  };

  fabric.IText.prototype.toObject = (function (toObject) {
    return function () {
      return fabric.util.object.extend(toObject.call(this), {
        metadata: this.metadata,
      });
    };
  })(fabric.IText.prototype.toObject);
  //save canvas

  fabric.Image.prototype.toObject = (function (toObject) {
    return function () {
      return fabric.util.object.extend(toObject.call(this), {
        metadata: this.metadata,
        MkIconId: this.MkIconId,
      });
    };
  })(fabric.Image.prototype.toObject);

  //flip Options SHow
  const toggleFlipButtons = () => {
    setShowFlipButtons(!showFlipButtons);
  };

  //Flip object and text
  const flipObject = (direction) => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      if (direction === "horizontal") {
        activeObject.flipX = !activeObject.flipX;
      } else if (direction === "vertical") {
        activeObject.flipY = !activeObject.flipY;
      }
      mainCanvas.requestRenderAll();
    }
  };

  //opesity control on the object  and text
  const toggleOpacityInput = () => {
    setShowOpacityInput((prevShow) => !prevShow);
  };

  const handleOpacityChange = (event, group = "0") => {
    const newOpacity = parseInt(event.target.value);
    setOpacity(newOpacity);
    const activeObject = mainCanvas.getActiveObject();
    if (mainCanvas.getActiveObject()) {
      if (group == "0") {
        mainCanvas.getActiveObject().set("opacity", newOpacity / 100);
      } else {
        activeObject._objects.forEach((obj) => {
          obj.set("opacity", newOpacity / 100);
          obj.setCoords(); // Update coordinates for proper rendering
        });
        activeObject.set("opacity", newOpacity / 100);
      }
    }

    mainCanvas.requestRenderAll();
  };
  //duplicate object
  const duplicateObject = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.clone((clonedObject) => {
        // Preserve custom width if it exists
        const customWidth = activeObject.customWidth || activeObject.width;

        clonedObject.set({
          left: activeObject.left + 10,
          top: activeObject.top - 10,
          width: customWidth, // Apply custom width to the cloned object
          customWidth, // Ensure customWidth is preserved
          scaleX: 1,
          scaleY: 1,
        });

        mainCanvas.add(clonedObject);
        mainCanvas.setActiveObject(clonedObject);
        mainCanvas.requestRenderAll();
        whenRenderFunction(true);
      });
    }
  };

  //options show in the navbar
  const toggleSecondButton = () => {
    setShowSecondButton((prev) => {
      setShowSecondButton2(false);
      return !prev;
    });
  };

  const toggleSecondButton2 = () => {
    setDownload(!showDownload);
    setShowSecondButton2((prev) => {
      setShowSecondButton(false);
      return !prev;
    });
  };

  //Sidebar on off

  const sidebarClassName =
    windowWidth > 500
      ? isOpenicon ||
        isOpenBg ||
        isOpentext ||
        isOpenshape ||
        isOpenuploads ||
        isOpenAbout ||
        isOpenTemplate ||
        isColor ||
        isletter
        ? "sideBarContentContainer sideBarContentContainerShow"
        : "sideBarContentContainer"
      : isOpenicon ||
        isOpenBg ||
        isOpentext ||
        isOpenshape ||
        isOpenuploads ||
        isOpenAbout ||
        isOpenTemplate ||
        isColor ||
        isletter
      ? "mobileSideBarContentContainer mobileSideBarContentContainerShow"
      : "mobileSideBarContentContainer";

  //hover sidebar icon chnge  color change  and actice

  const handleButtonUploadimg = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/svg+xml";
    input.style.display = "none";

    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const svgContent = await readSVGFile(file);

        await handleUpload(file, svgContent);
        setshowLoaderAdmin(true);
        toast("Element Uploaded Succesfully");
      }
    };

    document.body.appendChild(input);
    input.click();
  };

  const handleShapesButtonUploadimg = (file_type) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/svg+xml";
    input.style.display = "none";

    // Map file_type to the desired shape_type
    let shape_type;
    switch (file_type) {
      case "frame":
        shape_type = "Frames";
        break;
      case "shape":
        shape_type = "Shapes";
        break;
      case "line":
        shape_type = "Lines";
        break;
      default:
        shape_type = "Unknown"; // or handle this case as needed
    }

    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const svgContent = await readSVGFile(file);

        await handleShapeUpload(file, svgContent, shape_type);
        setshowLoaderAdmin(true);
        toast("Shape Uploaded Succesfully");
      }
    };

    document.body.appendChild(input);
    input.click();
  };

  const readSVGFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };
  //------------------

  //-------------------- color code in image

  const handleImageClick = async (src, add_or_not) => {
    if (!mainCanvas) return;
    console.log(src);
    try {
      let colors = [];
      if (src.startsWith("data:image/svg+xml;base64,")) {
        console.log("1");
        // Extract colors from SVG
        const svgData = atob(src.split(",")[1]);
        colors = extractTopColorsFromSVG(svgData, 3); // Get top 3 colors
        if (add_or_not != 1) {
          fabric.loadSVGFromString(svgData, (objects, options) => {
            const svg = fabric.util.groupSVGElements(objects, options);
            const newColor = "#000000"; // Default black color
            svg.getObjects().forEach((obj) => {
              if (obj.set) {
                obj.set({ fill: newColor });
              }
            });

            // Set properties for SVG with updated position
            svg.set({
              left: position.left,
              top: position.top,
              scaleX: 70 / svg.width,
              scaleY: 70 / svg.height,
              globalCompositeOperation: src,
              CustomImage: "custom",
            });

            mainCanvas.add(svg).setActiveObject(svg);
            mainCanvas.renderAll();
            updatePosition(); // Update position for next image
          });
        }
      } else {
        // If it's a regular base64 image URL
        const imgElement = await loadImage(src);
        colors = extractTopColorsFromImage(imgElement, 3); // Get top 3 colors
        if (add_or_not != 1) {
          const timestamp = new Date().getTime().toString(36); // Convert the current time to a base-36 string
          const randomNumber = Math.floor(Math.random() * 1000000000).toString(
            36
          );
          const fabricImage = new fabric.Image(imgElement, {
            left: position.left,
            top: position.top,
            scaleX: 70 / imgElement.width,
            scaleY: 70 / imgElement.height,
            globalCompositeOperation: src,
            CustomImage: "custom",
            MkIconId: `MkIconId_${timestamp}_${randomNumber}`, // Assign unique ID,
            metadata: {
              role: "element",
              timestamp: new Date().toISOString(),
            },
          });
          fabricImage.MkIconId = new Date().toISOString();
          fabricImage.metadata = {
            role: "element",
            timestamp: new Date().toISOString(),
          };
          console.log(fabricImage);
          mainCanvas.add(fabricImage).setActiveObject(fabricImage);
          mainCanvas.renderAll();
          updatePosition();
        }
      }
      //  setcolorcodeloop(colors);
      setColorCount(colors.length); // Set the number of colors extracted
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };

  const extractTopColorsFromSVG = (svgData, topN) => {
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgData, "image/svg+xml");
    const colorCounts = {};

    const extractColors = (node) => {
      if (node.getAttribute) {
        const fill = node.getAttribute("fill");
        const stroke = node.getAttribute("stroke");
        if (fill) colorCounts[fill] = (colorCounts[fill] || 0) + 1;
        if (stroke) colorCounts[stroke] = (colorCounts[stroke] || 0) + 1;
      }

      for (let i = 0; i < node.childNodes.length; i++) {
        extractColors(node.childNodes[i]);
      }
    };

    extractColors(svgDoc.documentElement);

    // Group similar colors
    const groupedColors = groupSimilarColors(colorCounts);

    // Extract only unique colors
    const uniqueColors = Object.keys(groupedColors).filter(
      (color, index, self) => self.indexOf(color) === index
    );

    const sortedUniqueColors = uniqueColors.sort(
      (a, b) => groupedColors[b] - groupedColors[a]
    );

    return sortedUniqueColors.slice(0, topN);
  };

  const groupSimilarColors = (colorCounts) => {
    const groupedColors = {};
    const threshold = 32; // Adjust threshold for color similarity

    const parseColor = (color) => {
      const hex = color.replace("#", "");
      const bigint = parseInt(hex, 16);
      return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
      };
    };

    const isSimilarColor = (color1, color2) => {
      const c1 = parseColor(color1);
      const c2 = parseColor(color2);
      return (
        Math.abs(c1.r - c2.r) < threshold &&
        Math.abs(c1.g - c2.g) < threshold &&
        Math.abs(c1.b - c2.b) < threshold
      );
    };

    Object.keys(colorCounts).forEach((color) => {
      let found = false;
      for (const groupedColor in groupedColors) {
        if (isSimilarColor(color, groupedColor)) {
          groupedColors[groupedColor] += colorCounts[color];
          found = true;
          break;
        }
      }
      if (!found) {
        groupedColors[color] = colorCounts[color];
      }
    });

    return groupedColors;
  };

  // Helper function to extract top colors from image
  const extractTopColorsFromImage = (imgElement, topN) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = imgElement.width;
    canvas.height = imgElement.height;
    ctx.drawImage(imgElement, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    const colorCounts = {};
    for (let i = 0; i < imageData.length; i += 4) {
      const r = imageData[i];
      const g = imageData[i + 1];
      const b = imageData[i + 2];
      const a = imageData[i + 3];
      if (a !== 0) {
        // Exclude transparent pixels
        const color_code = `rgba(${r},${g},${b},${a / 255})`;
        const color = rgbToHex(color_code);
        colorCounts[color] = (colorCounts[color] || 0) + 1;
      }
    }
    const sortedColors = Object.entries(colorCounts).sort(
      (a, b) => b[1] - a[1]
    );

    // Extract only unique colors
    const uniqueColors = sortedColors.map((color) => color[0]);

    return uniqueColors.slice(0, topN);
  };

  // Helper function to load image
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };
  // Helper function to convert RGBA to hex format
  const rgbToHex = (rgbaColor) => {
    const [r, g, b] = rgbaColor.match(/\d+/g);
    return `#${Number(r).toString(16).padStart(2, "0")}${Number(g)
      .toString(16)
      .padStart(2, "0")}${Number(b).toString(16).padStart(2, "0")}`;
  };

  //--------------------color code in image

  const updatePosition = () => {
    setPosition((prevPosition) => ({
      left: prevPosition.left + 10, // Adjust increment as needed
      top: prevPosition.top + 10, // Adjust increment as needed
    }));
  };

  //text aligment change

  const handleAlignment = (alignmentType, group = "0") => {
    alignSelectedObjects(
      alignmentType,
      alignmentType === "center" || alignmentType === "middle",
      group
    );
  };

  const handleAlignmentGroupUngroup = () => {
    if (GroupUngroup == "Group") {
      setGroupUngroup("Ungroup");
    } else {
      setGroupUngroup("Group");
    }
  };

  const alignSelectedObjects = (
    alignmentType,
    alignVertical = false,
    groupings
  ) => {
    const activeObject = mainCanvas.getActiveObject();
    if (!activeObject) return;

    const canvasWidth = mainCanvas.width;
    const canvasHeight = mainCanvas.height;
    const objectWidth = activeObject.getScaledWidth();
    const objectHeight = activeObject.getScaledHeight();
    let grpuoldbck;
    if (GroupUngroup == "Group") {
      grpuoldbck = 0;
    } else {
      grpuoldbck = groupings;
    }
    if (grpuoldbck == "0") {
      console.log(activeObject);
      if (alignmentType === "left") {
        activeObject.set({ left: 0 });
      } else if (alignmentType === "right") {
        activeObject.set({ left: canvasWidth - objectWidth });
      } else if (alignmentType === "top") {
        activeObject.set({ top: 0 });
      } else if (alignmentType === "bottom") {
        activeObject.set({ top: canvasHeight - objectHeight });
      } else if (alignmentType === "middle") {
        // activeObject.set({
        //   left: (canvasWidth - objectWidth) / 2,
        //   top: (canvasHeight - objectHeight) / 2,
        // });
        activeObject.set({
          top: (canvasHeight - objectHeight) / 2,
        });
      } else if (alignmentType === "center") {
        activeObject.set({
          left: (canvasWidth - objectWidth) / 2,
        });
        // if (alignVertical) {
        //   activeObject.set({
        //     top: (canvasHeight - objectHeight) / 2,
        //   });
        // } else {
        //   activeObject.set({
        //     left: (canvasWidth - objectWidth) / 2,
        //   });
        // }
      }
      activeObject.setCoords();
    } else {
      if (!activeObject || !activeObject.type === "group") return;

      const group = activeObject;

      let groupLeft, groupTop;

      const groupWidth = group.width * group.scaleX;
      const groupHeight = group.height * group.scaleY;

      switch (alignmentType) {
        case "left":
          groupLeft = 0;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        case "right":
          groupLeft = canvasWidth - groupWidth;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        case "center":
          groupLeft = (canvasWidth - groupWidth) / 2;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        case "middle":
          groupLeft = (canvasWidth - groupWidth) / 2;
          groupTop = (canvasHeight - groupHeight) / 2;
          break;
        default:
          return;
      }

      // Update group position
      group.set({
        left: groupLeft,
      });
      group.setCoords();
      mainCanvas.discardActiveObject();
      mainCanvas.renderAll();
      group._objects.forEach((obj) => {
        if (alignmentType === "left") {
          obj.set({ left: 0 });
        } else if (alignmentType === "right") {
          obj.set({ left: canvasWidth - obj.getScaledWidth() });
        } else if (alignmentType === "middle") {
          obj.set({
            top: (canvasHeight - obj.getScaledHeight()) / 2,
          });
        } else if (alignmentType === "center") {
          obj.set({
            left: (canvasWidth - obj.getScaledWidth()) / 2,
          });
        }

        obj.setCoords();
      });
      mainCanvas.renderAll();
      // Step 4: Reselect the group
      const selectedObjects = [];
      group._objects.forEach((obj) => {
        console.log(obj);
        selectedObjects.push(obj);
      });

      const selection = new fabric.ActiveSelection(selectedObjects, {
        canvas: mainCanvas,
      });

      mainCanvas.setActiveObject(selection);
      mainCanvas.renderAll();
    }

    mainCanvas.renderAll();
    //mainCanvas.requestRenderAll();
    whenRenderFunction(true);
  };

  const ungroupObjects = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject && activeObject.type === "group") {
      activeObject.toActiveSelection(); // Ungroups the objects into an active selection
      mainCanvas.discardActiveObject(); // Clears the selection
      mainCanvas.renderAll(); // Renders the canvas to reflect the changes
    }
  };

  //Possitons Layer Show
  const handleShowLayers = () => {
    SetPossitionsLayers(!isPossitionsLayers);
  };

  const bringToFront = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.bringToFront();
      StackingOrderObject(activeObject, "bringToFront");
    }
  };

  const bringLayerUp = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.bringForward();
      StackingOrderObject(activeObject, "bringLayerUp");
    }
  };

  const sendToBack = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      console.log("Sending to Back:", activeObject.id); // Debugging log
      activeObject.sendToBack();
      StackingOrderObject(activeObject, "sendToBack");
    }
  };

  const bringLayerDown = () => {
    const activeObject = mainCanvas.getActiveObject();
    if (activeObject) {
      activeObject.sendBackwards();
      StackingOrderObject(activeObject, "bringLayerDown");
    }
  };

  const StackingOrderObject = (activeObject, optn) => {
    // Get the current order of objects in the canvas
    const objects = mainCanvas.getObjects();
    const index = objects.indexOf(activeObject);

    if (index === -1) return; // If the object is not found, return early
    console.log(index);
    console.log(objects);
    return;
    // Remove the object from its current position
    objects.splice(index, 1);

    // Modify the stacking order based on the operation
    switch (optn) {
      case "bringToFront":
        objects.push(activeObject); // Add the object to the end (top)
        break;
      case "bringLayerUp":
        if (index < objects.length) {
          objects.splice(index + 1, 0, activeObject); // Move it one layer up
        }
        break;
      case "sendToBack":
        objects.unshift(activeObject); // Add the object to the beginning (bottom)
        break;
      case "bringLayerDown":
        if (index > 0) {
          objects.splice(index - 1, 0, activeObject); // Move it one layer down
        }
        break;
    }

    // Update the canvas objects order
    mainCanvas.clear(); // Clear the canvas
    objects.forEach((obj) => mainCanvas.add(obj)); // Re-add the objects in the new order

    // Re-render the canvas to reflect the new stacking order
    mainCanvas.requestRenderAll();
  };

  //object Aligment
  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };
  //get options show when i hover the img
  const handleMouseEnter4 = (index) => {
    setHoveredImageIndex(index);
  };

  const handleMouseLeave4 = () => {
    setHoveredImageIndex(null);
  };

  const toggleVisibility4 = (index) => {
    setIsVisible4((prev) => (prev === index ? null : index));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        HideDwnUpRef.current &&
        !HideDwnUpRef.current.contains(event.target) &&
        uploadRef.current &&
        !uploadRef.current.contains(event.target)
      ) {
        setIsVisible4(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //upload img get  img Download Btn
  const handleDownload = async (imageSrc) => {
    // try {
    //   const response = await fetch(imageSrc);
    //   const blob = await response.blob();
    //   saveAs(blob, imageSrc.split("/").pop());
    // } catch (error) {}
  };

  const handleMouseDown = (options) => {
    const target = options.target;
    const activeObjects = mainCanvas.getActiveObjects();

    if (activeObjects.length > 1) {
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setIsGroupObj(true); // Show group toolbar
      setshopcolor(true);
    } else if (
      target &&
      (target.type === "i-text" || target.type === "textbox")
    ) {
      setIsTextToolVisible(true);
      setIsObjectSelected(false);
      setIsGroupObj(false);
      setshopcolor(true);
    } else if (target) {
      setIsTextToolVisible(false);
      setIsObjectSelected(true); // Show object toolbar
      setIsGroupObj(false); // Hide group toolbar if a single object is selected
      setshopcolor(true);
    } else {
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setIsGroupObj(false); // Hide group toolbar
      setshopcolor(true);
    }
  };

  //show  the resize custom dimention when i  check input
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "Business Card", dimensions: "90x55mm", checked: false },
    { id: 2, label: "Letterhead", dimensions: "210x297mm", checked: false },
    { id: 3, label: "Envelope", dimensions: "595x282mm", checked: false },
    { id: 4, label: "Invitation Card", dimensions: "91x114mm", checked: false },
    { id: 5, label: "Logo", dimensions: "500x500px", checked: false },
    {
      id: 6,
      label: "Instagram Post",
      dimensions: "1080x1080px",
      checked: false,
    },
    { id: 7, label: "Twitter", dimensions: "1600x900px", checked: false },
    { id: 8, label: "Facebook Post", dimensions: "1200x630px", checked: false },
    {
      id: 9,
      label: "Instagram Story",
      dimensions: "1080x1920px",
      checked: false,
    },
  ]);
  //custom width Height width on the canvas  in the resize button option
  const handleCheckboxChange = () => {
    const newManualSetupState = !isManualSetup;
    setIsManualSetup(newManualSetupState);
    setButtonColor(newManualSetupState ? "#6473fd" : "#6473fd90");

    if (newManualSetupState) {
      const uncheckedCheckboxes = checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: false,
      }));
      setCheckboxes(uncheckedCheckboxes);
    }
  };
  //Fix width Height width on the canvas  in the resize button option
  const handleCheckboxChange2 = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id
        ? { ...checkbox, checked: !checkbox.checked }
        : { ...checkbox, checked: false }
    );
    setCheckboxes(updatedCheckboxes);

    const anyChecked = updatedCheckboxes.some((checkbox) => checkbox.checked);
    setButtonColor(anyChecked ? "#6473fd" : "#6473fd90");

    if (anyChecked) {
      setIsManualSetup(false);
    }
  };
  // Function to handle the resize button click

  //bg color active classs
  const handleClick = (color) => {
    setActiveColor(color);

    if (Array.isArray(color)) {
      const gradient = `linear-gradient(to right, ${color.join(", ")})`;
      const syntheticEvent = {
        target: { value: gradient },
      };
      handleCanvasColorChange(syntheticEvent, color);
    } else {
      const syntheticEvent = {
        target: { value: color },
      };
      handleCanvasColorChange(syntheticEvent);
    }
  };
  const templateparseGradient = (gradientString) => {
    const gradientRegex = /linear-gradient\(([^,]+),(.+)\)/;
    const matches = gradientString.match(gradientRegex);

    if (matches) {
      const direction = matches[1].trim();
      const colors = matches[2].split(",").map((color) => color.trim());
      return { direction, colors };
    }

    // Default case if regex does not match (fallback)
    return { direction: "to right", colors: [gradientString] };
  };

  const setCanvasBackground = (canvas, background) => {
    if (background.startsWith("linear-gradient")) {
      const { direction, colors } = templateparseGradient(background);

      let x1 = 0,
        y1 = 0,
        x2 = 0,
        y2 = 0;
      if (direction.includes("to right")) {
        x2 = canvas.width;
      } else if (direction.includes("to bottom")) {
        y2 = canvas.height;
      } else if (direction.includes("to left")) {
        x1 = canvas.width;
      } else if (direction.includes("to top")) {
        y1 = canvas.height;
      }

      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "pixels",
        coords: { x1, y1, x2, y2 },
        colorStops: colors.map((color, index) => ({
          offset: index / (colors.length - 1),
          color,
        })),
      });

      canvas.setBackgroundColor(gradient, canvas.renderAll.bind(canvas));
    } else {
      canvas.setBackgroundColor(background, canvas.renderAll.bind(canvas));
    }
  };

  const colRef = useRef(null);
  const [colWidth, setColWidth] = useState();

  const updateCanvasSize = () => {
    if (colRef.current) {
      const colWidth = colRef.current.offsetWidth;
      const colHeight = colRef.current.offsetHeight;
      setColWidth(colWidth);
    }
  };

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener("resize", updateCanvasSize);
    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, []);

  const createCanvas = (
    ref,
    logoData,
    variants = "",
    index = "",
    colordata = "",
    colorIndex = ""
  ) => {
    return new Promise((resolve) => {
      //console.log(colordata);
      if (!ref || !logoData) return;

      let canvasWidth;
      let canvasHeight;
      let background = "#000";
      // let colWidth=200;

      if (variants == "variants") {
        // if (colRef.current) {
        //   colWidth = colRef.current.offsetWidth;
        //   setColWidth(colWidth);
        // }
        canvasWidth = 220;
        canvasHeight = 210;
        if (colordata != "0") {
          //same
          background = colordata.background;

          if (index == selectedLogo[colorIndex]) {
            $(".selectedLgotest" + colorIndex).removeClass("selectedLgo");
            $(".selectedLgo" + index + "_" + colorIndex).addClass(
              "selectedLgo"
            );
          }
        } else {
          background = logoData.background;
          ///console.log(background);
        }
      } else {
        canvasWidth = 112;
        canvasHeight = 112;
        background = logoData.background;
      }
      let canvas_temp = ref.__canvas || null;

      if (canvas_temp) {
        // Adjust canvas size and clear existing content
        canvas_temp.setDimensions({ width: canvasWidth, height: canvasHeight });
        canvas_temp.clear();
        //console.log(background);
        setCanvasBackground(canvas_temp, background);
      } else {
        // Create new fabric canvas
        canvas_temp = new fabric.Canvas(ref, {
          width: canvasWidth,
          height: canvasHeight,
          borderColor: "#000", // Example border color
          borderWidth: 2, // Example border width
        });
        setCanvasBackground(canvas_temp, background);
        // Store canvas reference in the DOM element
        ref.__canvas = canvas_temp;
      }
      // Calculate the scaling factor based on the new canvas dimensions

      const scaleFactorWidth = canvasWidth / 400; // Assuming original canvas width is 400
      const scaleFactorHeight = canvasHeight / 400; // Assuming original canvas height is 400
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      // Adjust objects according to the canvas size
      //logoData.objects.forEach(async (objData) => {
      const renderNextObject = async (index) => {
        if (index >= logoData.objects.length) {
          resolve();
          return;
        }
        const objData = logoData.objects[index];
        if (objData.type === "i-text" && objData.metadata) {
          const customWidth = objData.customWidth || objData.width;
          if (objData.metadata.role === "company_name") {
            if (colordata != "0") {
              if (colordata.companyText) {
                objData.fill = colordata.companyText; // Direct property assignment
              }
            } else {
              objData.fill = objData.color; // Direct property assignment
            }
          } else if (objData.metadata.role === "slogan") {
            if (colordata != "0") {
              if (colordata.tagline) {
                objData.fill = colordata.tagline; // Direct property assignment
              }
            } else {
              objData.fill = objData.color; // Direct property assignment
            }
          }
          objData.customWidth = customWidth;
          //console.log('upper',objData.textAlign);
          //console.log('upper',objData.left);
        }

        if (objData.type === "image" && objData.metadata) {
          let newcolor_code = "";
          if (objData.metadata.role === "Shapes") {
            if (objData.MkIconId == isshapbcg) {
              let svgDataUrl = objData.src;
              if (!svgDataUrl) {
                svgDataUrl = objData.getSrc();
              }
              newcolor_code = background; // Ensure colordata and icon are properly defined

              if (newcolor_code) {
                await fetch(svgDataUrl)
                  .then((response) => response.text())
                  .then((svgData) => {
                    const fillColor = getFillColorFromSVG(svgData); // Ensure this function is defined
                    const modifiedSvgData = replaceColorInSVG(
                      svgData,
                      fillColor,
                      background
                    ); // Ensure this function is defined

                    objData.src =
                      "data:image/svg+xml;base64," + btoa(modifiedSvgData);
                  })
                  .catch((error) => {
                    console.error("Error fetching SVG data:", error);
                  });
              }
            }
          }
          if (objData.metadata.role === "icon") {
            //const svgDataUrl = objData.src;
            let svgDataUrl = objData.src;
            if (!svgDataUrl) {
              svgDataUrl = objData.getSrc();
            }
            newcolor_code = colordata.icon; // Ensure colordata and icon are properly defined

            if (newcolor_code) {
              await fetch(svgDataUrl)
                .then((response) => response.text())
                .then((svgData) => {
                  const fillColor = getFillColorFromSVG(svgData); // Ensure this function is defined
                  const modifiedSvgData = replaceColorInSVG(
                    svgData,
                    fillColor,
                    colordata.icon
                  ); // Ensure this function is defined

                  objData.src =
                    "data:image/svg+xml;base64," + btoa(modifiedSvgData);
                })
                .catch((error) => {
                  console.error("Error fetching SVG data:", error);
                });
            }
          }
        }

        const scaledObject = {
          ...objData,
          scaleX: (objData.scaleX || 1) * scaleFactor,
          scaleY: (objData.scaleY || 1) * scaleFactor,
          left: (objData.left || 0) * scaleFactor,
          top: (objData.top || 0) * scaleFactor,
        };

        fabric.util.enlivenObjects([scaledObject], (objects) => {
          objects.forEach((obj) => {
            obj.selectable = false;
            if (obj.type === "i-text") {
              const customWidth = obj.customWidth || obj.width;
              obj.set({
                textAlign: obj.textAlign,
              });

              obj.set({
                width: customWidth,
              });
            }

            canvas_temp.add(obj);
          });
          canvas_temp.requestRenderAll();
          renderNextObject(index + 1);
        });
        //});
      };
      //console.log(canvas_temp);
      //return canvas_temp;
      whenRenderFunction(true);
      renderNextObject(0);
      resolve(); // Resolve the promise when rendering is complete
    });
  };

  const getFillColorFromSVG = (svgData) => {
    // Create a DOM parser to parse the SVG string
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgData, "image/svg+xml");

    // Get the <svg> element
    const svgElement = doc.querySelector("svg");

    // Return the fill color from the <svg> element
    return svgElement ? svgElement.getAttribute("fill") : null;
  };

  const replaceTemplate = () => {
    if (!selectedLogoData || !mainCanvas) return;

    const originalWidth = 400;
    const originalHeight = 400;
    const scaleFactor =
      mainCanvas.width / Math.max(originalWidth, originalHeight);

    mainCanvas.clear();

    selectedLogoData.objects.forEach((objData) => {
      // Apply the scale factor to each object
      objData.scaleX = (objData.scaleX || 1) * scaleFactor;
      objData.scaleY = (objData.scaleY || 1) * scaleFactor;
      objData.left = (objData.left || 0) * scaleFactor;
      objData.top = (objData.top || 0) * scaleFactor;

      fabric.util.enlivenObjects([objData], (objects) => {
        objects.forEach((obj) => {
          mainCanvas.add(obj);
        });
        mainCanvas.requestRenderAll();
      });
    });

    setCanvasBackground(mainCanvas, selectedLogoData.background);
    mainCanvas.renderAll();
  };
  const handleConfirmReplace = () => {
    setConfirmModalVisible(false);
    replaceTemplate();
  };
  const handleImageClick2 = (logoData) => {
    setSelectedLogoData(logoData);
    setConfirmModalVisible(true);
  };

  const getValueByColorName = (colorName) => {
    for (let item of colorItems) {
      if (item[colorName] !== undefined) {
        return item[colorName];
      }
    }
    return null; // Return null if the color name is not found
  };

  const ShowDiffrentColorLogo = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    const isAtLeastOneColorFilled = color.some((item) => item !== "");

    const mandatory_input = (inputmand) => {
      $("." + inputmand).css({
        border: "1px solid red",
        width: "50px !important",
      });
      $("." + inputmand)
        .nextAll(".condition_error:first")
        .html("Please Fill The Mandatory Information")
        .show();
      vaild_data = false;
    };

    if (vaild_data) {
      const color1 = getValueByColorName(color[0]); //color of logo theme
      const color2 = getValueByColorName(color[1]); //color of logo theme
      const color3 = getValueByColorName(color[2]); //color of logo theme

      ///selectedLogo[0] = 4;
      const canvasData = CurrentCanvas();
      setCanvasColorVariant(canvasData);
      setlogos(colorCombinations);
      setShowNext(true);
      const mol = 0;
      setTimeout(() => {
        $(".choicecanvasdiff").hide();
        $(".choicecanvas_" + color1 + "_rm").show();
        $(".choicecanvas_" + color2 + "_rm").show();
        $(".choicecanvas_" + color3 + "_rm").show();
        $(".removeextradiv" + color1).remove();
        $(".removeextradiv" + color2).remove();
        $(".removeextradiv" + color3).remove();
        $(".choicecanvas" + color1 + "_rm").addClass("choicecanvas" + color1);
        $(".selectedLgotest" + color1 + "_rm").addClass(
          "selectedLgotest" + color1
        );
        $(".selectedLgo0_" + color1 + "_rm").addClass(
          "selectedLgo0_" + color1 + " selectedLgo"
        );

        $(".choicecanvas" + color2 + "_rm").addClass("choicecanvas" + color2);
        $(".selectedLgotest" + color2 + "_rm").addClass(
          "selectedLgotest" + color2
        );
        $(".selectedLgo0_" + color2 + "_rm").addClass(
          "selectedLgo0_" + color2 + " selectedLgo"
        );

        $(".choicecanvas" + color3 + "_rm").addClass("choicecanvas" + color3);
        $(".selectedLgotest" + color3 + "_rm").addClass(
          "selectedLgotest" + color3
        );
        $(".selectedLgo0_" + color3 + "_rm").addClass(
          "selectedLgo0_" + color3 + " selectedLgo"
        );

        $(".colordivcls").hide();
        $(".colordiv" + mol).show();
        $(".tickmardis").removeClass("tickMark");
        $(".tickMark" + mol).addClass("tickMark"); //may be mol class will change
        selectedColorIndex = 0;
      }, 100);
    }
  };

  // font upload modal
  const handleFontuploadModal = () => {
    setshowFontModal(true);
  };
  const [cropConfirmed, setCropConfirmed] = React.useState(false);

  //crop object
  const handleCropButtonClick = () => {
    const img = mainCanvas.getActiveObject();
    if (img) {
      prepareCrop(img);
      setIsTextToolVisible(false);
      setIsObjectSelected(false);
      setshopcolor(true);
      setIsCropMode(true);
    }
  };
  const handleSaveCrop = () => {
    const img = mainCanvas.getActiveObject();
    const cropRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "crop-rect");
    //console.log(cropRect);
    //console.log(objectBeingCropped);
    const overlayRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "overlay-rect");
    if (overlayRect) {
      mainCanvas.remove(overlayRect);
    }
    if (objectBeingCropped && cropRect) {
      cropImage(cropRect, objectBeingCropped);
      setIsCropMode(false);
    }
  };

  const handleCancelCrop = () => {
    const overlayRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "overlay-rect");
    const cropRect = mainCanvas
      .getObjects()
      .find((obj) => obj.id === "crop-rect");
    if (overlayRect) {
      mainCanvas.remove(overlayRect);
    }
    if (cropRect) {
      mainCanvas.remove(cropRect);
    }
    setCropConfirmed(true);
    mainCanvas.renderAll();
    //whenRenderFunction(true);
    setIsCropMode(false);
  };

  /**shubham jain new code new editor */
  const prepareCrop = (e) => {
    objectBeingCropped = e;
    const cropRect = new fabric.Rect({
      id: "crop-rect",
      top: e.top,
      left: e.left,
      angle: e.angle,
      width: e.getScaledWidth(),
      height: e.getScaledHeight(),
      stroke: "rgb(42, 67, 101)",
      strokeWidth: 2,
      strokeDashArray: [5, 5],
      fill: "rgba(255, 255, 255, 1)",
      globalCompositeOperation: "overlay",
      lockRotation: true,
    });

    const overlayRect = new fabric.Rect({
      id: "overlay-rect",
      top: e.top,
      left: e.left,
      angle: e.angle,
      width: e.getScaledWidth(),
      height: e.getScaledHeight(),
      selectable: false,
      selection: false,
      fill: "rgba(0, 0, 0, 0.5)",
      lockRotation: true,
    });

    const s = e.cropX || 0,
      o = e.cropY || 0,
      c = e.width,
      l = e.height;

    e.set({
      cropX: null,
      cropY: null,
      left: e.left - s * e.scaleX,
      top: e.top - o * e.scaleY,
      width: e._originalElement.naturalWidth,
      height: e._originalElement.naturalHeight,
      dirty: false,
    });

    cropRect.set({
      left: e.left + s * e.scaleX,
      top: e.top + o * e.scaleY,
      width: c * e.scaleX,
      height: l * e.scaleY,
      dirty: false,
    });

    overlayRect.set({
      left: e.left,
      top: e.top,
      width: e.width * e.scaleX,
      height: e.height * e.scaleY,
      dirty: false,
    });

    cropRect.oldScaleX = cropRect.scaleX;
    cropRect.oldScaleY = cropRect.scaleY;

    mainCanvas.add(overlayRect);
    mainCanvas.add(cropRect);
    mainCanvas.discardActiveObject();
    mainCanvas.setActiveObject(cropRect);
    mainCanvas.renderAll();
    cropRect.on("moving", function () {
      if (cropRect.top < e.top) cropRect.top = e.top;
      if (cropRect.left < e.left) cropRect.left = e.left;
      if (
        cropRect.top + cropRect.getScaledHeight() >
        e.top + e.getScaledHeight()
      ) {
        cropRect.top = e.top + e.getScaledHeight() - cropRect.getScaledHeight();
      }
      if (
        cropRect.left + cropRect.getScaledWidth() >
        e.left + e.getScaledWidth()
      ) {
        cropRect.left = e.left + e.getScaledWidth() - cropRect.getScaledWidth();
      }
    });
    cropRect.on("deselected", function () {
      if (cropConfirmed) {
        // console.log('yes');
        //console.log(e);
        //console.log(cropRect);
        //cropImage(cropRect, e);
        mainCanvas.remove(overlayRect);
      }
    });
  };
  const cropImage = (cropRect, img) => {
    mainCanvas.remove(cropRect);
    const overlayRect = mainCanvas;
    if (overlayRect) {
      mainCanvas.remove(overlayRect);
    }
    const cropX = (cropRect.left - img.left) / img.scaleX;
    const cropY = (cropRect.top - img.top) / img.scaleY;
    const cropWidth = (cropRect.width * cropRect.scaleX) / img.scaleX;
    const cropHeight = (cropRect.height * cropRect.scaleY) / img.scaleY;

    img.set({
      cropX: cropX,
      cropY: cropY,
      width: cropWidth,
      height: cropHeight,
      top: img.top + cropY * img.scaleY,
      left: img.left + cropX * img.scaleX,
      selectable: true,
      cropped: 1,
    });

    mainCanvas.renderAll();
    //whenRenderFunction(true);
  };

  /**admin extra function shubham jain new code new editor */
  const [isBlackIcon, setIsBlackIcon] = useState(false);
  const [isBlackIcon2, setIsBlackIcon2] = useState(false);
  const [Selectedindustry, setSelectedindustry] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [showCheckboxList, setShowCheckboxList] = useState(false);
  const [showCheckboxList2, setShowCheckboxList2] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [showIndModal, setShowIndModal] = useState(false);
  const AlliconeColorChange = (index) => {
    setIsBlackIcon(true);
    setIsBlackIcon2(false);
  };
  const EditiconeColorChange = (index) => {
    setIsBlackIcon2(true);
    setIsBlackIcon(false);
  };
  const handleChangeindustrydata = (Selectedindustry) => {
    setSelectedindustry(Selectedindustry);
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleCheckList2 = () => {
    setShowCheckboxList2(!showCheckboxList2);
    if (showCheckboxList) {
      setShowCheckboxList(false);
    }
  };

  const handleCheckboxChangeLabel = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedColors([...selectedColors, value]);
    } else {
      setSelectedColors(selectedColors.filter((color) => color !== value));
    }
  };

  const template_type = [
    { value: "logo", label: "Logo" },
    { value: "letterhead", label: "Letterhead" },
    { value: "envelops", label: "Envelop" },
    { value: "businesscard", label: "Business Card" },
    { value: "invitationcard", label: "Invitation Card" },
    // Add more industries as needed
  ];
  const handleSelectLabelAll2 = (e) => {
    if (e.target.checked) {
      const allValues = template_type.map((option) => option.value);
      setSelectedType(allValues);
    } else {
      setSelectedType([]);
    }
  };
  const handleCheckboxChangeLabel2 = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedType([...selectedType, value]);
    } else {
      setSelectedType(selectedType.filter((type) => type !== value));
    }
  };
  const customStyles2 = {
    control: (base) => ({
      ...base,
      fontWeight: 500,
      fontSize: 13,
      color: "black",
      overflow: "hidden",
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: 13,
      fontWeight: 500,
      color: "black",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#a7a7a7",
      fontSize: 13,
    }),
    option: (base) => ({
      ...base,
      color: "#a7a7a7",
      fontSize: 13,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 !important",
    }),
  };
  // Function to convert a string to Pascal Case

  const toPascalCase = (str) => {
    if (typeof str !== "string") {
      console.error("Invalid input: Expected a string");
      return ""; // Return an empty string or a default value
    }

    return str
      .replace(/[_-]/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  //font txt section
  const customStyles3 = {
    control: (base, state) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 15,
      textAlign: "left",
      paddingLeft: 8,
      paddingTop: 6,
      paddingBottom: 6,
      boxShadow: "none",
      borderRadius: 4,
    }),
    singleValue: (base) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 15,
      color: "black",
      textAlign: "left",
      backgroundColor: "transparent", // Set the background color to transparent
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      fontSize: 15,
      color: "#a7a7a7",
      textAlign: "left",
    }),
    option: (base, state) => ({
      ...base,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 300,
      fontSize: 15,
      color: "#a7a7a7",
      textAlign: "left",
      borderRadius: 7,
      cursor: "pointer",
      backgroundColor: state.isSelected ? "transparent" : base.backgroundColor,
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),
  };
  const handleEditClick = () => {
    setShowIndModal(true);
  };

  /**admin extra function shubham jain new code new editor */

  const colorMapping = {
    "#888": "Black",
    "#00f": "Blue",
    "#0ff": "Teal",
    "#89d587": "Green",
    "#764bc4": "Purple",
    "#f0f": "Pink",
    "#f06060": "Red",
    "#ee8133": "Orange",
    "#f8e8a2": "Yellow",
  };
  const colorItems = [
    { color: "#f06060", className: "color_item7", Red: 0, 0: "Red" },
    { color: "#89d587", className: "color_item4", Green: 1, 1: "Green" },
    { color: "#888", className: "color_item1", Black: 2, 2: "Black" },
    { color: "#ee8133", className: "color_item8", Orange: 3, 3: "Orange" },
    { color: "#0ff", className: "color_item3", Teal: 4, 4: "Teal" },
    { color: "#f8e8a2", className: "color_item9", Yellow: 5, 5: "Yellow" },
    { color: "#f0f", className: "color_item6", Pink: 6, 6: "Pink" },
    { color: "#764bc4", className: "color_item5", Purple: 7, 7: "Purple" },
    { color: "#00f", className: "color_item2", Blue: 8, 8: "Blue" },
  ];

  const nameToHexMapping = Object.fromEntries(
    Object.entries(colorMapping).map(([hex, name]) => [name, hex])
  );

  const hexToRgb = (hex) => {
    let r = 0,
      g = 0,
      b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return [r, g, b];
  };

  const colorDistance = (rgb1, rgb2) => {
    return Math.sqrt(
      Math.pow(rgb1[0] - rgb2[0], 2) +
        Math.pow(rgb1[1] - rgb2[1], 2) +
        Math.pow(rgb1[2] - rgb2[2], 2)
    );
  };

  const getPrioritizedColorNames = (selectedColorNames, colorMapping) => {
    // Filter out empty values and map color names to hex codes
    const selectedHexes = selectedColorNames
      .map((name) => name.trim()) // Trim whitespace from each name
      .filter((name) => name) // Filter out empty or falsy values
      .map((name) => nameToHexMapping[name]);

    if (selectedHexes.includes(undefined)) {
      throw new Error(
        "Some selected color names are not in the color mapping."
      );
    }

    const avgRgb = selectedHexes.reduce(
      (acc, hex) => {
        const rgb = hexToRgb(hex);
        return [
          acc[0] + rgb[0] / selectedHexes.length,
          acc[1] + rgb[1] / selectedHexes.length,
          acc[2] + rgb[2] / selectedHexes.length,
        ];
      },
      [0, 0, 0]
    );

    const prioritizedColors = Object.entries(colorMapping)
      .filter(([color]) => !selectedHexes.includes(color)) // Exclude selected colors
      .sort(([color1], [color2]) => {
        const distance1 = colorDistance(hexToRgb(color1), avgRgb);
        const distance2 = colorDistance(hexToRgb(color2), avgRgb);
        return distance1 - distance2;
      })
      .map(([color, name]) => name) // Get only the names
      .filter((name) => name); // Filter out any empty values

    return prioritizedColors.join(",");
  };

  const [rotatePlus1, setRotatePlus1] = useState(false);
  const [rotatePlus2, setRotatePlus2] = useState(false);
  //drop down menus
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);

  const rotateDropdown1 = (event) => {
    event.preventDefault();
    setShowDropdown1(!showDropdown1);
    setRotatePlus1(!rotatePlus1);
    setRotatePlus2(false);
  };

  const handleColorItemClick = (hexColor) => {
    const colorName = colorMapping[hexColor];
    const newColors = [...color];
    const index = newColors.indexOf(colorName);
    if (index !== -1) {
      // If already selected, deselect it
      newColors[index] = ""; // Set the deselected color to an empty string
    } else {
      // If not selected, check for empty slots and add
      const emptyIndex = newColors.indexOf(""); // Check for an empty slot
      if (emptyIndex !== -1) {
        newColors[emptyIndex] = colorName; // Add to an empty slot
      } else if (newColors.length < 3) {
        newColors.push(colorName); // Add to the end if less than 3 colors
      }
    }

    // Shift colors to the left to fill empty slots
    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }
    // Repeat shifting to ensure all empty slots are filled correctly
    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }
    const fillcolor = newColors.filter((c) => c !== "").length;
    // Hide dropdown and set state
    if (fillcolor == 3) {
      setShowDropdown1(false);
      setRotatePlus1(false);
    }
    setColor(newColors);
    localStorage.setItem("card_colors", JSON.stringify(newColors));
  };

  const handleDeleteColor = (event, index) => {
    event.preventDefault();
    const newColors = [...color];
    newColors[index] = ""; // Set the color to an empty string

    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }
    for (let i = 0; i < newColors.length - 1; i++) {
      if (newColors[i] === "") {
        newColors[i] = newColors[i + 1];
        newColors[i + 1] = "";
      }
    }

    setColor(newColors);
    localStorage.setItem("card_colors", JSON.stringify(newColors));
    return false;
  };

  // const handleDeleteColor = (event, index) => {
  //   event.preventDefault();
  //   const newColors = [...color];
  //   newColors[index] = ""; // Set the color to an empty string
  //   setColor(newColors);
  //   localStorage.setItem("card_colors", JSON.stringify(newColors));
  //   return false;
  // };

  const handleAddColors = () => {
    if (color.filter((c) => c !== "").length === 3) {
      setSetColors([...setColors, color.filter((c) => c !== "")]);
      setColor(initialColor); // Reset the selected colors

      const canvasData = CurrentCanvas();

      setCanvasVariant((prevVariants) => [...prevVariants, canvasData]);
    } else {
      alert("You must select exactly 3 colors.");
    }
  };

  const CurrentCanvas = () => {
    const normalizedFilteredFonts = filteredFonts.map((font) => ({
      family: font.family,
      src: font.files?.regular,
    }));
    const normalizedCustomFonts = customFonts.map((font) => ({
      family: font.family,
      src: font.src,
    }));

    // Merge normalized fonts into mergedFonts
    const mergedFonts = [...normalizedFilteredFonts, ...normalizedCustomFonts];
    const data = mainCanvas.getObjects().map((obj) => {
      let objData = obj.toObject();
      objData.flag = 0; // Append flag 0
      objData.color = obj.fill || "";

      // Add mergedFonts to each object and include font source
      if (obj.type === "i-text") {
        const fontFamily = obj.fontFamily || "Arial";
        const fontSrc = mergedFonts.find(
          (font) => font.family === fontFamily
        )?.src;
        objData.fontSize = obj.fontSize || 20; // Default font size 20px
        objData.fontFamily = fontFamily;
        objData.fontSrc = fontSrc;

        // Dynamically generate and apply @font-face style
        if (fontSrc) {
          const fontStyles = `@font-face {
            font-family: '${fontFamily}';
            font-style: normal;
            font-weight: 400;
            src: url(${fontSrc});
          }`;

          // Create and append style element to head
          const styleElement = document.createElement("style");
          styleElement.textContent = fontStyles;
          document.head.appendChild(styleElement);
        }

        //console.log("Saving Font Family:", fontFamily);
        //console.log("Saving Font Source:", fontSrc);
      }

      return objData;
    });

    const canvasData = {
      objects: data,
      background: mainCanvas.backgroundColor,
      selectedColors: selectedColors,
    };

    return canvasData;
  };

  const handleDeleteVariant = (index) => {
    const newSetColors = setColors.filter((_, i) => i !== index);
    setSetColors(newSetColors);
    const newCanvasVariants = CanvasVariant.filter((_, i) => i !== index);
    setCanvasVariant(newCanvasVariants);
  };

  const [showAll, setShowAll] = useState(false);

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displaySelectedItems = (selectedItems) => {
    if (selectedItems.length <= 2) {
      return selectedItems;
    }
    if (showAll) {
      return selectedItems;
    }
    return selectedItems.slice(0, 2).concat({
      label: `+${selectedItems.length - 2} more`,
      value: "more",
    });
  };
  // Handle item removal
  const handleRemoveItem = (value) => {
    const updatedItems = Selectedindustry.filter(
      (item) => item.value !== value
    );
    setSelectedindustry(updatedItems);
    handleChangeindustrydata(updatedItems); // Trigger the parent handler
  };
  // Custom ValueContainer component for handling display logic

  const CustomValueContainer = (props) => {
    const { getValue } = props;
    const selectedItems = getValue();
    const displayedItems = displaySelectedItems(selectedItems);

    return (
      <components.ValueContainer {...props}>
        {displayedItems.slice(0, showAll ? undefined : 2).map((item) =>
          item.value === "more" ? (
            <div
              key={item.value}
              className="more-items"
              onClick={handleToggleShowAll}
            >
              {item.label}
            </div>
          ) : (
            <div key={item.value} className="selected-item">
              <img
                src={crossIcn}
                alt="Remove"
                className="cross-icon"
                onClick={() => handleRemoveItem(item.value)}
              />
              {item.label}
            </div>
          )
        )}
        {displayedItems.length > 2 && (
          <div
            className="toggle-more"
            onClick={handleToggleShowAll}
            style={{ display: "flex", fontSize: "13px", alignItems: "center" }}
          >
            {showAll ? "ShowLess" : "More"}
          </div>
        )}
      </components.ValueContainer>
    );
  };

  // Custom MenuList component inside the CustomSelect

  //font upload validation
  const handleUploadValidation = () => {
    if (!category || !file) {
      setValidationMessage("Please fill out all fields and choose a file");
      return;
    }
    handleFontUpload();
  };

  const categoryOptions = [
    { value: "All", label: "All" },
    ...fontCategories.map((cat) => ({
      value: cat,
      label: toPascalCase(cat),
    })),
    { value: "Custom", label: "Custom" },
  ];
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFontCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleColorClick = (index) => {
    ///setSelectedColorIndex(index);
    $(".tickmardis").removeClass("tickMark");
    $(".tickMark" + index).addClass("tickMark");
    $(".colordivcls").hide();
    $(".colordiv" + index).show();
    selectedColorIndex = index;
    $(".prevcls").removeClass("disableee");
    $(".nextcls").removeClass("disableee");
    $(".nextcls").show();
    $(".saveshow").addClass("savehidn");
    if (index == 0) {
      $(".prevcls").addClass("disableee");
    }
    if (index == 8) {
      $(".nextcls").addClass("disableee");
      $(".nextcls").hide();
      $(".saveshow").removeClass("savehidn");
    }
  };

  const handlePrevClick = () => {
    if (selectedColorIndex > 0) {
      const mol = selectedColorIndex - 1;
      selectedColorIndex = mol;
      $(".tickmardis").removeClass("tickMark");
      $(".tickMark" + mol).addClass("tickMark");
      $(".colordivcls").hide();
      $(".colordiv" + mol).show();
      $(".nextcls").show();
      if (mol == 0) {
        $(".prevcls").addClass("disableee");
      } else {
        $(".nextcls").removeClass("disableee");
        $(".saveshow").addClass("savehidn");
      }
    }
  };

  const handleNextClick = () => {
    if (selectedColorIndex < colorItems.length - 1) {
      const mol = selectedColorIndex + 1;
      selectedColorIndex = mol;
      $(".tickmardis").removeClass("tickMark");
      $(".tickMark" + mol).addClass("tickMark");
      $(".colordivcls").hide();
      $(".colordiv" + mol).show();
      $(".nextcls").show();
      if (mol == 8) {
        $(".nextcls").hide();
        $(".nextcls").addClass("disableee");
        $(".saveshow").removeClass("savehidn");
      } else {
        $(".prevcls").removeClass("disableee");
      }
    }
  };

  const handleLogoClick = (index, colorIndex) => {
    $(".selectedLgotest" + colorIndex).removeClass("selectedLgo");
    $(".selectedLgo" + index + "_" + colorIndex).addClass("selectedLgo");
  };
  //letter div

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const handleDeleteText = () => {
    if (currentTextObject) {
      mainCanvas.remove(currentTextObject);
      setCurrentTextObject(null);

      mainCanvas.renderAll();
      whenRenderFunction(true);
    }
  };
  const handleLetterClick = (letter) => {
    console.log(selectedLetter);
    let ol = 1;
    if (selectedLetter === "Letter_first" && lettertext1 === false) {
      setslettertext1(true);
      ol = 0;
    }
    if (selectedLetter === "Letter_Second" && lettertext2 === false) {
      setslettertext2(true);
      ol = 0;
    }
    if (selectedLetter === "Letter_Third" && lettertext3 === false) {
      setslettertext3(true);
      ol = 0;
    }
    // Create a new text input if not replacing
    if (ol === 0) {
      //createTextInput("Add Text", fontFamily);
      createTextInput(
        selectedLetter,
        letter,
        selectedFontCanvasFamily,
        mainCanvas.width / 2,
        mainCanvas.height / 2,
        false,
        40
      );
    } else {
      if (selectedLetter === "Letter_first") {
        alert("Please delete your Letter First before adding a new one.");
      }
      if (selectedLetter === "Letter_Second") {
        alert("Please delete your Letter Second before adding a new one.");
      }
      if (selectedLetter === "Letter_Third") {
        alert("Please delete your Letter Third before adding a new one.");
      }
    }

    // if (currentTextObject) {
    //   alert("Please delete your previous text before adding a new one.");
    // } else {

    // }
  };
  const handleTabClick = (letter) => {
    //console.log(letter);
    ///setSelectedTab(letter);
    setSelectedLetter(letter);
  };

  const select_dropdown = (item) => {
    setSelectedItem(item); // Update selected item
    setIsActive(false); // Close dropdown
  };

  const shiftdrodown = (item, first = 0) => {
    //change template

    //console.log(mainCanvas);
    console.log(item);
    console.log(first);
    if (first != 1) {
      if (mainCanvas != null) {
        mainCanvas.clear();
        console.log(item);
        const logoObject = images1.find((itemr) => itemr.description === item);
        console.log(logoObject);
        // Resize the canvas to 600x200
        mainCanvas.setWidth(logoObject.width);
        mainCanvas.setHeight(logoObject.height);
        mainCanvas.renderAll();
      }
    }

    if (item !== "Logo") {
      storeData("templatetype", item);
      // Hide the elements by setting their display style to 'none'
      //document.querySelector(".changeontemplatetype25").style.display = "none";

      document.querySelector(".changeontemplatetype2").style.display = "none";
      document.querySelector(".changeontemplatetype3").style.display = "block";
      const elements = document.querySelectorAll(".changeontemplatetype");

      // Loop through each element and hide it
      elements.forEach((element) => {
        element.style.display = "none";
      });
    } else {
      //document.querySelector(".changeontemplatetype25").style.display = "flex";

      document.querySelector(".changeontemplatetype2").style.display = "flex";
      document.querySelector(".changeontemplatetype3").style.display = "none";
      const elements = document.querySelectorAll(".changeontemplatetype");

      // Loop through each element and hide it
      elements.forEach((element) => {
        element.style.display = "block";
      });
      storeData("templatetype", "Logo");
    }

    setSelectedItem2(item); // Update selected item
    setIsActiveTwo(false); // Close dropdown
  };
  const [isModalVisible, setModalVisible] = useState(false);
  const select_dropdown2 = (item) => {
    let canvaschng = 0;
    if (mainCanvas) {
      mainCanvas._objects.forEach((object, index) => {
        if (object.type === "image") {
          canvaschng++;
        }
        // Check if the object is of type 'text', 'i-text', or 'textbox'
        else if (
          object.type === "text" ||
          object.type === "i-text" ||
          object.type === "textbox"
        ) {
          canvaschng++;
        } else {
          // console.log(`Object ${index} is of type: ${object.type}.`); // Logs other types if present
        }
      });

      if (canvaschng > 0) {
        setModalVisible(true);
      } else {
        shiftdrodown(item, firstcanvas);
        firstcanvas++;
      }
    } else {
      shiftdrodown(item, 1);
    }
    setSelectedDescription(item);
  };

  const handleConfirm = () => {
    setModalVisible(false);
    shiftdrodown(selectedItem);
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  ///only text aligment
  const toggleAlignment = () => {
    // Get the active text object
    const activeObject = mainCanvas.getActiveObject();

    if (activeObject && activeObject.type === "i-text") {
      // Save the current width before changing alignment
      const customWidth = activeObject.customWidth || activeObject.width;

      // Toggle alignment position
      const newAlignment =
        alignmentPosition === "left"
          ? "center"
          : alignmentPosition === "center"
          ? "right"
          : "left";

      // Update the alignment property
      activeObject.set({ textAlign: newAlignment });

      // Restore the custom width after changing alignment
      activeObject.set({
        width: customWidth,
        customWidth, // Ensure customWidth is preserved
      });

      // Update canvas
      mainCanvas.requestRenderAll();

      // Log the alignment change
      console.log(`Text alignment changed to: ${newAlignment}`);

      // Update state for alignment position
      setAlignmentPosition(newAlignment);
    } else {
      console.log("No text object is currently selected.");
    }
  };

  const handleIconClick = (clickedIcon, i) => {
    setActiveIndex(clickedIcon.actualIndex); // Update the active index on icon click
    setActiveIndexImg(clickedIcon.src); // Update the active index image source on icon click
    const activeObject = mainCanvas.getObjects();
    console.log(activeObject[clickedIcon.actualIndex]);
    console.log(activeObject[clickedIcon.actualIndex].MkIconId);
    ChangeIconSelect = activeObject[clickedIcon.actualIndex].MkIconId;
  };

  const UserChangingIconSelection = () => {
    const activeObject = mainCanvas.getObjects();
    let srcimg = "";

    let HaveIcon = 0;
    Haveselected = 0;
    iconSelectionArray = [];

    // Iterate through objects to find icons and check if one is selected
    activeObject.forEach((obj, index) => {
      if (obj.metadata && obj.metadata.role === "icon") {
        HaveIcon = 1;

        srcimg = obj.src || obj._element?.currentSrc || "";

        // Check if the current icon is selected
        if (obj.MkIconId === ChangeIconSelect) {
          Haveselected = 1;
          setActiveIndex(index);
          setActiveIndexImg(srcimg);
        }

        // Store icon data in the array
        iconSelectionArray.push({
          actualIndex: index, // Use the current loop index
          src: srcimg,
        });
      }
    });
    setTimeout(() => {
      // If there's an icon but none is selected, select the first one
      if (HaveIcon == 1 && Haveselected == 0 && iconSelectionArray.length > 0) {
        const FirstIconIndex = iconSelectionArray[0].actualIndex;
        const GetIconObjectData = activeObject[FirstIconIndex];

        ChangeIconSelect = GetIconObjectData.MkIconId || ChangeIconSelect;
        setActiveIndex(FirstIconIndex);
        setActiveIndexImg(iconSelectionArray[0].src);
      }
    }, 100);
  };
  const optionsMenuRef = useRef(null);
  const optionsButtonRef = useRef(null);
  const handleOptionClick = (id, type) => {
    if (optionsVisible === id && currentOptionsType === type) {
      // If the same item is clicked and options are already visible, hide the options
      setOptionsVisible(null);
      setCurrentOptionsType(null);
    } else {
      // Otherwise, show the options for the clicked item
      setOptionsVisible(id);
      setCurrentOptionsType(type);
    }
  };

  // Handle clicks outside the options menu or button to hide it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionsMenuRef.current &&
        !optionsMenuRef.current.contains(event.target) &&
        optionsButtonRef.current &&
        !optionsButtonRef.current.contains(event.target)
      ) {
        setOptionsVisible(null);
        setCurrentOptionsType(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filtering based on the selected tab
  const filteredShapeData = IconShape.filter((icon) => {
    if (currentContent === "shape") return icon.shape_type === "Shapes";
    if (currentContent === "line") return icon.shape_type === "Lines";
    if (currentContent === "frame") return icon.shape_type === "Frames";
    return false;
  });
  const decodeBase64ToSvg = (base64) => {
    try {
      // Ensure the base64 string is prefixed with 'data:image/svg+xml;base64,'
      return `data:image/svg+xml;base64,${base64}`;
    } catch (error) {
      console.error("Error decoding base64 to SVG:", error);
      return "";
    }
  };

  const specificDivRef = useRef(null); // Ref for the specific div
  const textDivRef = useRef(null);

  

  const handleScroll = (forwhat) => {
    console.log('this is calling');
    if(forwhat=='element'){
      if (specificDivRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = specificDivRef.current;
        if (scrollTop + clientHeight >= scrollHeight) {
          get_more_elements();
        }
      }
    }
    if(forwhat=='text'){
      //.log('calling inside text');
      if (textDivRef.current) {
        //console.log(textDivRef);
        const { scrollTop, scrollHeight, clientHeight } = textDivRef.current;
        console.log(scrollTop);
        console.log(scrollHeight);
        console.log(clientHeight);
        if (scrollTop + clientHeight + 10 >= scrollHeight) {
          loadMoreFonts();
          console.log('calling');
        }
      }
    }

  };
  const handleClickShape = (index) => {
    const shapeData = filteredShapeData[index];
    const timestamp = new Date().getTime().toString(36); // Convert the current time to a base-36 string
    const randomNumber = Math.floor(Math.random() * 1000000000).toString(36);

    fabric.Image.fromURL(shapeData.shape_url, (img) => {
      img.set({
        left: mainCanvas.width / 2 - img.width / 2,
        top: mainCanvas.height / 2 - img.height / 2,
        selectable: true,
        MkIconId: `MkIconId_${timestamp}_${randomNumber}`,
        metadata: {
          role: "Shapes",
          timestamp: new Date().toISOString(),
        },
      });
      img.MkIconId = new Date().toISOString();
      img.metadata = {
        role: "Shapes",
        timestamp: new Date().toISOString(),
      };

      // Add the image to the canvas
      mainCanvas.add(img).setActiveObject(img);
      //mainCanvas.add(img);
      mainCanvas.requestRenderAll();
    });

    ///console.log(`Shape ${index} added to canvas.`);
  };
  // const HandleIndustrYmoDAL = (e) => {
  //   e.preventDefault();
  //   console.log("clicked");
  //   setIndustryModal(true);
  // };
  const handleAddIndustry = (e) => {
    e.preventDefault();
    setIndustryModal(false);
    setAddIndustryModal(true);
  };
  // const handleIndustryCloseModal = () => {
  //   setIndustryModal(false);
  // };
  //mutli Selector Function
  const handleKeyIndustryDown = (event) => {
    try {
      if (!inputValue) return;
      if (event.key === "Enter" && inputValue) {
        event.preventDefault();
        const newOption = { value: inputValue, label: inputValue };
        setSelectedOptions((prevOptions) => [
          ...(Array.isArray(prevOptions) ? prevOptions : []),
          newOption,
        ]);
        setInputValue("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [newIndustries, setNewIndustries] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  // const [selectedIndustries, setSelectedIndustries] = useState([
  //   { industry: null, categories: [] },
  // ]);
  const iconSrc = newIndustries.length > 0 ? MinusIcon : PlusIconAdd;
  const handleAddNewIndustry = () => {
    setDropdowns((prev) => [
      ...prev,
      { selectedIndustry: null, selectedCategories: [] },
    ]);
    setNewIndustries([...newIndustries, {}]);
    setIsAdding(true);
  };

  const isLastIndex = (index) => {
    return index === dropdowns.length - 1;
  };

  //update

  // const handleAddNewIndustry = () => {
  //   if (isAdding) {
  //     // Remove the last industry form and dropdown item
  //     setNewIndustries((prevIndustries) => prevIndustries.slice(0, -1));
  //     setDropdowns((prev) => prev.slice(0, -1));
  //   } else {
  //     // Add a new industry form and dropdown item
  //     setNewIndustries((prevIndustries) => [...prevIndustries, {}]);
  //     setDropdowns((prev) => [
  //       ...prev,
  //       { selectedIndustry: null, selectedCategories: [] },
  //     ]);
  //   }
  //   setIsAdding(!isAdding);
  // };

  const handleChangeIndustry = (selected, index) => {
    const updatedIndustries = [...selectedIndustries];
    updatedIndustries[index] = {
      ...updatedIndustries[index],
      industry: selected,
    };
    setSelectedIndustries(updatedIndustries);
  };

  // const handleInputChange = (newValue) => {
  //   setInputValue(newValue);
  // };

  // const handleSaveChangesdynamic = async (form_data, url_for_save) => {
  //   let vaild_data = check_vaild_save(form_data);
  //   if (vaild_data) {
  //     let fd_from = combiled_form_data(form_data, null);
  //     // fd_from.append("main_id", editorDataMainID);
  //     // Extract only the 'value' properties from selectedOptions
  //     const valuesOnly = selectedOptions
  //       .map((option) => option.value)
  //       .join(",");
  //     if (valuesOnly) {
  //       setAddIndustryModal();
  //       setshowLoaderAdmin(true);
  //       // Append the comma-separated string to fd_from
  //       fd_from.append("related_industry_name_data", valuesOnly);
  //       await server_post_data(url_for_save, fd_from)
  //         .then((Response) => {
  //           setshowLoaderAdmin(false);
  //           if (Response.data.error) {
  //             handleError(Response.data.message);
  //           } else {
  //             handleSuccess(Response.data.message);
  //           }
  //         })
  //         .catch((error) => {
  //           handleError("network");
  //           setshowLoaderAdmin(false);
  //         });
  //     } else {
  //       handleError("Please Add Industry keyword");
  //     }
  //   }
  // };

  const [dropdowns, setDropdowns] = useState([
    { selectedIndustry: null, selectedCategories: [] },
    { selectedIndustry: null, selectedCategories: [] },
  ]);

  const handleChangeindustryrelated_data = (selectedOption, index) => {
    setDropdowns((prevState) => {
      if (!Array.isArray(prevState)) {
        console.error("prevState is not an array");
        return prevState;
      }

      const relatedIndustries = selectedOption.related_industry_name
        ? selectedOption.related_industry_name.split(",").map((name) => ({
            label: name.trim(),
            value: name.trim(),
          }))
        : [];

      const updatedDropdowns = [...prevState];
      updatedDropdowns[index] = {
        ...updatedDropdowns[index],
        selectedIndustry: selectedOption,
        selectedCategories: relatedIndustries,
      };

      return updatedDropdowns;
    });
  };

  //console.log(dropdowns);

  // Handle categories change
  const handleCategoriesChange = (selectedOptions, index) => {
    setDropdowns((prevState) => {
      if (!Array.isArray(prevState)) {
        console.error("prevState is not an array");
        return prevState;
      }

      const updatedDropdowns = [...prevState];
      updatedDropdowns[index] = {
        ...updatedDropdowns[index],
        selectedCategories: selectedOptions,
      };
      return updatedDropdowns;
    });
  };

  // const handleChangeCategories = (selected, index) => {
  //   const updatedCategories = [...selectedIndustries];
  //   updatedCategories[index] = {
  //     ...updatedCategories[index],
  //     categories: selected,
  //   };
  //   setSelectedIndustries(updatedCategories);
  // };

  const handleIndustryChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  // console.log("hshshshshsh", Selectedindustry);
  // console.log("Selected Options for Categories:", selectedOptions);

  const handleKeyDown2 = (event, index) => {
    try {
      if (event.key === "Enter") {
        event.preventDefault();
        if (!inputValue[index]) return;

        const newOption = {
          value: inputValue[index],
          label: inputValue[index],
        };

        setDropdowns((prevState) => {
          const updatedDropdowns = [...prevState];

          if (Array.isArray(updatedDropdowns[index].selectedCategories)) {
            updatedDropdowns[index].selectedCategories.push(newOption);
          } else {
            updatedDropdowns[index] = {
              ...updatedDropdowns[index],
              selectedCategories: [newOption],
            };
          }

          return updatedDropdowns;
        });

        setInputValue((prevInputValue) => {
          const updatedInputValue = [...prevInputValue];
          updatedInputValue[index] = "";
          return updatedInputValue;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (newValue, index) => {
    setInputValue((prevValue) => {
      const updatedValue = [...prevValue];
      updatedValue[index] = newValue;
      return updatedValue;
    });
  };

  //console.log(dropdowns);

  const handleLabelmodal = (index) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [industryModal, setIndustryModal] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]); // State for selected industries
  const [extractedIndustryValues, setExtractedIndustryValues] = useState([]);

  const handleIndustrySelect = (selected) => {
    console.log(selected);
    extractValuesFromSelectedIndustries(selected);
    setSelectedIndustries(selected);
  };

  const extractValuesFromSelectedIndustries = (selectedIndustry) => {
    const values = selectedIndustry.map((item) => item.value);

    // Set the extracted values in the new state variable
    setExtractedIndustryValues(values);
  };

  //console.log(selectedIndustries);
  //console.log(extractedIndustryValues);

  const HandleIndustrYmoDAL = (e) => {
    e.preventDefault();
    console.log("clicked");
    setIndustryModal(true);
    setShowModal(true);
  };

  const handleIndustryCloseModal = () => {
    setIndustryModal(false);
    setShowModal(false);
  };

  // Handle industry selection in the modal
  const handleSaveChangesdynamic = async () => {
    console.log("Starting handleSaveChangesdynamic");

    let fd_from = new FormData();

    // Extract only the 'value' properties from selectedIndustries
    const selectedIndustryValues = selectedIndustries
      .map((option) => option.value)
      .join(",");

    if (selectedIndustryValues) {
      setshowLoaderAdmin(true);
      console.log("Loader set to true, starting to append data.");

      // Iterate through selected templates and add industry data

      fd_from.append("primary_id", main_id); // Append primary_id
      fd_from.append("related_industry_name_data", selectedIndustryValues); // Append industry data

      console.log("Final FormData before sending:", fd_from);

      await server_post_data(save_update_admin_templates, fd_from)
        .then((Response) => {
          console.log("Response received:", Response.data);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            console.error("Error in response:", Response.data.message);
            handleError(Response.data.message);
          } else {
            console.log("Success, updating state.");
            toast.success("Industries updated successfully!");
            setTimeout(() => {
              window.location.reload();
            }, 100);

            handleIndustryCloseModal(); // Close the modal
          }
        })
        .catch((error) => {
          console.error("Error during server post:", error);
          handleError("network");
          setshowLoaderAdmin(false);
        });
    } else {
      console.warn("No industry selected, showing error.");
      handleError("Please Add Industry keyword");
    }
  };

  const dropdownRefLogo = useRef(null);
  const dropdownRefAbstrack = useRef(null);
  const dropdownOpenSelectedicon = useRef(null);
  const plusRef1 = useRef(null);
  const dropdownRef1 = useRef(null);
  const Handledropdown = () => {
    setIsActiveTwo(!isActiveTwo);
    setIsOpentext(false);
  };
  const Handldropdown2 = () => {
    setIsActive(!isActive);
  };
  const Handledropdown3 = () => {
    setIsActiveTwo(!isActiveTwo);
  };
  const openIcon = () => {
    setShowDropdown2(!showDropdown2);
  };
  const handleClickOutside = (event) => {
    // Check if the click is outside any of the dropdown refs
    if (
      (dropdownRefLogo.current &&
        !dropdownRefLogo.current.contains(event.target)) ||
      (dropdownRefAbstrack.current &&
        !dropdownRefAbstrack.current.contains(event.target)) ||
      (dropdownOpenSelectedicon.current &&
        !dropdownOpenSelectedicon.current.contains(event.target))
    ) {
      if (
        dropdownRefLogo.current &&
        !dropdownRefLogo.current.contains(event.target)
      ) {
        setIsActiveTwo(false);
      }
      if (
        dropdownRefAbstrack.current &&
        !dropdownRefAbstrack.current.contains(event.target)
      ) {
        setIsActive(false);
      }
      if (
        dropdownOpenSelectedicon.current &&
        !dropdownOpenSelectedicon.current.contains(event.target)
      ) {
        setShowDropdown2(false);
      }
    }
    if (
      dropdownRef1.current &&
      !dropdownRef1.current.contains(event.target) &&
      plusRef1.current &&
      !plusRef1.current.contains(event.target) &&
      showDropdown1
    ) {
      setShowDropdown1(false);
      setRotatePlus1(false);
      setShowDropdown2(false);
      setRotatePlus2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown1, showDropdown2]);

  return (
    <div>
      <Header />
      <form className="createRightsForm" id="createRightsForm">
        <div className="pageCntn_head d-flex flex-column justify-content-start align-items-start topBarTemplate">
          <div className="container-fluid">
            <div className="AllLogoCustmLogobtn mt-1">
              <Link to="/view_templates">
                <button className="allLogoBtn" onClick={AlliconeColorChange}>
                  <img
                    src={isBlackIcon ? AllLogoIcn : BlackAllLogoIcn}
                    alt="star"
                  />
                  All Templates
                </button>
              </Link>

              <Link to="/admin_canvas_new">
                <button className="EditorBtn" onClick={EditiconeColorChange}>
                  <img src={isBlackIcon2 ? BluePemcil : EDitIcn} alt="star" />
                  Editor
                </button>
              </Link>

              <div className="UndoRundobtns">
                <div className="UndoBtn">
                  <button id="undoButton" onClick={undoDelete}>
                    <img
                      id="undoImage"
                      className="undo"
                      src={redoIcn}
                      alt="undo"
                    ></img>
                  </button>
                </div>
                <div className="UndoBtn">
                  <button
                    id="redoButton"
                    className="redoButton "
                    onClick={redoDelete}
                  >
                    <img
                      id="redoImage"
                      className="redo"
                      src={redoIcn}
                      alt="redo"
                    ></img>
                  </button>
                </div>
              </div>
            </div>
            <div className="TemTop">
              <div className="AllLogoCustmLogobtn mb-3">
                <div className="col-md-2">
                  <div className="dropdownCustom" ref={dropdownRefLogo}>
                    <div
                      onClick={() => {
                        if (!main_id) {
                          Handledropdown();
                        }
                      }}
                      className="dropdownCustom-btn"
                      style={{ height: "48px" }}
                    >
                      {selectedItem2 || "Logo"}
                      <span
                        className={
                          isActiveTwo ? "fas fa-caret-up" : "fas fa-caret-down"
                        }
                      >
                        <img src={dropdown} alt="Dropdown Icon" />
                      </span>
                    </div>
                    <div
                      className="dropdownCustom-content"
                      style={{ display: isActiveTwo ? "block" : "none" }}
                    >
                      {dropdownItems2.map((item) => (
                        <div
                          key={item.description}
                          onClick={() => select_dropdown2(item.description)}
                          className="itemDrop"
                        >
                          {item.description}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/*---DropDown2---*/}

                <div className="col-md-2 changeontemplatetype">
                  <div className="dropdownCustom" ref={dropdownRefAbstrack}>
                    <div
                      onClick={() => {
                        Handldropdown2();
                      }}
                      className="dropdownCustom-btn"
                      style={{ height: "48px" }}
                    >
                      {selectedItem || "Abstract Marks"}
                      <span
                        className={
                          isActive ? "fas fa-caret-up" : "fas fa-caret-down"
                        }
                      >
                        <img src={dropdown} alt="Dropdown Icon" />
                      </span>
                    </div>
                    <div
                      className="dropdownCustom-content"
                      style={{ display: isActive ? "block" : "none" }}
                    >
                      {dropdownItems.map((item) => (
                        <div
                          key={item}
                          onClick={() => select_dropdown(item)}
                          className="itemDrop"
                        >
                          {item.charAt(0).toUpperCase() + item.slice(1)}{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/*---DropDown2---*/}
                <div className="col-md-2 changeontemplatetype">
                  {/* <div
                    className="industryBtttn"
                    onClick={(e) => HandleIndustrYmoDAL(e)}
                  >
                    <button>Industry</button>
                  </div> */}
                  <div className="selectrColor w-100 h-100 errorDiv pt-1   CanvasEditorIndustry">
                    <Select
                      styles={customStyles2}
                      options={industryData}
                      onChange={handleChangeindustrydata}
                      value={Selectedindustry}
                      className={`form-control industry_input inpageinput`}
                      placeholder="Industry"
                      isSearchable
                      isMulti
                      components={{
                        ValueContainer: (props) => (
                          <CustomValueContainer
                            {...props}
                            showAll={showAll}
                            handleToggleShowAll={handleToggleShowAll}
                          />
                        ),
                      }}
                    />
                    <span className="condition_error errorPos"></span>
                  </div>
                </div>
                {/* <div className="AddIndustry">
                <button onClick={() => handleEditClick()}>Add Industry</button>
                <IndustryModal
                  showIndModal={showIndModal}
                  handleCloseUpper={() => setShowIndModal(false)}
                />
              </div> */}
                {/*-----Label name*/}
                <div className="col-md-2">
                  <div className="labelInput errorDiv w-100 ">
                    <input
                      ref={inputRefs[1]}
                      type="text"
                      id="template_name"
                      className={`trio_mandatory form-control mb-0`}
                      name="template_name"
                      placeholder="Template Name"
                      value={templateName}
                      onKeyDown={handleKeyDownSelect}
                      data-index="1"
                      onChange={handleTemplateNameChange}
                    />
                    <span className="condition_error errorPos"></span>
                  </div>
                </div>
                {/* <button onClick={handleCheckList}>
                <img src={checkListTemplate} alt="Check List Template" />
              </button> */}

                <div className="ColorinPut d-flex align-items-center">
                  <div className="colors_holder_wrapper changeontemplatetype2">
                    {color && color.length > 0 && (
                      <>
                        {color.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="squares inpageinput colorinput"
                            >
                              <div
                                className="color_itemSelected w-100 h-100"
                                style={{
                                  backgroundColor: item,
                                }}
                              >
                                <div className="colorGrade1"></div>
                                <div className="colorGrade2"></div>
                                <div className="colorGrade3"></div>
                                <div className="colorGrade4"></div>
                                <div className="colorGrade5"></div>
                                <div className="colorGrade6"></div>
                                <div className="colorGrade7"></div>
                                <div className="colorGrade8"></div>
                                <div className="colorGrade9"></div>
                              </div>
                              <button
                                onClick={(event) =>
                                  handleDeleteColor(event, index)
                                }
                              >
                                <img
                                  style={{ height: "20px", width: "15px" }}
                                  src={Delet}
                                  alt="Logo Academy"
                                />
                              </button>
                            </div>
                          );
                        })}
                        {/* <span className="condition_error"></span> */}
                      </>
                    )}
                  </div>

                  <div className="plus_wrapper changeontemplatetype">
                    <img
                      className={`plus ${rotatePlus1 ? "rotate" : ""}`}
                      src={plus}
                      ref={plusRef1}
                      onClick={(event) => {
                        rotateDropdown1(event);
                      }}
                      alt="dropdown plus"
                    />
                  </div>
                  <div className="colorDiv changeontemplatetype">
                    {showDropdown1 && (
                      <div className="dropdown_menu2" ref={dropdownRef1}>
                        {color &&
                        color.filter((item) => item === "" || item === "#fff")
                          .length === 0 ? (
                          <div className="no_empty_slots_message">
                            {/* <img src={topArrow} alt="Up Arrow"></img> */}
                            You have reached the maximum number of colors
                          </div>
                        ) : (
                          <div className="color_grid color_grid22">
                            {colorItems.map((item, colorIndex) => (
                              <div
                                key={colorIndex}
                                className={`colorItems ${item.className} ${
                                  color.includes(colorMapping[item.color])
                                    ? "selected"
                                    : ""
                                }`}
                                style={{
                                  opacity: color.includes(
                                    colorMapping[item.color]
                                  ),
                                  position: "relative",
                                }}
                                onClick={() => handleColorItemClick(item.color)}
                              >
                                {color.includes(colorMapping[item.color]) && (
                                  <div className="tickMark">
                                    <img src={tickmark} alt="tick"></img>
                                  </div>
                                )}
                                <div className="colorGrade1"></div>
                                <div className="colorGrade2"></div>
                                <div className="colorGrade3"></div>
                                <div className="colorGrade4"></div>
                                <div className="colorGrade5"></div>
                                <div className="colorGrade6"></div>
                                <div className="colorGrade7"></div>
                                <div className="colorGrade8"></div>
                                <div className="colorGrade9"></div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="saveBtn">
                {" "}
                <button onClick={saveCanvasAsJSON}>Save</button>
              </div>  */}

                {/* Icons Select div */}

                <div
                  className="selectIconDiv changeontemplatetype"
                  ref={dropdownOpenSelectedicon}
                >
                  <div className="iconBtnDiv" onClick={openIcon}>
                    <div className="iconSlcShow">
                      {iconSelectionArray.length > 0 && (
                        <img
                          src={activeIndexImg}
                          className="iconDivCoimg"
                          alt="selected-icon"
                        />
                      )}
                    </div>
                    <img
                      className="selectIconArrow"
                      src={Down23}
                      alt="down-arrow"
                    />
                  </div>
                  {showDropdown2 && (
                    <div className="iconsContainer">
                      <div className="iconsFlexDiv">
                        {iconSelectionArray.map((icon, i) => {
                          return (
                            <div
                              key={icon.actualIndex}
                              className={`iconDivCo ${
                                activeIndex === icon.actualIndex
                                  ? "activeIcon"
                                  : ""
                              }`}
                              onClick={() => handleIconClick(icon, i)}
                            >
                              <img
                                src={icon.src}
                                className={`iconDivCoimg`}
                                alt={`icon-${icon.actualIndex}`}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className="saveBtn changeontemplatetype">
                  {" "}
                  <button
                    type="button"
                    onClick={() =>
                      ShowDiffrentColorLogo(
                        "createRightsForm",
                        save_update_admin_templates
                      )
                    }
                  >
                    Next
                  </button>
                </div>

                <div className="saveBtn changeontemplatetype3">
                  {" "}
                  <button
                    type="button"
                    onClick={() =>
                      saveCanvasAsJSON2(
                        "createRightsForm",
                        save_update_admin_templates
                      )
                    }
                  >
                    Save
                  </button>
                </div>
                {/*<button onClick={handleCheckList2}>
                <img src={checkListTemplate} alt="Check List Template" />
              </button>

              {showCheckboxList2 && (
                <div className="CheckList2">
                  <label>
                    <input
                      type="checkbox"
                      className="InpCls"
                      checked={selectedColors.length === template_type.length}
                      onChange={handleSelectLabelAll2}
                    />{" "}
                    Select All
                  </label>
                  <br />
                  {template_type.map((template_type, index) => (
                    <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          name="template_type"
                          value={template_type.value}
                          checked={selectedType.includes(template_type.value)}
                          onChange={handleCheckboxChangeLabel2}
                        />
                        {template_type.label}
                      </label>
                      <br />
                    </div>
                  ))}
                </div>
              )} */}
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*--*/}
      <div className="canVasContainer">
        {showLoaderAdmin && <Loader />}
        {/*-------Canvas Containt-------*/}
        <div className="container-fluid r-0 p-0">
          <div className="d-flex canvasBg">
            <div className="row">
              <div className="col-12">
                <div
                  className={
                    windowWidth > 500 ? "sideToolBar" : "mobileToolbar"
                  }
                >
                  <div className="icon">
                    <div
                      className={`TxtBtn `}
                      onMouseEnter={() => setHovered(true)}
                      onMouseLeave={() => setHovered(false)}
                    >
                      <div>
                        <button onClick={() => toggle("icon")}>
                          <img
                            src={
                              hovered || isOpenicon
                                ? add_reactionBlue
                                : EmogiIcn
                            }
                            alt="Icon"
                          ></img>
                        </button>
                        <label style={{ color: "initial" }}>Icon</label>
                      </div>
                    </div>

                    <div
                      className={`TxtBtn ${isActive2 ? "active" : ""}`}
                      onMouseEnter={() => setHovered2(true)}
                      onMouseLeave={() => setHovered2(false)}
                    >
                      <div>
                        {" "}
                        <button onClick={() => toggle("text")}>
                          <img
                            src={hovered2 || isOpentext ? TextIcnBlue : TxtIcn}
                            alt="Icon"
                          ></img>
                        </button>
                        <label
                          style={{ color: isActive2 ? "blue" : "initial" }}
                        >
                          Text
                        </label>
                      </div>
                    </div>

                    <div
                      className={`TxtBtn ${isActive3 ? "active" : ""}`}
                      onMouseEnter={() => setHovered3(true)}
                      onMouseLeave={() => setHovered3(false)}
                    >
                      <div>
                        <button onClick={() => toggle("background")}>
                          <img
                            src={
                              hovered3 || isOpenBg
                                ? BackgroundWindowIcnBlue
                                : BackgroundWindowIcn
                            }
                            alt="Icon"
                          ></img>
                        </button>
                        <label
                          style={{ color: isActive3 ? "blue" : "initial" }}
                        >
                          Background
                        </label>
                      </div>
                    </div>

                    <div
                      className={`TxtBtn ${isActive4 ? "active" : ""}`}
                      onMouseEnter={() => setHovered4(true)}
                      onMouseLeave={() => setHovered4(false)}
                    >
                      <button
                        onClick={() => toggle("shapes")}
                        className={clicked ? "active" : ""}
                      >
                        <img
                          src={
                            hovered4 || isOpenshape ? ShapeIcnBlue : ShapeIcn
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: isActive4 ? "blue" : "initial" }}>
                        Shapes
                      </label>
                    </div>

                    {/* <div
                      className={`TxtBtn ${isActive5 ? "active" : ""}`}
                      onMouseEnter={() => setHovered5(true)}
                      onMouseLeave={() => setHovered5(false)}
                    >
                      <button
                        onClick={() => {
                          toggle("template");
                          master_data_templates("c", null);
                        }}
                        className={clicked ? "active" : ""}
                      >
                        <img
                          src={
                            hovered5 || isOpenTemplate
                              ? TemplGridIcnBlue
                              : TemplGridIcn
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: isActive5 ? "blue" : "initial" }}>
                        Template{" "}
                      </label>
                    </div> */}

                    <div
                      className={`TxtBtn ${isActive7 ? "active" : ""}`}
                      onMouseEnter={() => setHovered7(true)}
                      onMouseLeave={() => setHovered7(false)}
                    >
                      <button onClick={() => toggle("uploads")}>
                        <img
                          src={
                            hovered7 || isOpenuploads
                              ? UploadIcnBlue
                              : UploadIcn
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: isActive7 ? "blue" : "initial" }}>
                        Elements{" "}
                      </label>
                    </div>

                    <div
                      className={`TxtBtn ${
                        isActive9 ? "active" : ""
                      } changeontemplatetype`}
                      onMouseEnter={() => setHovered9(true)}
                      onMouseLeave={() => setHovered9(false)}
                    >
                      <button onClick={() => toggle("letter")}>
                        <img
                          className="LetterIConSvg"
                          src={
                            hovered8 || isletter ? letterIconBlue : letterIcon
                          }
                          alt="Icon"
                        ></img>
                      </button>
                      <label style={{ color: isActive9 ? "blue" : "initial" }}>
                        Letters{" "}
                      </label>
                    </div>
                  </div>

                  <div className="DownloadBtn">
                    <button onClick={toggleSecondButton2}>Download</button>
                  </div>
                </div>
              </div>
            </div>

            <div className={sidebarClassName}>
              <div
                className="SideBarContainer sideContainerHeight"
                
              >
                <div className="container">
                  {" "}
                  {isOpenicon && (
                    <Icons
                      handleClose={handleClose}
                      setIconData={handleIconData}
                    />
                  )}
                </div>

                <div className="container">
                  {isOpentext && (
                    <div className="testcontainer" ref={textDivRef} onScroll={() => handleScroll('text')}
                    style={{ height: '450px', overflowY: 'scroll' }}>
                    <div className="addtxt">
                      <div className="SearchConTnnrr">
                        <div
                          className={`changeontemplatetype25 ${
                            selectedItem2 === "Logo"
                              ? "FontTabss"
                              : "unselectLogoHideDiv"
                          }`}
                        >
                          <button
                            onClick={() => setSelectedTab("company_name")}
                            style={{
                              opacity: selectedTab === "company_name" ? 1 : 0.5,
                              cursor: "pointer",
                            }}
                            className={`
                                selectedTab === "company_name" ? "active" : ""
                              `}
                          >
                            <p className="m-0">Company</p>
                          </button>
                          <button
                            onClick={() => setSelectedTab("slogan")}
                            style={{
                              opacity: selectedTab === "slogan" ? 1 : 0.5,
                              cursor: "pointer",
                            }}
                            className={`
                                selectedTab === "slogan" ? "active" : ""
                              `}
                          >
                            <p className="m-0">Slogan</p>
                          </button>
                        </div>

                        <div className="container TextFiledBox">
                          <p className="">Add Text</p>
                          <p
                            className="upladCustumFOnt"
                            onClick={handleFontuploadModal}
                          >
                            Upload
                          </p>
                        </div>
                        <div className="crossIcn">
                          <button onClick={handleClose}>
                            <img src={crossIcn} alt="cross"></img>
                          </button>
                        </div>
                        <div className="FontSearchbar">
                          <div className="mb-3">
                            <Select
                              styles={customStyles3}
                              options={categoryOptions}
                              value={selectedCategory}
                              onChange={handleCategoryChange}
                              placeholder="Select a category"
                              isClearable
                            />
                          </div>
                        </div>
                      </div>

                      {filteredFonts.slice(0, initialFontCount).length > 0 ? (
                        filteredFonts
                          .slice(0, initialFontCount)
                          .map((font, index) => (
                            <div className="TextAddContain" key={index}>
                              <div className="txtContainer">
                                <div className="txt1">
                                  <button
                                    onClick={() => handleFontSelection(font)}
                                  >
                                    <p
                                      style={{
                                        fontFamily: font.family,
                                        cursor: "pointer",
                                      }}
                                    >
                                      {`Add Text`}
                                    </p>
                                    <label
                                      style={{
                                        fontFamily: font.family,
                                        cursor: "pointer",
                                      }}
                                    >
                                      ({font.family})
                                    </label>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <p>No fonts found</p>
                      )}
                      {filteredFonts.length > initialFontCount && (
                        <div className="ShowAllDiv mb-3 loadMoreBTn">
                          {/* <button onClick={loadMoreFonts}>
                            <label>Load More</label>
                          </button> */}
                        </div>
                      )}
                    </div>
                    </div>
                  )}
                </div>

                <div className="container">
                  {isOpenBg && (
                    <div className="TxtBtn2 bgBtnInside">
                      <div className="ChooseColorBtn">
                        <div className="crossIcn UploadCrossIcn">
                          <button onClick={handleClose}>
                            <img src={crossIcn} alt="cross img"></img>
                          </button>
                        </div>
                        <button onClick={handleButtonClick}>
                          <input
                            type="color"
                            ref={colorPickerRef}
                            style={{ display: "none", cursor: "pointer" }}
                            onChange={handleCanvasColorChange}
                          />
                          Choose from pallette
                        </button>
                      </div>

                      <div className="backgroundColorOptions bgColor">
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {colors.map((palette, index) => (
                            <div className="backgroundColorOptions" key={index}>
                              <div className="ColorTxt">
                                <p>{palette.title}</p>
                              </div>
                              <div className="SideBArBackgroundCOlor">
                                {palette.list.map((color, colorIndex) => (
                                  <div
                                    key={colorIndex}
                                    className={`catagorieBgcolors ${
                                      activeColor === color ? "active" : ""
                                    }`}
                                    style={{
                                      background: Array.isArray(color)
                                        ? `linear-gradient(to right, ${color.join(
                                            ", "
                                          )})`
                                        : color,
                                      outline:
                                        activeColor === color
                                          ? "2px solid #6473fddc"
                                          : "",
                                      border: `12px solid ${
                                        Array.isArray(color)
                                          ? "transparent"
                                          : color
                                      }`,
                                    }}
                                    onClick={() => handleClick(color)}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="container">
                  {isOpenshape && (
                    <div className="TxtBtn2 shapeBtns">
                      <div>
                        {currentContent === "main" && (
                          <div className="ShapeTabs">
                            <div
                              className="Tab"
                              onClick={() => setCurrentContent("shape")}
                            >
                              <img src={shape1} alt="Shape Inner" />
                              <label>Shapes</label>
                            </div>
                            <div
                              className="Tab"
                              onClick={() => setCurrentContent("line")}
                            >
                              <img src={line1} alt="Line" />
                              <label>Lines</label>
                            </div>
                            <div
                              className="Tab"
                              onClick={() => setCurrentContent("frame")}
                            >
                              <img src={fram1} alt="Frames" />
                              <label>Frames</label>
                            </div>
                          </div>
                        )}

                        {currentContent !== "main" && (
                          <div>
                            <div className="backbuttonCont">
                              <button
                                className="BackButton"
                                onClick={() => {
                                  setCurrentContent("main");
                                  setOptionsVisible(null);
                                }}
                              >
                                Back to{" "}
                                {currentContent.charAt(0).toUpperCase() +
                                  currentContent.slice(1)}
                              </button>
                            </div>
                            <button
                              className="upldBtnShape"
                              onClick={() =>
                                handleShapesButtonUploadimg(currentContent)
                              }
                            >
                              Upload
                            </button>
                            <div className="Shapesdata" ref={optionsButtonRef}>
                              {filteredShapeData.length > 0 ? (
                                filteredShapeData.map((icon, index) => (
                                  <div
                                    key={icon.id}
                                    className="ItemWrapper"
                                    onMouseEnter={() => hoverImage(index)}
                                    onMouseLeave={() => hoverImage(null)}
                                  >
                                    <div
                                      onClick={() => handleClickShape(index)}
                                    >
                                      <img
                                        src={icon.shape_url}
                                        alt={`${icon.shape_type} ${icon.primary_id}`}
                                        className="ShapeImageMapd"
                                      />
                                    </div>

                                    {hoveredImage === index && (
                                      <div className="imgHoverShape">
                                        <button
                                          className="optionsBttns"
                                          onClick={() =>
                                            handleOptionClick(index, icon.type)
                                          }
                                        >
                                          <img
                                            className="OPTiMG"
                                            src={uploadImgOptions}
                                            alt="Options"
                                          />
                                        </button>
                                      </div>
                                    )}

                                    {optionsVisible === index &&
                                      currentOptionsType === icon.type && (
                                        <div
                                          className="optionsMenu"
                                          ref={optionsMenuRef}
                                          style={{ gap: "0.5rem" }}
                                        >
                                          <button
                                            onClick={() =>
                                              handleDownload(icon.shape_url)
                                            }
                                            style={{
                                              display: "flex",
                                              gap: "0.3rem",
                                              alignItems: "center",
                                              padding: "0 0.5rem",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <img
                                              src={downloadicn}
                                              alt="Download"
                                              style={{
                                                width: "0.8rem",
                                                cursor: "pointer",
                                              }}
                                            />
                                            <label
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Download
                                            </label>
                                          </button>
                                          <button
                                            className="removeBtn"
                                            onClick={() =>
                                              master_data_action_shapes(
                                                icon.primary_id
                                              )
                                            }
                                            style={{
                                              display: "flex",
                                              gap: "0.3rem",
                                              alignItems: "center",
                                              padding: "0 0.4rem",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <img
                                              src={removeIcn}
                                              style={{
                                                width: "0.8rem",
                                                cursor: "pointer",
                                              }}
                                              alt="Remove"
                                            />
                                            <label
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              Remove
                                            </label>
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                ))
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "0.5rem",
                                  }}
                                >
                                  No{" "}
                                  {currentContent.charAt(0).toUpperCase() +
                                    currentContent.slice(1)}{" "}
                                  available
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {isOpenTemplate && (
                  <div className="container">
                    <div className="crossIcn TemplateCross">
                      <button
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <img src={crossIcn}></img>
                      </button>
                    </div>

                    <div
                      className="row"
                      style={{ flexWrap: "wrap", overflow: "hidden" }}
                    >
                      {Gettemplates ? (
                        <div className="row " style={{ flexWrap: "wrap" }}>
                          {Gettemplates.map((logoData, index) => (
                            <div
                              className="col-sm-6 mt-3"
                              key={index}
                              onClick={() => {
                                handleImageClick2(logoData);
                              }}
                            >
                              <div
                                className="girdLogo_item2"
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <canvas
                                  ref={(ref) => {
                                    if (ref) {
                                      createCanvas(
                                        ref,
                                        logoData,
                                        "",
                                        0,
                                        "0",
                                        0
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p>No Templates found.</p>
                      )}
                    </div>
                  </div>
                )}

                <div className="container">
                  {isOpenuploads && (
                    <div  className='testcontainer'  ref={specificDivRef}
                 onScroll={() => handleScroll('element')}
                  style={{ height: '450px', overflowY: 'scroll' }}>
                    <div className="upLoadBTn">
                      <div className="crossIcn UploadCrossIcn">
                        <button onClick={handleClose}>
                          <img src={crossIcn}></img>
                        </button>
                      </div>
                      <button onClick={handleButtonUploadimg}>Upload</button>
                      <div
                        className="d-flex justify-content-center w-100"
                        style={{ marginLeft: "-10px" }}
                      >
                        <label>My Upload</label>
                      </div>

                      <div className="UploadedImggetcontainer">
                        {GetUploadImg ? (
                          GetUploadImg.map((person, index) => (
                            <div
                              key={index}
                              onMouseEnter={() => handleMouseEnter4(index)}
                              onMouseLeave={handleMouseLeave4}
                              ref={(el) => (containerRefs.current[index] = el)}
                              className={`renderUploadedImg deletediv${person.primary_id}`}
                            >
                              <div className="UploadImgs">
                                {hoveredImageIndex === index && (
                                  <button
                                    className="uploadImgOptions"
                                    onClick={() => toggleVisibility4(index)}
                                  >
                                    <img
                                      src={uploadImgOptions}
                                      alt="Upload Options"
                                    />
                                  </button>
                                )}

                                <button
                                  onClick={() =>
                                    handleImageClick(
                                      APL_LINK + IMAGEPATH + person.upload_img,
                                      0
                                    )
                                  }
                                >
                                  {" "}
                                  <img
                                    id="upload_img"
                                    name="upload_img"
                                    src={
                                      APL_LINK + IMAGEPATH + person.upload_img
                                    }
                                    alt={`Image ${index + 1}`}
                                  />
                                </button>
                                {isVisible4 === index && (
                                  <div
                                    className="uploadsssOpp"
                                    ref={HideDwnUpRef}
                                  >
                                    <div className="uploadGetimgContainer">
                                      <button
                                        onClick={() =>
                                          handleDownload(
                                            APL_LINK +
                                              IMAGEPATH +
                                              person.upload_img
                                          )
                                        }
                                      >
                                        <img src={downloadicn}></img>
                                        <label>Download original</label>
                                      </button>
                                      <button
                                        onClick={() =>
                                          master_data_action(person.primary_id)
                                        }
                                      >
                                        <img src={removeIcn}></img>
                                        <label>Remove</label>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
 
                          ))
                        ) : (
                          <div
                            className="d-flex justify-content-center w-100"
                            style={{ marginLeft: "-10px" }}
                          >
                            <p> No images found.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
                <div className="container">
                  {isletter && (
                    <div classsName="contentOfLetter">
                      <div className="FontTabss3">
                        <button
                          onClick={() => handleTabClick("Letter_first")}
                          style={{
                            opacity:
                              selectedLetter === "Letter_first" ? 1 : 0.5,
                            cursor: "pointer",
                          }}
                          className={
                            selectedLetter === "Letter_first"
                              ? "active"
                              : "inactive"
                          }
                        >
                          <p className="m-0">1st Letter</p>
                        </button>
                        <button
                          onClick={() => handleTabClick("Letter_Second")}
                          style={{
                            opacity:
                              selectedLetter === "Letter_Second" ? 1 : 0.5,
                            cursor: "pointer",
                          }}
                          className={
                            selectedLetter === "Letter_Second"
                              ? "active"
                              : "inactive"
                          }
                        >
                          <p className="m-0">2nd Letter</p>
                        </button>
                        <button
                          onClick={() => handleTabClick("Letter_Third")}
                          style={{
                            opacity:
                              selectedLetter === "Letter_Third" ? 1 : 0.5,
                            cursor: "pointer",
                          }}
                          className={
                            selectedLetter === "Letter_Third"
                              ? "active"
                              : "inactive"
                          }
                        >
                          <p className="m-0">3rd Letter</p>
                        </button>
                      </div>

                      <div className="LttersSection">
                        {alphabet.map((letter, index) => (
                          <button
                            key={index}
                            onClick={() => handleLetterClick(letter)}
                            style={{
                              opacity: selectedLetter === letter ? 1 : 0.5,
                              cursor: "pointer",
                            }}
                            className={
                              selectedLetter === letter ? "active" : ""
                            }
                          >
                            <p className="m-0">{letter}</p>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="container">
                  {isColor && (
                    <div>
                      <div className="backgroundColorOptions">
                        <div className="ColorTxt">
                          <p>Choose Logo Colors</p>
                        </div>
                        <div className="color_grid">
                          {colorItems.map((item, colorIndex) => (
                            <div
                              key={colorIndex}
                              className={`colorItem ${item.className}`}
                              style={{
                                position: "relative",
                              }}
                              onClick={() => handleColorItemClick(item.color)}
                            >
                              {color.includes(colorMapping[item.color]) && (
                                <div className="tickMark">
                                  <img src={tickmark} alt="tick" />
                                </div>
                              )}
                              <div className="colorGrade1"></div>
                              <div className="colorGrade2"></div>
                              <div className="colorGrade3"></div>
                              <div className="colorGrade4"></div>
                              <div className="colorGrade5"></div>
                              <div className="colorGrade6"></div>
                              <div className="colorGrade7"></div>
                              <div className="colorGrade8"></div>
                              <div className="colorGrade9"></div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="colorHead">
                        <div className="colors_holder_wrapper">
                          {color.map((item, index) => (
                            <div key={index} className="squares">
                              <div
                                className="color_itemSelected w-100 h-100"
                                style={{ backgroundColor: item }}
                              >
                                <div className="colorGrade1"></div>
                                <div className="colorGrade2"></div>
                                <div className="colorGrade3"></div>
                                <div className="colorGrade4"></div>
                                <div className="colorGrade5"></div>
                                <div className="colorGrade6"></div>
                                <div className="colorGrade7"></div>
                                <div className="colorGrade8"></div>
                                <div className="colorGrade9"></div>
                              </div>
                              <button
                                onClick={(event) =>
                                  handleDeleteColor(event, index)
                                }
                              >
                                <img src={Delet} alt="Delete" />
                              </button>
                            </div>
                          ))}
                        </div>
                        <div
                          className="plus_wrapper plusAddBtn"
                          onClick={handleAddColors}
                        >
                          <img className={`plus`} src={plus} alt="Add" />
                          <p>Add</p>
                        </div>
                      </div>
                      {setColors.length > 0 && (
                        <div className="addedColors">
                          {setColors.map((variant, variantIndex) => (
                            <div
                              className="backgroundColorOptions savedVariant"
                              key={variantIndex}
                            >
                              <div className="ColorTxt mb-1">
                                <p>Variant {variantIndex + 1}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <div className="colors_holder_wrapper">
                                  {variant.map((color, index) => (
                                    <div key={index} className="squares">
                                      <div
                                        className="color_itemSelected w-100 h-100"
                                        style={{ backgroundColor: color }}
                                      >
                                        <div className="colorGrade1"></div>
                                        <div className="colorGrade2"></div>
                                        <div className="colorGrade3"></div>
                                        <div className="colorGrade4"></div>
                                        <div className="colorGrade5"></div>
                                        <div className="colorGrade6"></div>
                                        <div className="colorGrade7"></div>
                                        <div className="colorGrade8"></div>
                                        <div className="colorGrade9"></div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  className={`plus_wrapper plusAddBtn canvas_variant${variantIndex}`}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <canvas
                                    ref={(ref) =>
                                      createCanvas(
                                        ref,
                                        CanvasVariant[variantIndex],
                                        "variants",
                                        0,
                                        "0",
                                        "0"
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className="plus_wrapper plusAddBtn"
                                  onClick={() =>
                                    handleDeleteVariant(variantIndex)
                                  }
                                >
                                  <img
                                    className={`plus`}
                                    style={{ width: "1.3rem" }}
                                    src={DeletRed}
                                    alt="Delete"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="canvasEditorCOntainer col-lg-8 mx-auto mobilePading">
              <div className="col-col-12">
                {/*------Text ToolBar-----*/}

                <div className="container ToolBArsection">
                  {(isTextToolVisible || isObjectSelected || isGroupObj) && (
                    <div className="toolBarContainer">
                      <div className="TextEdittoolBar">
                        {isTextToolVisible && (
                          <div className="topTxtEditToolBar2">
                            <div className="TxtEdit">
                              <div className="ediTBBUTN">
                                <button
                                  onClick={handleFontButtonClick}
                                  className={buttonClass}
                                >
                                  <img src={imgSrc} alt="fontFamily" />
                                  {truncatedFontFamily}
                                </button>
                              </div>
                            </div>
                            <div className="TxtEdit tooltip-button">
                              <div className="gratterLess ">
                                <button
                                  style={{ background: "transparent" }}
                                  onClick={() => handleFontSizeChange(-1)}
                                >
                                  <img src={lessIcn}></img>
                                </button>
                                <select
                                  className="FontOptionDropdown"
                                  value={selectedFontSize}
                                  onChange={(e) => {
                                    const newFontSize = parseInt(
                                      e.target.value,
                                      10
                                    );
                                    // Set the font size to the selected value
                                    handleFontSizeChange(
                                      newFontSize - selectedFontSize
                                    );
                                  }}
                                >
                                  {fontSizes.map((size) => (
                                    <option key={size} value={size}>
                                      {size}px
                                    </option>
                                  ))}
                                  {/* Add an option for custom size */}
                                  <option value={selectedFontSize} selected>
                                    {selectedFontSize}px
                                  </option>
                                </select>
                                <button
                                  style={{ background: "transparent" }}
                                  onClick={() => handleFontSizeChange(1)}
                                >
                                  <img src={graterIcn}></img>
                                </button>
                                <span className="tooltip-text">Font size</span>
                              </div>
                            </div>

                            <div className="colorinputtt tooltip-button">
                              <input
                                className="custom-input tooltip-button"
                                type="color"
                                Value={SetTextcolor}
                                onChange={(e) =>
                                  handleTextColorChange(e.target.value)
                                }
                              />
                              {/* <span className="tooltip-text">Font color</span> */}
                            </div>
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={() => toggleAlignment()}
                              >
                                {alignmentPosition === "left" && (
                                  <img
                                    src={aligmentLeft}
                                    alt="Left Alignment"
                                  />
                                )}
                                {alignmentPosition === "center" && (
                                  <img
                                    src={aligmntIcn}
                                    alt="Center Alignment"
                                  />
                                )}
                                {alignmentPosition === "right" && (
                                  <img
                                    src={aligmentRight}
                                    alt="Right Alignment"
                                  />
                                )}
                                <span className="tooltip-text">Alignment</span>
                              </button>
                            </div>
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={convertTextToUpperCase}
                              >
                                <img src={CaptallSmallIcn} alt="img"></img>
                                <span className="tooltip-text">Capital</span>
                              </button>
                            </div>
                            {/* <div className="aligmentIcn"> */}
                            <div
                              className={`aligmentIcn ${
                                Textweight ? "aligmentIcnSlct" : ""
                              } `}
                            >
                              <button
                                className="tooltip-button"
                                onClick={() =>
                                  handleFontStyleChange(true, false)
                                }
                              >
                                <img src={BoldIcn} alt="Bold" />
                                <span className="tooltip-text">Bold</span>
                              </button>
                            </div>
                            <div
                              className={`aligmentIcn ${
                                Textstyle ? "aligmentIcnSlct" : ""
                              } `}
                            >
                              <button
                                className="tooltip-button"
                                onClick={() =>
                                  handleFontStyleChange(false, true)
                                }
                              >
                                <img src={IteLicIcn} alt="Italic" />
                                <span className="tooltip-text">Italic</span>
                              </button>
                            </div>

                            <div
                              ref={letterRef}
                              style={{ position: "relative" }}
                            >
                              <div className="aligmentIcn ">
                                <button
                                  className="tooltip-button"
                                  onClick={() =>
                                    setLetterSpacingVisible(
                                      !isLetterSpacingVisible
                                    )
                                  }
                                >
                                  <img
                                    src={letterSpacing}
                                    className="letterSpacing"
                                    alt="Opacity"
                                  />
                                  {/* <span className="tooltip-text">
                                    Letter Spacing
                                  </span> */}
                                </button>
                              </div>

                              {isLetterSpacingVisible && (
                                <div className="letterSpacingRage">
                                  <div className="LtterSpacingConts">
                                    <label>Letter Spacing</label>
                                    <div className="d-flex letterSpc">
                                      <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={letterSpacingValue}
                                        onChange={handleLetterSpacingChange}
                                      />
                                      <p>{letterSpacingValue}px</p>
                                    </div>
                                    <label>Line Height</label>
                                    <div className="d-flex Heighline">
                                      <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={heightSpacingValue}
                                        onChange={handleheightSpacingChange}
                                      />
                                      <p>{heightSpacingValue}px</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={handleShowLayers}
                              >
                                <img src={possitionIcn} alt="Duplicate"></img>
                                <span className="tooltip-text">Possition</span>
                              </button>
                            </div>
                            {isPossitionsLayers && (
                              <div
                                className="posstionLayer"
                                ref={PosstionLayer}
                              >
                                <div className="possitns">
                                  <div className="row">
                                    <div className="PossitonDiv">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button onClick={bringLayerUp}>
                                          <img
                                            src={frontLayer}
                                            alt="Front Layer"
                                            className="me-2"
                                          />
                                          Layer Up
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button onClick={bringLayerDown}>
                                          <img
                                            src={backLayer}
                                            alt="Back Layer"
                                            className="me-2"
                                          />
                                          Layer Down
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="PossitonDiv">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button onClick={bringToFront}>
                                          <img
                                            src={frontLayer}
                                            alt="Front Layer"
                                            className="me-2"
                                          />
                                          Front Layer
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button onClick={sendToBack}>
                                          <img
                                            src={backLayer}
                                            alt="Back Layer"
                                            className="me-2"
                                          />
                                          Back Layer
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="AligmntDiv">
                                    <label className="ALigmttxt">
                                      Alignemnt
                                    </label>
                                    <div className="row mt-2">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button
                                          onClick={() =>
                                            handleAlignment("left")
                                          }
                                        >
                                          <img
                                            className="me-2"
                                            src={aligmentLeft}
                                            alt="img"
                                          />
                                          Left
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button
                                          onClick={() =>
                                            handleAlignment("right")
                                          }
                                        >
                                          {" "}
                                          <img
                                            className="me-2"
                                            src={aligmentRight}
                                            alt="img"
                                          />
                                          Right
                                        </button>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button
                                          onClick={() => handleAlignment("top")}
                                        >
                                          {" "}
                                          <img
                                            className="me-2"
                                            src={topALigment}
                                            alt="img"
                                          />
                                          Top
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button
                                          onClick={() =>
                                            handleAlignment("bottom")
                                          }
                                        >
                                          {" "}
                                          <img
                                            className="me-2 bottmAigmnt"
                                            src={topALigment}
                                            alt="img"
                                          />{" "}
                                          Bottom
                                        </button>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button
                                          onClick={() =>
                                            handleAlignment("center")
                                          }
                                        >
                                          <img
                                            className="me-2"
                                            src={aligmntIcn}
                                            alt="img"
                                          />{" "}
                                          Center
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button
                                          onClick={() =>
                                            handleAlignment("middle")
                                          }
                                        >
                                          {" "}
                                          <img
                                            className="me-2"
                                            src={centerALigment}
                                            alt="img"
                                          />{" "}
                                          Middle
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={toggleOpacityInput}
                              >
                                <img src={OpeSityObjctIcn} alt="Opacity" />
                                <span className="tooltip-text">Opacity</span>
                              </button>
                            </div>
                            <div className="opesityFill">
                              {showOpacityInput && (
                                <div ref={opacityInputRef}>
                                  <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    value={opacity}
                                    onChange={handleOpacityChange}
                                  />
                                  <span>{opacity}%</span>
                                </div>
                              )}
                            </div>
                            {/* <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={duplicateObject}
                              >
                                <img
                                  src={objectDuplicateIcn}
                                  alt="Duplicate"
                                ></img>
                                <span className="tooltip-text">Duplicate</span>
                              </button>
                            </div> */}
                            <div className="aligmentIcn">
                              <div className="Container">
                                {" "}
                                <button
                                  className="tooltip-button"
                                  onClick={handleDeleteClick}
                                >
                                  <img src={objectDltIcn} alt="Delete"></img>
                                  <span className="tooltip-text">Delete</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/*Object Canvas */}
                      <div className="TextEdittoolBar2">
                        {isObjectSelected && (
                          <div className="topTxtEditToolBar2">
                            {/* <div className="TxtEdit">
                              <button className="tooltip-button">
                                <img src={ReplaceIcn} alt="img"></img>
                                <span className="tooltip-text2">Replace</span>
                                Replace
                              </button>
                            </div> */}
                            <div className="TxtEdit">
                              <button
                                className="tooltip-button flipICnnss"
                                onClick={toggleFlipButtons}
                              >
                                <span className="tooltip-text">Flip</span>
                                Flip
                              </button>

                              {showFlipButtons && (
                                <div className="flipss" ref={FlipLayer}>
                                  <div className="FlipHorizintall">
                                    <button
                                      onClick={() => flipObject("horizontal")}
                                    >
                                      Flip Horizontally
                                    </button>
                                  </div>
                                  <div className="FlipVertically">
                                    <button
                                      onClick={() => flipObject("vertical")}
                                    >
                                      Flip Vertically
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            {shopcolor &&
                              (colorcodeloop && colorcodeloop.length > 0 ? (
                                colorcodeloop.map((color, index) => (
                                  <div
                                    key={index}
                                    className="colorinputtt tooltip-button"
                                  >
                                    <input
                                      className="custom-input tooltip-button"
                                      type="color"
                                      Value={iconchngcolor} // Set the initial color value
                                      color_code="#FFFFFF"
                                      id={`color_id${index}`}
                                      onChange={(e) => {
                                        handleIconColorChange(
                                          e.target.value,
                                          `color_id${index}`
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                    {/* <span className="tooltip-text">
                                      Font color
                                    </span> */}
                                  </div>
                                ))
                              ) : (
                                <div className="colorinputtt tooltip-button">
                                  <input
                                    className="custom-input tooltip-button"
                                    type="color"
                                    color_code={iconchngcolor}
                                    Value={iconchngcolor}
                                    id="color_id0"
                                    onChange={(e) => {
                                      handleIconColorChange(
                                        e.target.value,
                                        "color_id0"
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  {/* <span className="tooltip-text">
                                    Font color
                                  </span> */}
                                </div>
                              ))}

                            <div className="TxtEdit">
                              <button
                                className="tooltip-button flipIcn"
                                onClick={handleCropButtonClick}
                              >
                                Crop
                                <span className="tooltip-text3">Crop</span>
                              </button>
                            </div>
                            {/* <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={() => toggleAlignment()}
                              >
                                {alignmentPosition === "left" && (
                                  <img
                                    src={aligmentLeft}
                                    alt="Left Alignment"
                                  />
                                )}
                                {alignmentPosition === "center" && (
                                  <img
                                    src={aligmntIcn}
                                    alt="Center Alignment"
                                  />
                                )}
                                {alignmentPosition === "right" && (
                                  <img
                                    src={aligmentRight}
                                    alt="Right Alignment"
                                  />
                                )}
                                <span className="tooltip-text">Alignment</span>
                              </button>
                            </div> */}
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={handleShowLayers}
                              >
                                <img src={possitionIcn} alt="Duplicate"></img>
                                <span className="tooltip-text">Possition</span>
                              </button>
                            </div>

                            {isPossitionsLayers && (
                              <div
                                className="posstionLayer"
                                ref={PosstionLayer}
                              >
                                <div className="possitns2">
                                  <div className="row">
                                    <div className="PossitonDiv">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button onClick={bringLayerUp}>
                                          <img
                                            src={frontLayer}
                                            alt="Front Layer"
                                            className="me-2"
                                          />
                                          Layer Up
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button onClick={bringLayerDown}>
                                          <img
                                            src={backLayer}
                                            alt="Back Layer"
                                            className="me-2"
                                          />
                                          Layer Down
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="PossitonDiv">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button onClick={bringToFront}>
                                          <img
                                            src={frontLayer}
                                            alt="Front Layer"
                                            className="me-2"
                                          />
                                          Front Layer
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button onClick={sendToBack}>
                                          <img
                                            src={backLayer}
                                            alt="Back Layer"
                                            className="me-2"
                                          />
                                          Back Layer
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="AligmntDiv">
                                    <label className="ALigmttxt">
                                      Alignemnt
                                    </label>
                                    <div className="row mt-2">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button
                                          onClick={() =>
                                            handleAlignment("left")
                                          }
                                        >
                                          <img
                                            className="me-2"
                                            src={aligmentLeft}
                                            alt="img"
                                          />
                                          Left
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button
                                          onClick={() =>
                                            handleAlignment("right")
                                          }
                                        >
                                          {" "}
                                          <img
                                            className="me-2"
                                            src={aligmentRight}
                                            alt="img"
                                          />
                                          Right
                                        </button>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button
                                          onClick={() => handleAlignment("top")}
                                        >
                                          {" "}
                                          <img
                                            className="me-2"
                                            src={topALigment}
                                            alt="img"
                                          />
                                          Top
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button
                                          onClick={() =>
                                            handleAlignment("bottom")
                                          }
                                        >
                                          {" "}
                                          <img
                                            className="me-2 bottmAigmnt"
                                            src={topALigment}
                                            alt="img"
                                          />{" "}
                                          Bottom
                                        </button>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-6 d-flex justify-content-start">
                                        <button
                                          onClick={() =>
                                            handleAlignment("center")
                                          }
                                        >
                                          <img
                                            className="me-2"
                                            src={aligmntIcn}
                                            alt="img"
                                          />{" "}
                                          Center
                                        </button>
                                      </div>
                                      <div className="col-6 d-flex justify-content-end">
                                        <button
                                          onClick={() =>
                                            handleAlignment("middle")
                                          }
                                        >
                                          {" "}
                                          <img
                                            className="me-2"
                                            src={centerALigment}
                                            alt="img"
                                          />{" "}
                                          Middle
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={toggleOpacityInput}
                              >
                                <img src={OpeSityObjctIcn} alt="Opacity" />
                                <span className="tooltip-text">Opacity</span>
                              </button>
                            </div>
                            <div className="opesityFill">
                              {showOpacityInput && (
                                <div ref={opacityInputRef}>
                                  <div className="OppSItyyInput">
                                    <input
                                      type="range"
                                      min="0"
                                      max="100"
                                      value={opacity}
                                      onChange={handleOpacityChange}
                                    />
                                    <p>{opacity}%</p>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={duplicateObject}
                              >
                                <img
                                  src={objectDuplicateIcn}
                                  alt="Duplicate"
                                ></img>
                                <span className="tooltip-text">Duplicate</span>
                              </button>
                            </div>
                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={handleDeleteClick}
                              >
                                <img src={objectDltIcn} alt="Delete"></img>
                                <span className="tooltip-text">Delete</span>
                              </button>
                            </div>

                            {isbgcolorshape && (
                              <div className="aligmentIcn">
                                <button
                                  className="tooltip-button"
                                  onClick={handleSameAsbg}
                                >
                                  <img
                                    src={isRightImg ? markRght : questionMark}
                                    alt="Toggle Icon"
                                  />
                                  <span className="tooltip-SameAsbg">
                                    Same As Background
                                  </span>
                                </button>
                              </div>
                            )}
                            {/* <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={handleButtonClickCheckIcon}
                                disabled={isButtonDisabled}
                                style={{ opacity: isButtonDisabled ? 0.5 : 1 }}
                              >
                                <img
                                  src={
                                    sessionStorage.getItem("checkedIconId") ===
                                    selectedIconId
                                      ? markRght
                                      : questionMark
                                  }
                                  alt="Toggle"
                                />
                                <span className="tooltip-text">
                                  {isButtonDisabled
                                    ? "uncheck previus icon"
                                    : sessionStorage.getItem(
                                        "checkedIconId"
                                      ) === selectedIconId
                                    ? "Checked"
                                    : "ICON"}
                                </span>
                              </button>
                            </div> */}
                          </div>
                        )}
                      </div>
                      {/*Group ToollBar*/}
                      <div className="TextEdittoolBar2">
                        {isGroupObj && (
                          <div className="topTxtEditToolBar2">
                            <div className="aligmentIcn">
                              <button
                                onClick={() => handleAlignmentGroupUngroup()}
                              >
                                {GroupUngroup}
                              </button>
                            </div>
                            <div className="aligmentIcn">
                              <button
                                onClick={() => handleAlignment("left", "group")}
                              >
                                <img
                                  className="me-2"
                                  src={aligmentLeft}
                                  alt="img"
                                />
                                Left
                              </button>
                            </div>

                            <div className="aligmentIcn">
                              <button
                                onClick={() =>
                                  handleAlignment("right", "group")
                                }
                              >
                                {" "}
                                <img
                                  className="me-2"
                                  src={aligmentRight}
                                  alt="img"
                                />
                                Right
                              </button>
                            </div>

                            <div className="aligmentIcn">
                              <button
                                onClick={() =>
                                  handleAlignment("center", "group")
                                }
                              >
                                <img
                                  className="me-2"
                                  src={aligmntIcn}
                                  alt="img"
                                />{" "}
                                Center
                              </button>
                            </div>

                            <div className="aligmentIcn">
                              <button
                                onClick={() =>
                                  handleAlignment("middle", "group")
                                }
                              >
                                {" "}
                                <img
                                  className="me-2"
                                  src={centerALigment}
                                  alt="img"
                                />{" "}
                                Middle
                              </button>
                            </div>

                            {GroupUngroup === "Group" && (
                              <>
                                <div className="aligmentIcn">
                                  <button
                                    onClick={() =>
                                      handleAlignment("top", "group")
                                    }
                                  >
                                    {" "}
                                    <img
                                      className="me-2"
                                      src={centerALigment}
                                      alt="img"
                                    />{" "}
                                    Top
                                  </button>
                                </div>

                                <div className="aligmentIcn">
                                  <button
                                    onClick={() =>
                                      handleAlignment("bottom", "group")
                                    }
                                  >
                                    {" "}
                                    <img
                                      className="me-2"
                                      src={centerALigment}
                                      alt="img"
                                    />{" "}
                                    Bottom
                                  </button>
                                </div>
                              </>
                            )}

                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={toggleOpacityInput}
                              >
                                <img src={OpeSityObjctIcn} alt="Opacity" />
                                <span className="tooltip-text">Opacity</span>
                              </button>
                            </div>
                            <div className="opesityFill">
                              {showOpacityInput && (
                                <div ref={opacityInputRef}>
                                  <div className="OppSItyyInput">
                                    <input
                                      type="range"
                                      min="0"
                                      max="100"
                                      value={opacity}
                                      onChange={(event) =>
                                        handleOpacityChange(event, "group")
                                      }
                                    />
                                    <p>{opacity}%</p>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="aligmentIcn">
                              <button
                                className="tooltip-button"
                                onClick={handleDeleteClick}
                              >
                                <img src={objectDltIcn} alt="Delete"></img>
                                <span className="tooltip-text">Delete</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {isCropMode && (
                    <div className="cropBtnSaveCencel">
                      <button onClick={handleSaveCrop}>
                        <img src={rightmakImg}></img>Save
                      </button>
                      <button onClick={handleCancelCrop}>
                        <img src={crossIconModa}></img>Cencel
                      </button>
                    </div>
                  )}
                  {!isTextToolVisible && !isObjectSelected && !isGroupObj && (
                    <label className="editAreraText">
                      Click on any object to customize its properties or
                      double-click to effortlessly edit text.
                    </label>
                  )}
                </div>
              </div>

              <div className={containerClassName} style={zoomStyle}>
                <div className="MaineditorCanvas">
                  <canvas ref={mainCanvasRef}></canvas>
                </div>
              </div>

              <div className="zoomingCanvasContainer">
                <div className="ButtnsZoom">
                  <button onClick={handleZoomOut}>-</button>
                  <p>{zoomLevel}%</p>
                  <button onClick={handleZoomIn}>+</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*----*/}

      <div className="Templatereplace">
        <Modal
          className="Templatereplace"
          show={confirmModalVisible}
          onHide={() => setConfirmModalVisible(false)}
          centered
        >
          <Modal.Body>
            <p>Are you sure you want to replace the current template?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setConfirmModalVisible(false)}
            >
              No
            </Button>
            <Button variant="primary" onClick={handleConfirmReplace}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="Templatereplace">
        <Modal
          className="Templatereplace"
          show={ShowNext}
          onHide={() => setShowNext(false)}
          centered
        >
          <Modal.Body>
            <div className="modalHead mb-2">
              <div className="backgroundColorOptions">
                <div className="ColorTxt">
                  <p className="p-0" style={{ fontSize: "16px" }}>
                    Choose Logo Colors Varient
                  </p>
                </div>
                <div className="color_grid">
                  {colorItems.map((item, colorIndex) => (
                    <div
                      key={colorIndex}
                      className={`colorItem ${item.className}`}
                      style={{
                        position: "relative",
                      }}
                      onClick={() => handleColorClick(colorIndex)}
                    >
                      {/* {selectedColorIndex === colorIndex && ( */}
                      <div className={`tickmardis tickMark${colorIndex}`}>
                        <img src={tickmark} alt="tick" />
                      </div>
                      {/* )} */}
                      <div className="colorGrade1"></div>
                      <div className="colorGrade2"></div>
                      <div className="colorGrade3"></div>
                      <div className="colorGrade4"></div>
                      <div className="colorGrade5"></div>
                      <div className="colorGrade6"></div>
                      <div className="colorGrade7"></div>
                      <div className="colorGrade8"></div>
                      <div className="colorGrade9"></div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="btnClass">
                <button
                  onClick={handlePrevClick}
                  className={`${
                    selectedColorIndex === 0 ? "disableee" : ""
                  } prevcls`}
                >
                  Prev
                </button>
                <button onClick={handleNextClick} className={`nextcls`}>
                  Next
                </button>
                <button
                  onClick={saveCanvasAsJSON}
                  className={`savehidn saveshow`}
                >
                  Save
                </button>
              </div>
            </div>

            {showLoaderAdmin ? (
              <div>Loading...</div>
            ) : (
              <div>
                {colorItems.map((item, colorIndex) => (
                  <div
                    className={`row m-0 colordivcls colordiv${colorIndex}`}
                    key={`${colorIndex}`}
                  >
                    {logos && logos.length > 0 ? (
                      <div className="row m-0" key={colorIndex}>
                        {logos[colorIndex].map((logoData, index) => (
                          <div
                            className={`col-xl-3 col-md-4 col-sm-6 mb-4 choicecanvas${logoData.index} removeextradiv${colorIndex}`}
                            ref={colRef}
                            key={logoData.index}
                          >
                            <div
                              className={`grid-item girdLogo_item w-100 selectedLgotest${colorIndex} selectedLgo${logoData.index}_${colorIndex}`}
                              keyattr={`selectedLgo${logoData.index}_${colorIndex}`}
                              onClick={() =>
                                handleLogoClick(logoData.index, colorIndex)
                              }
                            >
                              <canvas
                                ref={(ref) => {
                                  createCanvas(
                                    ref,
                                    CanvasColorVariant,
                                    "variants",
                                    logoData.index,
                                    logoData,
                                    colorIndex
                                  );
                                }}
                              />
                            </div>
                          </div>
                        ))}

                        <div
                          className={`col-xl-3 col-md-4 col-sm-6 mb-4 choicecanvasdiff choicecanvas_${colorIndex}_rm`}
                          ref={colRef}
                          key="0"
                        >
                          <div
                            className={`grid-item girdLogo_item w-100 selectedLgotest${colorIndex}_rm selectedLgo0_${colorIndex}_rm`}
                            keyattr={`selectedLgo0_${colorIndex}`}
                          >
                            <canvas
                              ref={(ref) => {
                                createCanvas(
                                  ref,
                                  CanvasColorVariant,
                                  "variants",
                                  0,
                                  "0",
                                  colorIndex
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="w-100 d-flex justify-content-center mt-4">
                        No logos available
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>

      {/*----FONT FAMILY UPLOAD */}

      <Modal
        className="FontFamilyUpload"
        show={showFontModal}
        onHide={() => setshowFontModal(false)}
        centered
      >
        <Modal.Body>
          {validationMessage && (
            <Alert variant="danger">{validationMessage}</Alert>
          )}
          <Form>
            <Form.Group controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                name="category"
                id="category"
                placeholder="Enter category"
                value={category}
                onChange={handleFontCategoryChange}
              />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Choose File</Form.Label>
              <Form.Control
                type="file"
                accept=".ttf,.otf"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshowFontModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUploadValidation}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      {/**industrY Modal  */}
      {/* <Modal
        show={industryModal}
        size="lg"
        onHide={handleIndustryCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Industry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="createRightsForm" id="createRightsForm">
            <div className="row m-0">
              <div className="personalForm">
                <div className="row m-0   IndustrDataaaa">
                  <div className="col-md-5">
                    <div className="inpContainer">
                      <label style={{ color: "#666666" }}>
                        Industry
                        <span>*</span>
                      </label>
                      <div>
                        <Select
                          styles={customStyles2}
                          options={industryData}
                          onChange={(e) =>
                            handleChangeindustryrelated_data(e, 0)
                          }
                          value={
                            dropdowns && dropdowns[0]
                              ? dropdowns[0].selectedIndustry
                              : null
                          } 
                          className={`form-control industry_input inpageinput`}
                          placeholder="Industry"
                          isSearchable
                          components={{ ValueContainer: CustomValueContainer }}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="inpContainer">
                      <label style={{ color: "#666666" }}>
                        Categories
                        <span>*</span>
                      </label>
                      <div>
                        <Select
                          isMulti
                          inputValue={inputValue[0]} 
                          onInputChange={(newValue) =>
                            handleInputChange(newValue, 0)
                          } 
                          className={`trio_mandatory`}
                          onKeyDown={(event) => handleKeyDown2(event, 0)}
                          onChange={(selectedOptions) =>
                            setDropdowns((prevState) => {
                              const updatedDropdowns = [...prevState];
                              updatedDropdowns[0].selectedCategories =
                                selectedOptions;
                              return updatedDropdowns;
                            })
                          }
                          placeholder="Related Industry"
                          value={
                            dropdowns && dropdowns[0]?.selectedCategories
                              ? dropdowns[0].selectedCategories
                              : []
                          }
                          closeMenuOnSelect={false}
                          menuIsOpen={false}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            MenuList: CustomMenuList,
                          }}
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="addBUtton">
                      <button
                        type="button"
                        onClick={() => handleAddNewIndustry(0)}
                      >
                        <img
                          src={isAdding ? MinusIcon : PlusIconAdd}
                          alt="Toggle"
                        />
                      </button>
                    </div>
                  </div>

                  {newIndustries.map((_, index) => (
                    <React.Fragment key={index}>
                      <div className="col-md-5 mt-3">
                        <div className="inpContainer">
                          <label style={{ color: "#666666" }}>
                            New Industry
                            <span>*</span>
                          </label>
                          <div>
                            <Select
                              styles={customStyles2}
                              options={industryData}
                              onChange={(selected) =>
                                handleChangeindustryrelated_data(
                                  selected,
                                  index + 1
                                )
                              }
                              value={
                                dropdowns && dropdowns[index + 1]
                                  ? dropdowns[index + 1].selectedIndustry
                                  : null
                              } // Handle undefined gracefully
                              className={`form-control industry_input inpageinput`}
                              placeholder="Industry"
                              isSearchable
                              components={{
                                ValueContainer: CustomValueContainer,
                              }}
                            />
                            <span className="condition_error"></span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 mt-3">
                        <div className="inpContainer">
                          <label style={{ color: "#666666" }}>
                            Categories
                            <span>*</span>
                          </label>
                          <div>
                            <Select
                              isMulti
                              inputValue={inputValue[index + 1]}
                              onInputChange={(e) =>
                                handleInputChange[(e, index + 1)]
                              }
                              className={`trio_mandatory`}
                              onKeyDown={(event) =>
                                handleKeyDown2(event, index + 1)
                              }
                              onChange={setDropdowns}
                              placeholder="Related Industry"
                              value={
                                dropdowns &&
                                dropdowns[index + 1].selectedCategories
                                  ? dropdowns[index + 1].selectedCategories
                                  : []
                              }
                              closeMenuOnSelect={false}
                              menuIsOpen={false}
                              components={{
                                DropdownIndicator: false,
                                IndicatorSeparator: false,
                                MenuList: CustomMenuList,
                              }}
                            />
                            <span className="condition_error"></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 ">
                        <div className="addBUtton   seconAddd">
                          <button type="button" onClick={handleAddNewIndustry}>
                            <img src={PlusIconAdd} alt="PluseIcon"></img>
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className="createRightBtnDiv"></div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>

          <Button
            className="createBtn"
            variant="primary"
            onClick={() =>
              handleSaveChangesdynamic("createRightsForm", save_update_industry)
            }
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal> */}
      {/**----Add Industry ---- */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>New Label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="createRightsForm" id="createRightsForm">
            <div className="row m-0">
              <div className="personalForm">
                <div className="row m-0">
                  <div className={`col-md-8`}>
                    <div className="inpContainer">
                      <label style={{ color: "#666666" }}>
                        label name:<span>*</span>
                      </label>
                      <div>
                        <Select
                          styles={customStyles2}
                          options={industryData}
                          onChange={handleIndustrySelect} // Handle selection
                          value={selectedIndustries} // Ensure value is bound to selectedIndustries
                          isMulti
                          className={`form-control industry_input inpageinput`}
                          placeholder="Industry"
                          isSearchable
                        />
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createRightBtnDiv"></div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="createBtn"
            variant="primary"
            onClick={handleIndustryCloseModal} // Update with correct arguments
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="confirmationChangeModal"
        centered
        show={isModalVisible}
        onHide={handleCancel}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your changes will be lost if you change this. Are you sure you want to
          proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CanvasEditor;