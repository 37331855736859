import React, { useState, useEffect } from "react";
import OpenMenu from "../assets/sidebarIconBlue.png";
import Dashboard from "../assets/sideDashblack.svg";

import Guest from "../assets/sideGuestBlack.svg";
import Analytics from "../assets/sideAnalytisBlack.svg";
import Staff from "../assets/sideStaffBlack.svg";
import Website from "../assets/sideWebBlack.svg";

import HelpSupport from "../assets/sideHelpBlack.svg";
import Setting from "../assets/sideSettingBlack.svg";
import TestiBlackicone from "../assets/testiblack.svg";
import IndustryBlack from "../assets/industryIcnBLack.svg";
import DashboardOrng from "../assets/sideDashOrng.svg";
import TemplateOrng from "../assets/templeBlue.svg";
import TemplateBlck from "../assets/templateBlackIcn.svg";
import userIconBack from "../assets/userprofileIconBlackkkk.svg";
import userIconBlue from "../assets/userprofileIconBlueee.svg";

import GuestOrng from "../assets/sideGuestOrng.svg";
import TestiNo from "../assets/testiNo.svg";
import IndustryIcn from "../assets/industryIcn.svg";
import AnalyticsOrng from "../assets/sideAnalyticsBlue.svg";
import StaffOrng from "../assets/sideStaffOrng.svg";
import WebsiteOrng from "../assets/sideWebOrng.svg";

import HelpSupportOrng from "../assets/sideHelpBlue.svg";
import SettingOrng from "../assets/sideSettingOrng.svg";
import master from "../assets/master.svg";
import masterBlue from "../assets/masterBlue.svg";
import NewLogo from "../assets/LogoAcademyLogo.svg";

import { sidebarpagetext, Hide_Menu_text } from "./../CommonJquery/WebsiteText";
import { Link, useLocation } from "react-router-dom";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import {
  server_post_data,
  get_all_StaffRights,
} from "../ServiceConnection/serviceconnection.js";
function SideBar({ isSidebarOpen, toggleSidebar }) {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const access_permission = retrieveData("access_permission");
  const [page_chekt, setPage_chekt] = useState([]);
  const [rightpage, setrightpage] = useState([]);
  const [designationpage, setDesignationPage] = useState([]);
  useEffect(() => {
    master_data_get_staff_right();
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /******************* this useEffect Staff Right Activity Start****************/
  useEffect(() => {
    //console.log(page_chekt);
    //console.log(designationpage);
    var currentPage = window.location.pathname.split("/").pop();
    var rrr = Triosidebarhideshow(currentPage);
    if (rrr) {
      goback();
    }

    if (page_chekt[currentPage] && page_chekt[currentPage]["insidepage"]) {
      page_chekt[currentPage]["insidepage"].split("/").forEach(function (item) {
        console.log(item);
        const insidePages_vw = item.split("@");
        if (
          designationpage[currentPage] &&
          Object.values(designationpage[currentPage]).includes(
            insidePages_vw[0]
          )
        ) {
          // item is present in the designation so no need to remove
        } else {
          var elementsToRemove = document.querySelectorAll(
            "." + insidePages_vw[0]
          );
          elementsToRemove.forEach(function (element) {
            element.remove(); //remove buttons
          });
        }
      });
    }

    Object.keys(page_chekt).forEach(function (key) {
      var itempi = page_chekt[key].page_url_id;
      if (!designationpage[itempi]) {
        document.querySelectorAll(`li.${itempi}`).forEach(function (element) {
          element.remove();
        });
        document
          .querySelectorAll(`[href="/${itempi}"]`)
          .forEach(function (element) {
            element.remove();
          });
      }
    });
  }, [page_chekt]);

  /******************* this useEffect Staff Right Activity End ****************/

  const master_data_get_staff_right = async () => {
    const fd = new FormData();
    fd.append("flag", "10");
    await server_post_data(get_all_StaffRights, fd)
      .then((Response) => {
        // Assuming Response.data is an array of objects with 'page_url' as key
        Response.data.message.data.forEach(function (item) {
          var idd = item.page_url_id;
          setPage_chekt((prevState) => ({
            ...prevState,
            [idd]: {
              page_url_id: item.page_url_id,
              insidepage: item.insidepage,
            },
          }));
        });
        // Now you have page_chekt object in JavaScript

        const datam = access_permission.split("/");
        const newDataxt = datam.reduce((acc, item, index) => {
          if (item.includes("@")) {
            const [page, ...extras] = item.split("@");
            if (!acc[page]) {
              acc[page] = {};
            }
            extras.forEach((extra, idx) => {
              acc[page][idx] = extra;
            });
          } else {
            acc[item] = { ["class_list"]: "" };
          }
          return acc;
        }, {});
        setDesignationPage(newDataxt);

        if (Response.data.error) {
          //handleError(Response.data.message);
        } else {
          setrightpage(Response.data.message.data);
        }
      })
      .catch((error) => {});
  };

  function Triosidebarhideshow(pagelink) {
    if (designationpage[pagelink]) {
      return ""; // User has access to this page
    } else {
      if (!page_chekt[pagelink]) {
        return ""; // No user right for this page
      } else {
        return "dsplynoe"; // User has no right to access this page
      }
    }
  }

  /********* Current Page redirect Start**********/
  function goback() {
    window.history.back();
  }
  /********* Current Page redirect Close**********/
  return (
    <div className={`sidebar ${isSidebarOpen ? "OpneSidebar" : ""}`}>
      <div className="sidebar_container">
        <div className="sideHeadLogo">
          <a className="navbar-brand" href="#">
            <div className="headerLogo">
              <div className="headerLogo_contaienr">
                <img src={NewLogo} alt="Barley's Dashboard" />
              </div>
            </div>
          </a>
        </div>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          htmlFor="top"
          title={`Hide`}
          className="menuOpenClose"
          onClick={toggleSidebar}
          style={{
            paddingLeft: "0",
            marginLeft: "2rem",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ transform: " rotate(0deg)" }}
            src={OpenMenu}
            alt="Barley's Dashboard"
          />
        </div>
        <div className="sidebar_list">
          <ul>
            <Link to="/dashboard">
              <li className="analytics">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/dashboard" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/dashboard"
                        ? DashboardOrng
                        : Dashboard
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Dashborard_text}</p>
                </div>
              </li>
            </Link>
            <Link to="/sales">
              <li className="analytics">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/sales" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/sales" ? AnalyticsOrng : Analytics
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Analytics_text}</p>
                </div>
              </li>
            </Link>
            <Link to="/view_templates">
              <li className="analytics">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_templates"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_templates"
                        ? TemplateOrng
                        : TemplateBlck
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Templatetxt}</p>
                </div>
              </li>
            </Link>

            <Link to="/View_enquiry">
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Guest_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_enquiry"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_enquiry" ? GuestOrng : Guest
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Guest_Mgmt_text}</p>
                </div>
              </li>
            </Link>
            <Link to="/view_industry">
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Guest_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_industry"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_industry"
                        ? IndustryIcn
                        : IndustryBlack
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Industry_text}</p>
                </div>
              </li>
            </Link>
            <Link to="/View_testestimonial">
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Guest_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_testestimonial" ||
                    location.pathname === "/add_testestimonial" ||
                    location.pathname.includes("/edit_testestimonial")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_testestimonial" ||
                      location.pathname === "/add_testestimonial" ||
                      location.pathname.includes("/edit_testestimonial")
                        ? TestiNo
                        : TestiBlackicone
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.TestiTxt}</p>
                </div>
              </li>
            </Link>
            <Link to="/view_package">
              <li
                className={`guest_mngmt ${Triosidebarhideshow(
                  "Guest_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_package"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_package" ? StaffOrng : Staff
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>Package</p>
                </div>
              </li>
            </Link>

            {/* <Link to="/view_sTaff">
              <li className="system_settings">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Staff" ||
                    location.pathname === "/Add_Staff" ||
                    location.pathname === "/View_Staff_Rights" ||
                    location.pathname === "/View_Sub_Rights" ||
                    location.pathname === "/View_Inside_Page_Rights" ||
                    location.pathname === "/ViewSearchMaster" ||
                    location.pathname === "/Add_SearchMaster" ||
                    location.pathname === "/Create_Staff_Rights" ||
                    location.pathname === "/Edit_Staff_Rights" ||
                    location.pathname === "/Edit_Sub_Rights" ||
                    location.pathname === "/Create_Inside_Page_Rights" ||
                    location.pathname === "/ViewDesignation" ||
                    location.pathname.includes("/edit_staff") ||
                    location.pathname.includes("/Edit_SearchMaster") ||
                    location.pathname.includes("/Edit_Designation")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Staff" ||
                      location.pathname === "/Add_Staff" ||
                      location.pathname === "/View_Staff_Rights" ||
                      location.pathname === "/View_Sub_Rights" ||
                      location.pathname === "/View_Inside_Page_Rights" ||
                      location.pathname === "/ViewSearchMaster" ||
                      location.pathname === "/Add_SearchMaster" ||
                      location.pathname === "/Create_Staff_Rights" ||
                      location.pathname === "/Edit_Staff_Rights" ||
                      location.pathname === "/Edit_Sub_Rights" ||
                      location.pathname === "/Create_Inside_Page_Rights" ||
                      location.pathname === "/ViewDesignation" ||
                      location.pathname.includes("/edit_staff") ||
                      location.pathname.includes("/Edit_SearchMaster") ||
                      location.pathname.includes("/Edit_Designation")
                        ? SettingOrng
                        : Setting
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Setting_text}</p>
                </div>
              </li>
            </Link> */}
            <Link to="/view_sTaff">
              <li
                className={`website_mngmt ${Triosidebarhideshow(
                  "Website_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_sTaff" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_sTaff"
                        ? SettingOrng
                        : Setting
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>Staff</p>
                </div>
              </li>
            </Link>
            <Link to="/Website_Management">
              <li
                className={`website_mngmt ${Triosidebarhideshow(
                  "Website_Management"
                )}`}
              >
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Website_Management"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Website_Management"
                        ? WebsiteOrng
                        : Website
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Website_Mngmt}</p>
                </div>
              </li>
            </Link>

            <Link to="/user_management">
              <li className="Manuals">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/user_management"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/user_management"
                        ? userIconBlue
                        : userIconBack
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.userManagemnt}</p>
                </div>
              </li>
            </Link>
            <Link to="/View_master">
              <li className="Manuals">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_master"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_master" ? masterBlue : master
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.master_text}</p>
                </div>
              </li>
            </Link>
            <Link to="/View_Faq">
              <li className="Manuals">
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Faq" ||
                    location.pathname === "/Help_And_Support" ||
                    location.pathname === "/FAQs" ||
                    location.pathname === "/View_Faq" ||
                    location.pathname === "/Add_Faq" ||
                    location.pathname.includes("/Edit_Faq")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Help_And_Support" ||
                      location.pathname === "/FAQs" ||
                      location.pathname === "/View_Faq" ||
                      location.pathname === "/Add_Faq" ||
                      location.pathname.includes("/Edit_Faq")
                        ? HelpSupportOrng
                        : HelpSupport
                    }
                    alt="Barley's Dashboard"
                  />
                  <p>{sidebarpagetext.Help_Support_text}</p>
                </div>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
