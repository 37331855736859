import React, {
  useEffect,
  useState,
  useRef,
  Component,
  useCallback,
} from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";

import dropdown from "../assets/arrow_drop_down_24px.svg";
import DownloadImg from "../assets/print.svg";
import Chart from "react-apexcharts";
import { CardImgOverlay, Pagination } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import {
  server_post_data,
  get_all_analyticsReservation,
  get_users_with_packages,
  classify_users,
  calculate_revenue_and_sales,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  computeplussevendays,
  check_vaild_save,
  handleIaphabetnumberChange,
  formatDateStringdot,
} from "../CommonJquery/CommonJquery.js";
import { OrdersManagementPageText } from "../CommonJquery/WebsiteText.js";
import html2canvas from "html2canvas";
import {
  options_select_drop_feedback,
  Analytics_text,
} from "../CommonJquery/WebsiteText.js";
import orangeSales from "../assets/orangeSales.svg";
import redSales from "../assets/redSales.svg";
import purpleSales from "../assets/purpleSales.svg";
import "./Css/Dashboard.css";

// logoacdemy dashboard imports
import { CanvasJSChart } from "canvasjs-react-charts";
import ReactApexChart from "react-apexcharts";
function Dashboard() {
  const [SelectedData, setSelectedData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [FirstTimeData, setFirstTimeData] = useState(false);
  const [customDateActive, setCustomDateActive] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(options_select_drop_feedback[0]);
  const [series, setseries] = useState([0, 0, 0]);
  const [filteredData, setfilteredData] = useState([]);
  const [filteredDataSales, setfilteredDataSales] = useState([]);
  const [filteredDataRevenue, setfilteredDataRevenue] = useState([]);
  const [filteredDataWiseRevenue, setfilteredDataWiseRevenue] = useState([]);
  const [datesArray, setdatesArray] = useState([]);

  const [filteredDataUsers, setfilteredDataUsers] = useState([]);

  const [SalesData, settotalSalesData] = useState();
  const [Statics, setStatics] = useState([]);
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [RupeesLeft, setsRupeesLeft] = useState("");
  const [RupeesRight, setsRupeesRight] = useState("");

  // code by shubham jain for custom date functionality
  useEffect(() => {
    // master_data_get(selected.value);
  }, []);

  // const master_data_get = async (select_type) => {
  //   let start_date = "";
  //   let end_date = "";
  //   setshowLoaderAdmin(true);
  //   const fd = new FormData();
  //   const current_date = new Date(); // Initialize current_date with the current date
  //   if (select_type === "today") {
  //     start_date = start_date_fn(current_date);
  //     end_date = end_date_fn(current_date);
  //   } else if (select_type === "last_7_days") {
  //     const sevenDaysAgo = new Date();
  //     sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  //     start_date = start_date_fn(sevenDaysAgo);
  //     end_date = end_date_fn(current_date);
  //   } else if (select_type === "this_month") {
  //     const firstDayOfMonth = new Date(
  //       current_date.getFullYear(),
  //       current_date.getMonth(),
  //       1
  //     );
  //     const lastDayOfMonth = new Date(
  //       current_date.getFullYear(),
  //       current_date.getMonth() + 1,
  //       0
  //     );
  //     start_date = start_date_fn(firstDayOfMonth);
  //     end_date = end_date_fn(lastDayOfMonth);
  //   } else if (select_type === "last_month") {
  //     const firstDayOfLastMonth = new Date(
  //       current_date.getFullYear(),
  //       current_date.getMonth() - 1,
  //       1
  //     );
  //     const lastDayOfLastMonth = new Date(
  //       current_date.getFullYear(),
  //       current_date.getMonth(),
  //       0
  //     );
  //     start_date = start_date_fn(firstDayOfLastMonth);
  //     end_date = end_date_fn(lastDayOfLastMonth);
  //   } else if (select_type === "this_year") {
  //     const firstDayOfYear = new Date(current_date.getFullYear(), 0, 1);
  //     start_date = start_date_fn(firstDayOfYear);
  //     end_date = end_date_fn(current_date);
  //   }

  //   function start_date_fn(start_date) {
  //     // Formatting start date
  //     const start_year = start_date.getFullYear();
  //     const start_month = (start_date.getMonth() + 1)
  //       .toString()
  //       .padStart(2, "0");
  //     const start_day = start_date.getDate().toString().padStart(2, "0");
  //     return `${start_year}-${start_month}-${start_day}`;
  //   }

  //   function end_date_fn(end_date) {
  //     // Formatting end date
  //     const end_year = end_date.getFullYear();
  //     const end_month = (end_date.getMonth() + 1).toString().padStart(2, "0");
  //     const end_day = end_date.getDate().toString().padStart(2, "0");
  //     return `${end_year}-${end_month}-${end_day}`;
  //   }
  //   fd.append("start_date", start_date);
  //   fd.append("end_date", end_date);
  //   fd.append("select_type", select_type);
  //   await server_post_data(get_all_analyticsReservation, fd)
  //     .then((Response) => {
  //       if (Response.data.error) {
  //         handleError(Response.data.message);
  //       } else {
  //         setfilteredData(Response.data.message);
  //         setsRupeesLeft(Response.data.message.RUPEES_ICON_LEFT);
  //         setsRupeesRight(Response.data.message.RUPEES_ICON_RIGHT);
  //         setfilteredData(Response.data.message);
  //         setseries([
  //           Response.data.message.count_carting_web,
  //           Response.data.message.count_carting_app,
  //         ]);

  //         const barGraph = Response.data.message.view_data.map((posnegObj) => {
  //           let haveApprovalPendingData = 0;
  //           let haveArrivedData = 0;
  //           let haveCompletedData = 0;
  //           let haveCancelledData = 0;

  //           let totalApprovalPending = 0;
  //           let totalArrived = 0;
  //           let totalCompleted = 0;
  //           let totalCancelled = 0;

  //           Response.data.message.reservations.forEach((subObj) => {
  //             const trimmedEntryDate = String(subObj.month_or_date).replace(
  //               /^0+/,
  //               ""
  //             );
  //             const trimmedToCheck = posnegObj.tocheck.replace(/^0+/, "");
  //             if (
  //               String(trimmedEntryDate) === String(trimmedToCheck) &&
  //               String(posnegObj.tocheck_year) === String(subObj.month_year)
  //             ) {
  //               if (subObj.booking_status_name === "Pending") {
  //                 totalApprovalPending = subObj.total_count;
  //                 haveApprovalPendingData = 1;
  //               }
  //               if (subObj.booking_status_name === "Arrived") {
  //                 totalArrived = subObj.total_count;
  //                 haveArrivedData = 1;
  //               }
  //               if (subObj.booking_status_name === "Completed") {
  //                 totalCompleted = subObj.total_count;
  //                 haveCompletedData = 1;
  //               }
  //               if (subObj.booking_status_name === "Cancelled") {
  //                 totalCancelled = subObj.total_count;
  //                 haveCancelledData = 1;
  //               }
  //             }
  //           });

  //           return {
  //             total_approval_pending: haveApprovalPendingData
  //               ? totalApprovalPending
  //               : 0,
  //             total_arrived: haveArrivedData ? totalArrived : 0,
  //             total_completed: haveCompletedData ? totalCompleted : 0,
  //             total_cancelled: haveCancelledData ? totalCancelled : 0,
  //           };
  //         });
  //         const totalApprovalPendingArray = barGraph.map(
  //           (item) => item.total_approval_pending
  //         );
  //         const totalArrivedArray = barGraph.map((item) => item.total_arrived);
  //         const totalCompletedArray = barGraph.map(
  //           (item) => item.total_completed
  //         );
  //         const totalCancelledArray = barGraph.map(
  //           (item) => item.total_cancelled
  //         );

  //         const aryList = {
  //           totalPending: totalApprovalPendingArray,
  //           totalArrived: totalArrivedArray,
  //           totalCompleted: totalCompletedArray,
  //           totalCancelled: totalCancelledArray,
  //         };
  //         setStatics(aryList);
  //       }
  //       setshowLoaderAdmin(false);
  //       setFirstTimeData(false);
  //     })
  //     .catch((error) => {
  //       console.log("1", error);
  //       handleError("network");
  //       setshowLoaderAdmin(false);
  //     });
  // };

  // const search_data = () => {
  //   master_data_get(selected.value);
  // };

  const master_data_get = async () => {
    console.log("Hello");
    setshowLoaderAdmin(true);
    await server_post_data(get_users_with_packages, null)
      .then((Response) => {
        console.log(Response.data.message.total_sales);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfilteredDataSales(Response.data.message.data);
          // settotalSalesData(Response.data.message.total_sales);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_get_user_classification = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", "2024-08-01");
    fd.append("end_date", "2024-09-01");
    await server_post_data(classify_users, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setfilteredDataUsers(Response.data.message);
          // settotalSalesData(Response.data.message.total_sales);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_get_revenue_sales = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", "2024-08-03");
    fd.append("end_date", "2024-09-02");
    await server_post_data(calculate_revenue_and_sales, fd)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const dateWiseRevenue =
            Response.data.message.date_wise_revenue.revenue;

          // Initialize empty arrays for dates and revenue
          const datesArray = [];
          const revenueArray = [];

          // Check if date_wise_revenue is defined
          if (dateWiseRevenue) {
            for (const [date, revenue] of Object.entries(dateWiseRevenue)) {
              datesArray.push(date); // Add the date to the array
              revenueArray.push(revenue); // Add the corresponding revenue
            }
          }
          setdatesArray(datesArray);
          setfilteredDataRevenue(Response.data.message);
          setfilteredDataWiseRevenue(Response.data.message.date_wise_revenue);

          // settotalSalesData(Response.data.message.total_sales);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
    master_data_get_user_classification();
    master_data_get_revenue_sales();
  }, []);

  const select_dropdown = (itemsdata) => {
    setIsSelected(itemsdata);
    setIsActive(!isActive);

    if (itemsdata.datepicker_show) {
      setCustomDateActive(true);
    } else {
      setCustomDateActive(false);
      // master_data_get(itemsdata.value);
    }
  };

  // code by shubham jain for custom date functionality

  const optionsRadial2 = {
    chart: {
      type: "donut",
    },
    labels: ["Web Covers", "App Covers"],
    colors: ["#007bff", "#509D30"],
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const seriesLine = [
    {
      name: "Loyal Customers",
      data: [280, 290, 335, 364, 332, 322, 331],
    },
    {
      name: "Unique Customers",
      data: [120, 340, 194, 186, 177, 183, 103],
    },
    {
      name: "New Customers",
      data: [100, 107, 210, 300, 250, 150, 255],
    },
  ];

  const optionsLine = {
    chart: {
      height: 200,
      type: "line",
      zoom: {
        enabled: false, // Disable zooming
      },
      toolbar: {
        show: false, // Hide toolbar
      },
    },
    stroke: {
      curve: "smooth", // Smoothened curve for the line
    },
    yaxis: {
      categories: ["0", "100", "200", "400", "500"],
    },
    xaxis: {
      categories: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },

    colors: [
      "rgba(167, 0, 255, 0.5) ",
      "rgba(2, 142, 134, 0.5)",
      "rgba(0, 26, 255, 0.5) ",
    ],
    markers: {
      size: 0,
      colors: [
        "rgba(167, 0, 255, 0.5) ",
        "rgba(2, 142, 134, 0.5)",
        "rgba(0, 26, 255, 0.5) ",
      ],
      strokeColors: "#fff",
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    grid: {
      show: false, // Hide grid lines
    },
    legend: {
      show: false, // Hide legends
    },
  };

  const tableRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const currentItems = filteredData
    .filter(
      (option) =>
        option.customer_details.length > 0 &&
        (option.customer_details[0].full_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
          option.customer_details[0].user_moblie_no
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          option.counter_invoice
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          option.entry_date.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (option.customer_details[0].email_id &&
            option.customer_details[0].email_id
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (option.customer_details[0].business_name &&
            option.customer_details[0].business_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase())))
    )
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPageCount = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          // onClick={() => paginate(number)}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log(filteredDataWiseRevenue);
  console.log(filteredDataUsers.stay_percentage);
  console.log(filteredDataUsers.bounce_rate);

  // order api data

  // const master_data_action_update = async (
  //   call_id,
  //   for_status_final,
  //   reject_text
  // ) => {
  //   setshowLoaderAdmin(true);
  //   const fd = new FormData();

  //   fd.append("id_for_delete", call_id);
  //   fd.append("for_status_final", for_status_final);
  //   fd.append("reject_order_remark", reject_text);
  //   await server_post_data(update_customer_order_status, fd)
  //     .then((Response) => {
  //       setshowLoaderAdmin(false);
  //       if (Response.data.error) {
  //         handleError(Response.data.message);
  //       } else {
  //         closeModal();
  //         master_data_get(selected.value);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("2", error);
  //       handleError("network");
  //       setshowLoaderAdmin(false);
  //     });
  // };

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index, flag) => {
    setSelectedData(guestName);
    setSelectedGuestIndex(flag);
    if (flag === 1) {
      setShowModal(true);
    } else if (flag === 2) {
      setShowModal1(true);
    } else if (flag === 3) {
      setShowModal2(true);
    } else if (flag === 4) {
      setShowModal3(true);
    }
  };

  const confirmVIP = (flag_click, form_name) => {
    let reject_text = "";
    if (flag_click === 2) {
      // master_data_action_update(
      //   SelectedData.primary_id,
      //   1,
      //   reject_text,
      //   flag_click
      // );
    } else if (flag_click === 3) {
      let vaild_data = check_vaild_save(form_name);

      if (vaild_data) {
        reject_text = document.getElementById("disc_msg").value;
        // master_data_action_update(
        //   SelectedData.primary_id,
        //   3,
        //   reject_text,
        //   flag_click
        // );
      }
    } else if (flag_click === 4) {
      // master_data_action_update(SelectedData.primary_id, 2, "", flag_click);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal1(false);
    setShowModal2(false);
    setShowModal3(false);
  };
  //  logo academy dashboard chart class starts

  // const datesArray = [];
  // const revenueArray = [];

  // for (const [date, revenue] of Object.entries(filteredDataWiseRevenue.revenue)) {
  //   datesArray.push(date);
  //   revenueArray.push(revenue);
  // }

  // console.log('Dates:', datesArray);
  // console.log('Revenue:', revenueArray);

  let salesData = [0, 15, 7, 20, 22, 16, 12];
  let revenueData = [0, 8, 12, 21, 19, 10, 5];
  let XaxisData =   [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  console.log(XaxisData);
  const [seriesBarGraph] = useState([
    {
      name: "Revenue",
      data: salesData,
    },
    {
      name: "Visitor",
      data: revenueData,
    },
  ]);

  console.log(datesArray);

  const [optionsBarGraph] = useState({
    chart: {
      type: "bar",
      height: 200,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: XaxisData,
    },
    yaxis: {
      categories: ["0", "5k", "10k", "15k", "20k", "25k", "30k"],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {},
    colors: ["rgba(128, 141, 255, 1)", "rgba(219, 159, 255, 1)"],
  });

  // Function to extract and map data

  // pie chart
  const [seriesPieChart, setSeriesPieChart] = useState([0, 0]);
  const [optionsPieChart] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    colors: ["rgba(219, 159, 255, 1)", "rgba(128, 141, 255, 1)"],
    labels: ["Stay%", "Bounce%"],
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    if (filteredDataUsers) {
      // Update seriesPieChart with actual data
      setSeriesPieChart([
        filteredDataUsers.stay_percentage || 0, // Fallback to 0 if data is missing
        filteredDataUsers.bounce_rate || 0, // Fallback to 0 if data is missing
      ]);
    }
  }, [filteredDataUsers]);

  // side bar graph for package sales
  const [seriesSidebar] = useState([
    {
      data: [258, 150, 5],
    },
  ]);

  const [optionsSidebar] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {},
    yaxis: {
      categories: ["0", "5k", "10k", "15k", "20k", "25k", "30k"],
    },
    colors: ["rgba(219, 159, 255, 1)"],
  });
  //  logo academy dashboard chart class ends

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  console.log(SelectedData.order_details);
  console.log("Ssfsfsf", SelectedData);

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="analyticsHead">
                <div className="analyticFIlter">
                  <div className="col-xl-10">
                    <div className="row m-0">
                      <div className="col-md-4">
                        <div className="dropdownCustom" ref={dropdownRef}>
                          <div
                            onClick={(e) => {
                              setIsActive(!isActive);
                            }}
                            className="dropdownCustom-btn"
                          >
                            {Analytics_text.Period_text}: {selected.label}
                            <span
                              className={
                                isActive
                                  ? "fas fa-caret-up"
                                  : "fas fa-caret-down"
                              }
                            >
                              <img src={dropdown} alt="Shopup Admin" />
                            </span>
                          </div>
                          <div
                            className="dropdownCustom-content"
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            {options_select_drop_feedback.map(function (
                              items,
                              index
                            ) {
                              return (
                                <div
                                  onClick={(e) => {
                                    select_dropdown(items);
                                  }}
                                  className="itemDrop"
                                  key={index}
                                >
                                  {items.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {customDateActive && (
                        <>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="startDate"
                                type="date"
                                placeholder="From Date"
                                defaultValue={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="endDate"
                                type="date"
                                placeholder="To Date"
                                defaultValue={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="downloadBtnAna">
                              <button>{Analytics_text.Search_text}</button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="downloadBtnAna">
                  <select>
                    <option value="select package" selected disabled hidden>
                      Select Package
                    </option>
                    <option value="Lite">Lite</option>
                    <option value="Standard">Standard</option>
                    <option value="Business">Business</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="analytics">
                <div className="analytics_container">
                  <div className="row m-0">
                    <div className="col-lg-6 col-md-12">
                      <div className="sales_dashboard">
                        <div className="heading_leftSection_todaysSales">
                          <div className="analytics_header">
                            <h3>Today's Sales</h3>
                            <p className="sub-header-analytics">
                              Sales Summery
                            </p>
                          </div>

                          <button className="export-button">Export</button>
                        </div>
                        <div className="Analytics_stats">
                          <div className="noOfSales">
                            <img src={redSales} alt="orangeSales" />
                            <div className="Analytics_stats_detailed">
                              <h5>{filteredDataRevenue.total_sales}</h5>
                              <p>Total no. of Sales</p>
                              <p className="blueText">+8% from last month</p>
                            </div>
                          </div>
                          <div className="totalRevenue">
                            <img src={orangeSales} alt="orangeSales" />
                            <div className="Analytics_stats_detailed">
                              <h5>₹ {filteredDataRevenue.total_revenue}</h5>
                              <p>Total Revenue</p>
                              <p className="blueText">+5% from last Month</p>
                            </div>
                          </div>
                          <div className="churnRate">
                            <img src={purpleSales} alt="orangeSales" />
                            <div className="Analytics_stats_detailed">
                              <h5>{filteredDataRevenue.churn_rate} %</h5>
                              <p>Churn Rate</p>
                              <p className="blueText">0.5% from Last Month</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="analyticsCardsContainer">
                        <div className="GuestStatusGraph">
                          <div className="GuestStatusGraphContainer">
                            <div className="GuestStatusGraphHead">
                              <div className="GuestStatusGraphLegends">
                                <div className="legendCOntainer">
                                  <h3>Visitors Insights</h3>
                                </div>
                              </div>
                            </div>
                            {!FirstTimeData && (
                              <div>
                                <Chart
                                  options={optionsLine}
                                  series={seriesLine}
                                  type="line"
                                  height={200}
                                />
                              </div>
                            )}
                            <div className="GuestStatusGraphHead">
                              <div className="GuestStatusGraphLegends">
                                <div className="legendCOntainer">
                                  <div className="legendColor arrivedLegend"></div>
                                  <p>Loyal Customers</p>
                                </div>
                                <div className="legendCOntainer">
                                  <div className="legendColor rescheduleLegend"></div>
                                  <p>Unique Customers</p>
                                </div>
                                <div className="legendCOntainer">
                                  <div className="legendColor cancelledLegend"></div>
                                  <p>New Customers</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* second row of charts starts  */}
                  <div className="row m-0">
                    <div className="col-lg-4 col-md-12">
                      <div className="bargraph_logoacademy sales_dashboard">
                        <div>
                          <h3>Total Revenue/Visitor</h3>
                        </div>
                        <div id="chart">
                          <ReactApexChart
                            options={optionsBarGraph}
                            series={seriesBarGraph}
                            type="bar"
                            height={300}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="piechart_container">
                        <div className="heading_piechart">
                          <h3>Bounce rate</h3>
                        </div>
                        <div id="Piechart">
                          <ReactApexChart
                            options={optionsPieChart}
                            series={seriesPieChart}
                            type="pie"
                            width={410}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="piechart_container Sidebarchart_container">
                        <div>
                          <h3>No. of Selling Package</h3>
                        </div>
                        <div id="chart">
                          <ReactApexChart
                            options={optionsSidebar}
                            series={seriesSidebar}
                            type="bar"
                            height={300}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* second row of charts ends  */}
                  <div className="feedBackTable">
                    <div className="dashBoardTableConatiner">
                      <div className="HeadingTitle">
                        <h5>Recent Product Sell</h5>
                        <div className="SearchTable">
                          <input
                            type="text"
                            placeholder="Client Name/Email ID"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="tableResponsive">
                        <div className="tableResponsive_container">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>Sales Date</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>ID</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>Client Name</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>Email ID</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>Package Name</span>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="theadStyle imgThead  ">
                                    <span>Package/Amount</span>
                                  </div>
                                </th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="tboday">
                              {!filteredDataSales
                                ? []
                                : filteredDataSales.map((option, index) => (
                                    <React.Fragment key={index}>
                                      <tr className="tableRow tbodyStyle">
                                        <td className="tabledata">
                                          <div className="guest_incenter shadowOnlyBottom">
                                            {formatDateStringdot(
                                              option.payment_date
                                            )}
                                          </div>
                                        </td>
                                        <td className="tabledata">
                                          <div className="guest_incenter shadowOnlyBottom">
                                            <button
                                              type="button"
                                              className={`markVip Mark_Vip $vipMarked`}
                                              onClick={() =>
                                                openModal(option, index, 1)
                                              }
                                            >
                                              <p> {option.customer_id}</p>
                                            </button>
                                          </div>
                                        </td>
                                        <td className="tabledata">
                                          <div className="guest_incenter shadowOnlyBottom">
                                            {/* {option.customer_details.length >
                                              0 &&
                                              `${option.customer_details[0].full_name}`} */}
                                            {option.full_name}
                                          </div>
                                        </td>

                                        <td className="tabledata">
                                          <div className="guest_incenter shadowOnlyBottom">
                                            {/* {option.customer_details.length >
                                              0 &&
                                              `${option.customer_details[0].user_moblie_no}`} */}
                                            {option.email_id}
                                          </div>
                                        </td>
                                        <td className="tabledata">
                                          <div className="guest_incenter shadowOnlyBottom">
                                            {RupeesLeft} {option.package_name}{" "}
                                            {RupeesRight}
                                          </div>
                                        </td>
                                        <td className="tabledata">
                                          <div className="guest_incenter shadowOnlyBottom">
                                            {RupeesLeft} {option.package_price}{" "}
                                            {RupeesRight}
                                          </div>
                                        </td>
                                        <td className="th2 tabledata">
                                          {option.order_status === 0 ? (
                                            <>
                                              <div className="guest_incenterActions borderRightRadius">
                                                <button
                                                  type="button"
                                                  className="markVip Mark_Vip vipMarked"
                                                  onClick={() =>
                                                    openModal(option, index, 2)
                                                  }
                                                >
                                                  <p>Accept</p>
                                                </button>
                                              </div>
                                              <div className="guest_incenterActions borderRightRadius">
                                                <button
                                                  type="button"
                                                  className="markVip Mark_Vip vipMarkedred"
                                                  onClick={() =>
                                                    openModal(option, index, 3)
                                                  }
                                                >
                                                  <p>Reject</p>
                                                </button>
                                              </div>
                                            </>
                                          ) : option.order_status === 1 ? (
                                            <>
                                              <div className="guest_incenterActions borderRightRadius">
                                                <button
                                                  type="button"
                                                  className="markVip Mark_Vip vipMarked"
                                                  onClick={() =>
                                                    openModal(option, index, 4)
                                                  }
                                                >
                                                  <p>Delivered</p>
                                                </button>
                                              </div>
                                              <div className="guest_incenterActions borderRightRadius">
                                                <button
                                                  type="button"
                                                  className="markVip Mark_Vip vipMarkedred"
                                                  onClick={() =>
                                                    openModal(option, index, 3)
                                                  }
                                                >
                                                  <p>Reject</p>
                                                </button>
                                              </div>
                                            </>
                                          ) : option.order_status === 2 ? (
                                            <div className="guest_incenterActions borderRightRadius">
                                              <button
                                                type="button"
                                                className="markVip Mark_Vip vipMarked"
                                              >
                                                <p>Delivered</p>
                                              </button>
                                            </div>
                                          ) : option.order_status === 3 ? (
                                            <div className="guest_incenterActions borderRightRadius">
                                              <button
                                                type="button"
                                                className="markVip Mark_Vip vipMarkedred"
                                              >
                                                <p>Reject</p>
                                              </button>
                                            </div>
                                          ) : null}
                                        </td>
                                      </tr>
                                      <tr
                                        key={`spacer-${index}`}
                                        style={{ height: "1rem" }}
                                      ></tr>
                                    </React.Fragment>
                                  ))}
                              {filteredData.length === 0 && (
                                <tr>
                                  <td
                                    colSpan={11}
                                    className="text_align_center"
                                  >
                                    No Results Found
                                  </td>{" "}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <Pagination>
                        <div className="paginationContainer">
                          <div className="nxtBttnTble">
                            {!currentItems && currentPage !== 1 ? (
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev > 1 ? prev - 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.Previous_text}
                              </button>
                            ) : null}
                          </div>
                          <div className="d-flex gap-2">
                            {renderPaginationItems()}
                          </div>
                          {!currentItems && (
                            <div className="nxtBttnTble">
                              <button
                                className="btn btn-primary"
                                disabled={currentPage === totalPageCount}
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    prev < totalPageCount ? prev + 1 : prev
                                  )
                                }
                              >
                                {Analytics_text.next_text}
                              </button>
                            </div>
                          )}
                        </div>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal show={showModal2} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            {" "}
            <form className="createRightsForm" id="createRightsForm">
              <div className="success_img  justify-content-center">
                <div className={`row`}>
                  <div className="inpContainer">
                    <label className="no_prsnl_id d-flex">
                      Reject Remark
                      <span>*</span>
                    </label>

                    <div>
                      <input
                        type="text"
                        id="disc_msg"
                        name="disc_msg"
                        maxLength={100}
                        onInput={handleIaphabetnumberChange}
                        className={`  trio_mandatory  input_field_customPadding form-control`}
                        placeholder="Description"
                      />

                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex, "createRightsForm")}
            >
              Yes
            </Button>
            <Button className="logoutNoBtn" onClick={closeModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal1} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              {/* ... Modal content goes here ... */}
            </div>

            <p>Are you sure you want to Accept This Order?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex, "")}
            >
              Yes
            </Button>
            <Button className="logoutNoBtn" onClick={closeModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal3} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              {/* ... Modal content goes here ... */}
            </div>

            <p>Are you sure you want to Deliver This Order?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="logoutYesBtn"
              onClick={() => confirmVIP(selectedGuestIndex, "")}
            >
              Yes
            </Button>
            <Button className="logoutNoBtn" onClick={closeModal}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal} onHide={closeModal} centered backdrop="static">
          <Modal.Body className="modal_body">
            <div className="success_img d-flex justify-content-center">
              <div className="invoice-box">
                <table cellPadding="0" cellSpacing="0">
                  <tr className="top_rw">
                    <td colSpan="2">
                      <h2 style={{ marginBottom: "0px" }}>Tax invoice</h2>
                    </td>
                  </tr>

                  <div className="row">
                    <div className="col">
                      <tr className="heading">
                        <td> Number: </td>
                      </tr>
                    </div>
                    <div className="col">
                      <tr className="heading">
                        <td> {SelectedData.customer_id}</td>
                      </tr>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <tr className="heading">
                        <td> Date: </td>
                      </tr>
                    </div>
                    <div className="col">
                      <tr className="heading">
                        <td>
                          {" "}
                          {formatDateStringdot(SelectedData.payment_date)}
                        </td>
                      </tr>
                    </div>
                  </div>

                  {SelectedData.order_type === 1 && (
                    <tr className="top">
                      <td colSpan="3">
                        <table>
                          <tr>
                            <td>
                              <b>Delivery Address:</b>
                              {SelectedData.full_address}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  )}

                  <tr className="information">
                    <td colSpan="3">
                      <table cellSpacing="0" cellPadding="2">
                        <div className="row">
                          <div className="col">
                            <tr className="heading">
                              <td> Package Name </td>
                            </tr>
                          </div>
                          <div className="col">
                            <tr
                              className="heading"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <td> {SelectedData.package_name}</td>
                            </tr>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <tr className="heading">
                              <td>Grand Total </td>
                            </tr>
                          </div>
                          <div className="col">
                            <tr
                              className="heading"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <td style={{ textAlign: "right" }}>
                                {RupeesLeft}
                                {SelectedData.package_price}/- {RupeesRight}
                              </td>
                            </tr>
                          </div>
                        </div>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="logoutNoBtn" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Dashboard;
