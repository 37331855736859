import React, { useState, useEffect } from "react";
import teamimage from "../assets/addImgImg.png";
import delicon from "../assets/delete.svg";
import { convertToRaw } from "draft-js";
import update from "../assets/editBlack.svg";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import { Modal, Button } from "react-bootstrap";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import Loader from "./Loader.js";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  empty_form,
  formatTimeintotwodigit,
  handleSuccess,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_blog,
  delete_blog,
  get_all_blogs,
} from "../ServiceConnection/serviceconnection.js";
import { BlogWebsite_text } from "../CommonJquery/WebsiteText";

const BlogWebsite = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [SelectedData, setSelectedData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [error_show, seterror_show] = useState("");

  const handleToggleForm = () => {
    setFormVisible(!formVisible);
  };
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  useEffect(() => {}, [editStaffData]);
  const handleDeactivate = () => {
    master_data_action_update(SelectedData.primary_id, "d");
  };

  const handleClose = () => {
    setModalShow(false);
  };
  const handleShow = (data_call) => {
    setSelectedData(data_call);
    setModalShow(true);
  };

  const handleEdit = (data_call) => {
    master_data_get("", "", "3", data_call.primary_id);
  };
  // const handleEditorChange = (event, editor) => {
  //   setEditorData(editor.getData());
  // };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_blogs, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_blog.length > 0) {
            seteditStaffData(Response.data.message.data_blog);

            if (flag === "3") {
              seteditStaffDataDetails(Response.data.message.data_blog[0]);
              setEditorDatMainID(Response.data.message.data_blog[0].primary_id);
              setEditorData(Response.data.message.data_blog[0].description);
              setEditorContent(Response.data.message.data_blog[0].description);
              setFormVisible(true);
            } else {
              seteditStaffDataDetails([]);
            }
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", call_id);
    fd.append("flag", "3");
    await server_post_data(delete_blog, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get("", "", "1", "0");
          window.location.reload();
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    // let valid_data = check_valid_save(form_data);
  
    if (form_data) {
      if (editorData.length > 0) {
        setshowLoaderAdmin(true);
  
        // Create FormData and append only non-null values
        let fd_from = new FormData();
        
        // Append form_data fields
        Object.keys(form_data).forEach(key => {
          if (form_data[key] !== null && form_data[key] !== undefined && form_data[key].trim() !== '') {
            fd_from.append(key, form_data[key]);
          }
        });
  
        // Append additional data
        fd_from.append("main_id", editorDataMainID);
        fd_from.append("description_data", editorData);
  
        // Send data to server
        try {
          const Response = await server_post_data(url_for_save, fd_from);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccess(Response.data.message);
            setFormVisible(false);
            empty_form(form_data);
          }
        } catch (error) {
          console.log(error);
          setshowLoaderAdmin(false);
        }
      } else {
        seterror_show("Please Fill The Mandatory Information");
      }
    }
  };

  //new editor code starts
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    const contentState = newState.getCurrentContent();
    const htmlData = draftToHtml(convertToRaw(contentState));
    setEditorData(htmlData); // Ensure editorData is updated
  };

  const setEditorContent = (htmlContent) => {
    const contentState = stateFromHTML(htmlContent);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  };
  // new editor
  return (
    <div>
      {showLoaderAdmin && <Loader />}
      {!formVisible && (
        <>
          <div className="headSeo">
            <button
              type="button"
              onClick={handleToggleForm}
              className="btn btn-secondary"
            >
              {BlogWebsite_text.Add_Blog}
            </button>
          </div>
          <div className="seoTable_container">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ color: "#505cca", fontWeight: "600" }}>
                    {BlogWebsite_text.Create_Date}
                  </th>
                  <th style={{ color: "#505cca", fontWeight: "600" }}>
                    {BlogWebsite_text.Image}
                  </th>
                  <th style={{ color: "#505cca", fontWeight: "600" }}>
                    {BlogWebsite_text.Title}
                  </th>

                  <th style={{ color: "#505cca", fontWeight: "600" }}>
                    {BlogWebsite_text.Description}
                  </th>
                  <th style={{ color: "#505cca", fontWeight: "600" }}>
                    {BlogWebsite_text.Keywords}
                  </th>
                </tr>
              </thead>
              <tbody>
                {editStaffData &&
                  editStaffData.length > 0 &&
                  editStaffData.map((option, index) => (
                    <React.Fragment key={index}>
                      <tr className="seoTable_row">
                        <td className="col-xs serial-span">
                          <div>
                            <span className="indexNumber">{1 + index}</span>
                            <p>{formatTimeintotwodigit(option.entry_date)}</p>
                          </div>
                        </td>
                        <td>
                          <img
                            src={option.image_url}
                            onError={(e) => (e.target.src = teamimage)}
                            alt="Barley's Dashboard"
                          />
                        </td>
                        <td className="col-md">
                          <div>{option.title_name}</div>
                        </td>
                        <td>
                          <div>{option.tag_line}</div>
                        </td>
                        <td>{option.author}</td>
                      </tr>
                      <tr className="actionRow">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <div className="img-icons mb-4">
                            <img
                              onClick={() => handleShow(option)}
                              className="del-icon"
                              src={delicon}
                              alt=""
                            />
                            <img
                              onClick={() => handleEdit(option)}
                              className=""
                              src={update}
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {formVisible && (
        <form id="addNewStaff">
          <div className="event-box blog-data">
            <button
              style={{ fontWeight: "500" }}
              type="button"
              className="btn custom-btn"
            >
              {BlogWebsite_text.Add_Blog}
            </button>

            <div className="form-group mt-4">
              <div className="row m-0">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="old_image_link"
                    id="old_image_link"
                    defaultValue={editStaffDataDetails.image_url || ""}
                    // onInput={handleURLChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Image URL*"
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    name="author_image_url"
                    id="author_image_url"
                    defaultValue={editStaffDataDetails.author_image_url || ""}
                    // onInput={handleURLChange}
                    className="form-control"
                    placeholder="Enter Author Image URL*"
                  />
                  <span className="condition_error"></span>
                </div>
                {/* <div className="col-md-6">
                  <input
                    type="text"
                    name="author_social_fb"
                    id="author_social_fb"
                    defaultValue={editStaffDataDetails.image_url || ""}
                    // onInput={handleURLChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Author Facebook URL*"
                  />
                  <span className="condition_error"></span>
                </div>

                <div className="col-md-6">
                  <input
                    type="text"
                    name="author_social_behance"
                    id="author_social_behance"
                    defaultValue={editStaffDataDetails.image_url || ""}
                    // onInput={handleURLChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Author Behance URL*"
                  />
                  <span className="condition_error"></span>
                </div>

                <div className="col-md-6">
                  <input
                    type="text"
                    name="author_social_x"
                    id="author_social_x"
                    defaultValue={editStaffDataDetails.image_url || ""}
                    // onInput={handleURLChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Author X URL*"
                  />
                  <span className="condition_error"></span>
                </div>

                <div className="col-md-6">
                  <input
                    type="text"
                    name="author_social_pinterest"
                    id="author_social_pinterest"
                    defaultValue={editStaffDataDetails.image_url || ""}
                    // onInput={handleURLChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Author Pinterest URL*"
                  />
                  <span className="condition_error"></span>
                </div> */}

                <div className="col-md-6">
                  <input
                    type="text"
                    id="author_data"
                    defaultValue={editStaffDataDetails.author || ""}
                    name="author_data"
                    // onInput={handleIaphabetnumberChange}
                    className="form-control trio_mandatory"
                    placeholder="Author*"
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    id="author_position"
                    defaultValue={editStaffDataDetails.author_position || ""}
                    name="author_position"
                    // onInput={handleIaphabetnumberChange}
                    className="form-control"
                    placeholder="Author Position*"
                  />
                  <span className="condition_error"></span>
                </div>
                {/* <div className="col-md-6 mt-2">
                  <input
                    type="text"
                    name="author_position"
                    defaultValue={editStaffDataDetails.author_position || ""}
                    id="author_position"
                    onInput={handleIaphabetnumberChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Author Position*"
                  />
                  <span className="condition_error"></span>
                </div> */}
                {/* <div className="col-md-6  mt-2">
                  <input
                    type="text"
                    name="author_image_url"
                    id="author_image_url"
                    defaultValue={editStaffDataDetails.author_image_url || ""}
                    // onInput={handleURLChange}
                    className="form-control trio_mandatory"
                    placeholder="Enter Author Image URL*"
                  />
                  <span className="condition_error"></span>
                </div> */}
                <div className="col-md-10 mt-2">
                  <input
                    type="text"
                    name="title_data"
                    defaultValue={editStaffDataDetails.title_name || ""}
                    id="title_data"
                    className="form-control trio_mandatory"
                    placeholder="Enter Title*"
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-2 mt-2">
                  <input
                    type="text"
                    name="read_min"
                    defaultValue={editStaffDataDetails.read_min || ""}
                    id="read_min"
                    className="form-control"
                    placeholder="Enter Read Min*"
                  />
                  <span className="condition_error"></span>
                </div>

                <div className="col-md-12">
                  <textarea
                    className="textareBlogs form-control trio_mandatory"
                    name="tagline_data"
                    id="tagline_data"
                    defaultValue={editStaffDataDetails.tag_line || ""}
                    placeholder="Enter Introduction*"
                  ></textarea>
                  <span className="condition_error"></span>
                </div>

                <div className={`col-md-12`}>
                  <div className="inpContainer mt-2">
                    <label className="no_prsnl_id">
                      {BlogWebsite_text.Description}
                      <span>*</span>
                    </label>
                    <div>
                      {/* <CKEditor
                        editor={ClassicEditor}
                        data={editStaffDataDetails.description || ""}
                        onChange={handleEditorChange}
                      /> */}
                      <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        toolbarClassName="fixed-toolbar"
                        onEditorStateChange={onEditorStateChange}
                      />
                    </div>
                    <span className="condition_error">{error_show}</span>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="addBlogBtnDiv">
                    <button
                      type="button"
                      onClick={() =>
                        handleSaveChangesdynamic(
                          "addNewStaff",
                          save_update_blog
                        )
                      }
                      className="btn btn-secondary mt-3 save-cap-btn"
                    >
                      {BlogWebsite_text.save_txt}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Delete This Data?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            {BlogWebsite_text.no_txt}
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={handleDeactivate}
          >
            <label
              style={{ cursor: "pointer" }}
              htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              {BlogWebsite_text.Yes_txt}
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default BlogWebsite;
