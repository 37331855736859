import React, { useState } from "react";
import Header from "./Header";
import AddImage from "./AddImage";
import SeoWebsite from "./SeoWebsite";
import BlogWebsite from "./BlogWebsite";

function WebsiteMngt() {
  const [addImage, setaddImage] = useState(true);
  const [blog, setblog] = useState(false);
  const [seo, setseo] = useState(false);

  const toggleSettingsBar = (TabId) => {
    setaddImage(TabId === "2");
    setblog(TabId === "3");
    setseo(TabId === "4");
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="settingsTabs">
                <div className="settingsTabs_container">
                  <ul>
                    <li className={`${addImage ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("2")}
                      >
                        <p>Add Image</p>
                      </div>
                    </li>
                    <li className={`${blog ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("3")}
                      >
                        <p>Blog</p>
                      </div>
                    </li>
                    <li className={`${seo ? "currentTab" : ""}`}>
                      <div
                        className="setTab setTab1"
                        onClick={() => toggleSettingsBar("4")}
                      >
                        <p>SEO</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="settingstabsContainer">
                {addImage && <AddImage />}
                {seo && <SeoWebsite />}
                {blog && <BlogWebsite />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteMngt;
