import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Css/icon.css";
import SearchIcn from "../assets/searchIcn.svg";

import wifiIcone from "../assets/wifirIcon.svg";
import CardIcone from "../assets/cardIcon.svg";
import KeyIcone from "../assets/keyIcon.svg";
import CheckIcone from "../assets/checkBoxxIcon.svg";

import crossIcn from "../assets/crossIcon.png";
import {
  server_post_data,
  get_icon_img,
  get_canvas_industry_icon_data,
  LastClickedIconsUrl,
} from "../ServiceConnection/serviceconnection.js";
import { handleError } from "../CommonJquery/CommonJquery";
import IconLoader from "./IconLoader.js";

const Icons = ({ handleClose, setIconData, handleSelectIcon }) => {
  const [lastClickedIcons, setLastClickedIcons] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [next_page, setnext_page] = useState("");
  const [currentScreen, setCurrentScreen] = useState(null);
  const [GetIcon, SetIconImg] = useState([]);
  const [GetSuggestion, SetGetSuggestion] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const flag = "1";
    const call_id = "10";

    master_data_NounImg(flag, call_id);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const showScreen = (screenName) => {
    setCurrentScreen(screenName);
    setIsActive(screenName === "suggestedScreen");
    setIsActive2(screenName === "badgesScreen");
    setIsActive3(screenName === "crownScreen");
    setIsActive4(screenName === "capScreen");
  };

  const handleClick = () => {
    handleClose();
  };
  // useEffect(() => {
  //   // Load last clicked icons from sessionStorage on component mount
  //   const storedIcons = sessionStorage.getItem("lastClickedIcons");
  //   if (storedIcons) {
  //     setLastClickedIcons(JSON.parse(storedIcons));
  //   }
  // }, []);
  const master_data_NounImg = async (flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("industry_id", 10);
    fd.append("user_id", 10);

    fd.append("query", "car");
    fd.append("limit_to_public_domain", 1); // Set to 1 to limit to public domain icons
    fd.append("thumbnail_size", 20); // Thumbnail size
    fd.append("limit", 100);
    fd.append("include_svg", 1);

    await server_post_data(get_canvas_industry_icon_data, fd)
      .then((Response) => {
        console.log(Response.data.message.suggestion.related_industry_name);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const relatedIndustryName =
            Response.data.message.suggestion.related_industry_name;

          // Convert the string to an array if necessary
          // const suggestionArray =
          //   typeof relatedIndustryName === "string"
          //     ? relatedIndustryName.split(",")
          //     : relatedIndustryName;
          // suggestionArray.unshift("Suggested");
          const Lasticonname = Response.data.message.lasticon_data;
          console.log(Lasticonname);
          const indexedUrls = Lasticonname.map((item, index) => ({
            index,
            url: item.url,
            thumbnail_url: item.thumbnail_url,
            lasticon_data: item.lasticon_data,
          }));
          setLastClickedIcons(indexedUrls);
          console.log(indexedUrls);
          document.querySelectorAll(".lasticon").forEach((element) => {
            element.style.display = "none";
            element.style.height = "0px";
          });

          document.querySelectorAll(".lconldr").forEach((element) => {
            element.style.display = "none";
            element.style.height = "0px";
          });

          //SetGetSuggestion(suggestionArray);
          //SetIconImg(Response.data.message.icondata.icons);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const searchIcons = async (searchvl,loadmore='') => {
    setshowLoaderAdmin(true);
    document.querySelectorAll(".lconldr").forEach((element) => {
      element.style.display = "flex";
      element.style.height = "35px";
    });
    if (searchvl == "Suggested") {
      const filteredSuggestions = GetSuggestion.filter(
        (item) => item !== "Suggested"
      );
      searchvl = filteredSuggestions.join(", ");
      console.log(searchvl);
    }
    try {
      const formData = new FormData();
      formData.append("query", searchvl);
      formData.append("limit_to_public_domain", 1); // Set to 1 to limit to public domain icons
      formData.append("thumbnail_size", 20); // Thumbnail size
      formData.append("limit", 10);
      formData.append("include_svg", 1);
      if(loadmore=='loadmore'){
        formData.append("next_page", next_page);
      }else{
        formData.append("next_page", "");
        SetIconImg([]);
      }
      
      
      const response = await axios.post(get_icon_img, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
        },
      });
      // Check if the response is successful (status code 200)
      if (response.status === 200) {
      if (response.data.message.icondata != 'Error fetching Noun Project icons') {

        setshowLoaderAdmin(false);
        // Handle the response data here
        if(loadmore=='loadmore'){
          SetIconImg((prevIcons) => [...prevIcons, ...response.data.message.icondata.icons]);
        }else{
          SetIconImg(response.data.message.icondata.icons); // Save the response data to state
        } 
        //console.log(response.data.message.icondata.icons.length);
        if (response.data.message.icondata.icons.length < 10) {
          document.querySelector('.loadMoreBTns').style.display = 'none';
      } else {
          document.querySelector('.loadMoreBTns').style.display = 'block';
      }
      
        
        document.querySelectorAll(".lconldr").forEach((element) => {
          element.style.display = "none";
          element.style.height = "0px";
        });
        //console.log("Icons fetched successfully:", response.data.message.icondata);
       setnext_page(response.data.message.icondata.next_page);
      } else {
        setshowLoaderAdmin(false);
        // Log an error message if the request was not successful
        console.error("Error fetching icons. Status:", response.status);
      }
    }
    } catch (error) {
      // Log any errors that occur during the request
      console.error("Error fetching icons:", error.message);
      setshowLoaderAdmin(false);
    }
  };

  const loadMoreIcons = async () => {
    setshowLoaderAdmin(true);
    console.log("loadingIcons");
    try {
      const formData = new FormData();
      formData.append("query", searchTerm);
      formData.append("limit_to_public_domain", 1); // Set to 1 to limit to public domain icons
      formData.append("thumbnail_size", 20); // Thumbnail size
      formData.append("limit", 100); // Number of icons to retrieve

      const response = await axios.post(get_icon_img, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the Content-Type header for form data
        },
      });

      // Check if the response is successful (status code 200)
      if (response.status === 200) {
        // Append the new icons to the existing list
        SetIconImg((prevIcons) => [...prevIcons, ...response.data.icons]);

        console.log(
          "Additional icons fetched successfully:",
          response.data.icons
        );
      } else {
        // Log an error message if the request was not successful
        console.error(
          "Error fetching additional icons. Status:",
          response.status
        );
      }
    } catch (error) {
      // Log any errors that occur during the request
      console.error("Error fetching additional icons:", error.message);
      setshowLoaderAdmin(false);
    }
  };

  const handleIconClick = async (index, url, ary) => {
    //console.log(ary);
    let thumbnail_url = ary.thumbnail_url;
    setIconData({ index, url, thumbnail_url });
    const isDuplicate = lastClickedIcons.some((icon) => icon.thumbnail_url === ary.thumbnail_url);

    if (!isDuplicate) {
    //const updatedIcons = [...lastClickedIcons, { index, url }];
    const updatedIcons = [{ index, url, thumbnail_url }, ...lastClickedIcons];
    //console.log(updatedIcons)
    setLastClickedIcons(updatedIcons);
    LastClickedIconsFn(index, url, ary.thumbnail_url);
    // try {
    //await LastClickedIconsFn(index, url,ary.thumbnail_url);
    // } catch (error) {
    //   console.error("Error in LastClickedIconsFn:", error);
    // }
    }
  };

  const LastClickedIconsFn = async (index, url, thumbnail_url) => {
    const fd = new FormData();

    fd.append("user_admin_id", 10);
    fd.append("user_admin", 0);

    fd.append("index", index);
    const response = await fetch(url);
    const svgText = await response.text();
    const base64Data = btoa(svgText);
    const base64Svg = `data:image/svg+xml;base64,${base64Data}`;
    fd.append("url", base64Svg);
    fd.append("thumbnail_url", thumbnail_url);
    await server_post_data(LastClickedIconsUrl, fd)
      .then((Response) => {
        //console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const handleIconClick2 = (iconPath) => {
    setIconData(iconPath);
    handleClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchIcons(searchTerm);
    }
  };
  //last use icon
  const [showAll, setShowAll] = useState(false);
  const [displayCount, setDisplayCount] = useState(3);

  const toggleShowAll = () => {
    if (showAll) {
      setDisplayCount(3);
    } else {
      setDisplayCount(lastClickedIcons.length);
    }
    setShowAll(!showAll);
  };
  //console.log(lastClickedIcons);

  return (
    <>
      <div className="TxtBtn2 ">
        <div className="crossIcn icn">
          <button onClick={handleClick}>
            <img src={crossIcn}></img>
          </button>
        </div>
        <div className="container serchBox">
          <div className="SerachIcn">
            {" "}
            <img className="SerachIcn" src={SearchIcn} alt="searchIcon"></img>
          </div>

          <input
            type="text"
            placeholder="Search icon here"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
          />
          <div className="iconSeacgBtnn">
            <button
              className="searchBtn"
              clastype="submit"
              onClick={() => searchIcons(searchTerm)}
            >
              Search
            </button>
          </div>
        </div>
        <div className="Butttns">
          {GetSuggestion.length > 0 &&
            GetSuggestion.map((person, index) => (
              <div key={index}>
                <button
                  className={`badges ${index === activeIndex ? "active" : ""}`}
                  onClick={() => {
                    setActiveIndex(index);
                    setSearchTerm(person);
                    searchIcons(person);
                  }}
                >
                  {person}
                </button>
              </div>
            ))}
        </div>
        <div className="iconget">
          <label>Last Used</label>

          <div className="container">
            {/* <div className="loaderIconCLass">
              {showLoaderAdmin && <IconLoader />}
            </div> */}
            <div className="SearchInpt2">
              {lastClickedIcons.length > 0 ? (
                lastClickedIcons
                  .slice(0, displayCount)
                  .map((icon, index, thumbnail_url) => {
                    // console.log('Icon:', icon);
                    // console.log('Index:', index);
                    // console.log('thumbnail_url:', icon.thumbnail_url);

                    return (
                      <div key={index}>
                        <div className="lastUsed">
                          <img
                            src={icon.thumbnail_url} //chng by mk last icon.url
                            alt={`lastUsedIcon-${index + 1}`}
                            onClick={() =>
                              handleIconClick(icon.index, icon.url,icon)
                            }
                          />
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="LoaderSearch lasticon">
                  <p>No icon Used</p>
                </div>
              )}
            </div>
          </div>
          <div className="ShowAllDiv mt-3">
            <button onClick={toggleShowAll}>
              {showAll ? "Hide" : "Show All"}
            </button>
          </div>
          <div
            className="iconget"
            style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
          >
            <div className="container">
              
              <div className="SearchInpt iconData">
                {/** -------Icon data Get---------------*/}
                {GetIcon.length > 0 ? (
                  GetIcon.map((person, index) => (
                    <div>
                      <div
                        key={index}
                        onClick={() =>
                          handleIconClick(index, person.icon_url, person)
                        }
                      >
                        <img
                          src={person.thumbnail_url}
                          alt={`Thumbnail ${index}`}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="LoaderSearch lasticon">
                    <p>No data Found</p>
                  </div>
                )}
              </div>
              <div className="loaderIconCLass">
                {showLoaderAdmin && <IconLoader />}
              </div>
              <div className="mt-3 loadMoreBTns">
                <button onClick={() =>
                              searchIcons(searchTerm,'loadmore')
                            }>
                  Load more
                </button>
              </div>
            </div>
          </div>
          {currentScreen === "suggestedScreen" && (
            <div className="SuggestionDiv">
              <label>Suggested</label>
              <div className="SerachIcnns2">
                <button>
                  <img src={wifiIcone}></img>
                </button>
                <button>
                  <img src={CardIcone}></img>
                </button>
                <button>
                  <img src={KeyIcone}></img>
                </button>
                <button>
                  <img src={CheckIcone}></img>
                </button>
              </div>
            </div>
          )}
          {/* Load More Button For Icon */}
          {/* <div className="ShowAllDiv mt-3 loadMoreBTn">
            <button onClick={loadMoreIcons}>
              <label>Load More</label>
            </button>
          </div> */}

          {currentScreen === "badgesScreen" && (
            <div className="BadgeDiv">
              <label>Badges</label>
            </div>
          )}

          {currentScreen === "crownScreen" && (
            <div className="CrownDiv">
              <label>Crown</label>
            </div>
          )}

          {currentScreen === "capScreen" && (
            <div className="CapDiv">
              <label>Cap</label>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Icons;
