import React from "react";
import "./Css/IconLoader.css";
// import Loader from "../Assets/Images/loader.gif";

const IconLoader = () => {
  return (
    <div>
      <div className="loaderIconImg">
        <div style={{ position: "relative" }}>
          <loader></loader>
        </div>
      </div>
    </div>
  );
};

export default IconLoader;
