import gif from "../assets/LoaderLogoAcdamy.gif";
import "../Components/Css/Loading.css";

function Loader() {
  return (
    <div>
      <div className="loading_overlay"></div>
      <div className="loading_website">
        <div className="restaurant-loader-inner">
          <img src={gif} alt="Loading GIF" />
        </div>
      </div>
    </div>
  );
}

export default Loader;
