import React, { useEffect, useState, useRef } from "react";
import { Pagination } from "react-bootstrap";
import Header from "./Header";
import Loader from "./Loader.js";
import Delete from "../assets/delete.svg";
import Edit from "../assets/edit_square.png";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  server_post_data,
  delete_Package,
  get_all_package_data,
  save_update_Package,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleConfimDeleteClick,
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleNumbersChange,
  handleAphabetsChange,
} from "../CommonJquery/CommonJquery";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
function ViewPackage() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [editStaffDataSelected, seteditStaffDataSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dynaicimage, setDynaicimage] = useState(null);

  const [DelteFaq, setDelteFaq] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get("", "", flag, call_id, "");
  }, []);

  const tableRef = useRef(null);

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    select_type
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("select_type", select_type);
    await server_post_data(get_all_package_data, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffData(Response.data.message.data);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const totalPageCount = Math.ceil(editStaffData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;

      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: file,
        [new_file_name]: reader.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  const handlePeriodChangedynamic = (name) => (event) => {
    console.log(name, event.target.value); // Add this line to debug the value
    // Ensure you're properly updating the state or handling the value
    // Example: setFormData({ ...formData, [name]: event.target.value });
  };

  const master_data_action = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", call_id);
    fd.append("flag", "3");
    await server_post_data(delete_Package, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    console.log("Fuction Called");
    // let vaild_data = check_vaild_save(form_data);
    if (form_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      console.log(editStaffDataSelected);
      console.log("fd_form");
      if (editStaffDataSelected && editStaffDataSelected.primary_id) {
        fd_from.append("main_id", editStaffDataSelected.primary_id);
        fd_from.append(
          "file_example_download_data_old",
          editStaffDataSelected.file_example_download
        );
      }
      fd_from.append("flag_data", "c");

      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            // handleSuccessSession(Response.data.message, "/view_package");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
          handleError("network");
        });
    }
  };
  const handleButtonClick = (index) => {
    if (index === null) {
      seteditStaffDataSelected(null);
    } else {
      console.log(editStaffData[index]);
      seteditStaffDataSelected(editStaffData[index]);
    }

    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    seteditStaffDataSelected(null);
  };

  const handlePackModalDelete = (id) => {
    setSelectedItemId(id);

    setDelteFaq(true);
  };
  const handleFaqyDelete = () => {
    if (selectedItemId) {
      master_data_action(selectedItemId);
      setDelteFaq(false);
    }
  };
  const handleCancelDelete = () => {
    setDelteFaq(false);
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>View Package</p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
              <Link onClick={() => handleButtonClick(null)}>
                <button type="button" className="btnAddStaff darkBg add_FAQ">
                  Add Package
                </button>
              </Link>
            </div>

            <div className="page_body">
              <div className="analytics">
                <div className="analytics_container mt-2">
                  <div className="analyticsCardsContainer">
                    <div className="feedBackTable">
                      <div className="feedBackTable_container">
                        <table
                          id="myTable"
                          className="display table"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="th3 col-3"
                                style={{ color: "white" }}
                              >
                                S. No.
                              </th>
                              <th
                                scope="col"
                                className="col-3"
                                style={{ color: "white" }}
                              >
                                Package Name
                              </th>
                              <th
                                scope="col"
                                className="col-3"
                                style={{ color: "white" }}
                              >
                                Price
                              </th>
                              <th
                                className="th4 col-3"
                                style={{ color: "white" }}
                              >
                                Action
                              </th>
                            </tr>
                            <tr style={{ height: "25px" }}></tr>
                          </thead>
                          <tbody>
                            {editStaffData.map((item, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td className="th3 col-3">
                                    <div className="recentANme">
                                      <p>{index + 1}</p>
                                    </div>
                                  </td>
                                  <td className="col-3">
                                    <div className="recentContact">
                                      <p>{item.package_name}</p>
                                    </div>
                                  </td>
                                  <td className="col-3">
                                    <div className="recentContact">
                                      <p>
                                        ₹<label>{item.Price}</label>
                                      </p>
                                    </div>
                                  </td>
                                  <td className="th4 col-3">
                                    <div className="recentReservBtns">
                                      <Link
                                        onClick={() => handleButtonClick(index)}
                                      >
                                        <button
                                          className="mb-2"
                                          style={{ width: "90px" }}
                                        >
                                          <p>Edit</p>
                                          <img
                                            style={{
                                              width: "18px",
                                              height: "18px",
                                            }}
                                            src={Edit}
                                            alt="Barley's Dashboard"
                                          />
                                        </button>
                                      </Link>
                                      <button
                                        className="mb-2"
                                        style={{ width: "90px" }}
                                        onClick={() =>
                                          handlePackModalDelete(item.primary_id)
                                        }
                                      >
                                        <p>Delete</p>
                                        <img
                                          src={Delete}
                                          alt="Barley's Dashboard"
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  style={{ height: "1rem", boxShadow: "none" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <Pagination>
                          <div className="paginationContainer">
                            <div className="nxtBttnTble">
                              {currentPage !== 1 ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    setCurrentPage((prev) =>
                                      prev > 1 ? prev - 1 : prev
                                    )
                                  }
                                >
                                  Previous
                                </button>
                              ) : null}
                            </div>
                            <div className="d-flex gap-2">
                              {renderPaginationItems()}
                            </div>
                          </div>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {editStaffDataSelected && editStaffDataSelected.primary_id
              ? "Update"
              : "Create"}{" "}
            Plan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="createRightsForm" id="createRightsForm">
            <div className="row m-0">
              <div className="personalForm">
                <div className="row m-0">
                  <div className={` d-flex col-md-12`}>
                    <div className={`col-md-8`}>
                      <div
                        className="inpContainer"
                        style={{ paddingRight: "15px" }}
                      >
                        <div>
                          <input
                            type="text"
                            id="package_name"
                            name="package_name_data"
                            defaultValue={
                              editStaffDataSelected &&
                              editStaffDataSelected.package_name
                                ? editStaffDataSelected.package_name
                                : ""
                            }
                            placeholder="Enter Plan Name"
                            onInput={handleAphabetsChange}
                            className={`  trio_mandatory  input_field_customPadding form-control`}
                          />

                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                    <div className={`col-md-4`}>
                      <div className="inpContainer">
                        <div>
                          <input
                            type="text"
                            id="Price"
                            name="price_data"
                            defaultValue={
                              editStaffDataSelected &&
                              editStaffDataSelected.Price
                                ? editStaffDataSelected.Price
                                : ""
                            }
                            placeholder="₹"
                            onInput={handleNumbersChange}
                            className={`trio_mandatory  input_field_customPadding form-control`}
                          />

                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={` d-flex col-md-12`}>
                    <div className={`col-md-6`}>
                      <div className="inpContainer">
                        <div>
                          <input
                            type="file"
                            name="file_example_download"
                            defaultValue={
                              editStaffDataSelected &&
                              editStaffDataSelected.file_example_download
                                ? editStaffDataSelected.file_example_download
                                : ""
                            }
                            onChange={handleFileChangedynamic(
                              "file_example_download"
                            )}
                            
                            className={`${
                              editStaffDataSelected &&
                              editStaffDataSelected.file_example_download
                                ? ""
                                : "trio_mandatory"
                            } form-control display_block`}
                            accept=".zip"
                            hidden
                            id="imgInput"
                          />

                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                    <div className={`col-md-6`}>
                      <div className="inpContainer">
                        <div>
                          <input
                            type="text"
                            id="package_days_data"
                            name="package_days_data"
                            defaultValue={
                              editStaffDataSelected &&
                              editStaffDataSelected.package_days
                                ? editStaffDataSelected.package_days
                                : ""
                            }
                            placeholder="Enter Package Edit days"
                            onInput={handleNumbersChange}
                            className={`trio_mandatory  input_field_customPadding form-control`}
                          />

                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={` d-flex col-md-12`}>
                    <div className={`col-md-6`}>
                      <div className="inpContainer">
                        <div>
                          <select
                            name="package_month_year"
                            id="package_month_year"
                            defaultValue={
                              editStaffDataSelected &&
                              editStaffDataSelected.package_month_year
                                ? editStaffDataSelected.package_month_year
                                : ""
                            }
                            onChange={handlePeriodChangedynamic}
                            className={`${
                              editStaffDataSelected &&
                              editStaffDataSelected.package_month_year
                                ? ""
                                : "trio_mandatory"
                            } form-control display_block`}
                          >
                            <option value="">Select Time Period</option>
                            <option value="Months">Months</option>
                            <option value="Years">Years</option>
                          </select>

                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                    <div className={`col-md-6`}>
                      <div className="inpContainer">
                        <div>
                          <input
                            type="text"
                            id="howmany_month_year"
                            name="howmany_month_year"
                            defaultValue={
                              editStaffDataSelected &&
                              editStaffDataSelected.howmany_month_year
                                ? editStaffDataSelected.howmany_month_year
                                : ""
                            }
                            placeholder="Enter Year/Month of"
                            onInput={handleNumbersChange}
                            className={`trio_mandatory  input_field_customPadding form-control`}
                          />

                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="about_logo"
                          name="about_logo"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.about_logo ===
                              "About Logo"
                              ? true
                              : false
                          }
                          value="About Logo"
                          className={`form-control checkbox_css`}
                        />
                        &nbsp; About Logo
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="resolution_data_other"
                          name="resolution_data_other"
                          value="High quality logo images"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.resolution ===
                              "High quality logo images"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;High quality logo images
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="vector_file_data"
                          name="vector_file_data"
                          value="Vectors File"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.vector_file === "Vectors File"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Vectors File
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="favicon_data"
                          name="favicon_data"
                          value="Favicon"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.favicon === "Favicon"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Favicon
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="watermark_data"
                          name="watermark_data"
                          value="Watermarks"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.watermark === "Watermarks"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Watermarks
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="resizing_design_data"
                          name="resizing_design_data"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.resizing_design ===
                              "Resizing designs"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                          value="Resizing designs"
                        />
                        &nbsp;Resizing designs
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="transparent_background_data"
                          name="transparent_background_data"
                          value="Transparent Background"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.transparent_background ===
                              "Transparent Background"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Transparent Background
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="transparent_background_data_other"
                          name="transparent_background_data_other"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.transparent_background ===
                              "Transparent Background"
                              ? true
                              : false
                          }
                          value="Transparent Background"
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;One logo file (no transparent file)
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="business_card_data"
                          name="business_card_data"
                          value="Business cards"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.business_card ===
                              "Business cards"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Business cards
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="brand_identity_data"
                          name="brand_identity_data"
                          value="Brandkit"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.brand_identity === "Brandkit"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Brandkit
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  {/* <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="brand_identity_data_other"
                          name="brand_identity_data_other"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.brand_identity ===
                              "Platform access"
                              ? true
                              : false
                          }
                          value="Platform access"
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Platform access
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>{" "} */}
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="downloading_rights_data"
                          name="downloading_rights_data"
                          value="Downloading Rights"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.downloading_rights ===
                              "Downloading Rights"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Downloading Rights
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="unlimited_changes_data"
                          name="unlimited_changes_data"
                          value="Make unlimited changes to your logos"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.unlimited_changes ===
                              "Make unlimited changes to your logos"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Make unlimited changes to your logos
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="copyright_ownership_data"
                          name="copyright_ownership_data"
                          value="Full copywrite ownership"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.copyright_ownership ===
                              "Full copywrite ownership"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Full copywrite ownership
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <div>
                        <input
                          type="checkbox"
                          id="support_data"
                          name="support_data"
                          value="Free Support"
                          defaultChecked={
                            editStaffDataSelected &&
                            editStaffDataSelected.support === "Free Support"
                              ? true
                              : false
                          }
                          className={`form-control checkbox_css`}
                        />
                        &nbsp;Free Support
                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createRightBtnDiv"></div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="createBtn"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button
            className="createBtn"
            variant="primary"
            onClick={handleCloseModal}
          >
            <button
              className="SaveBtnModal"
              type="button"
              onClick={() => {
                console.log("save Button");
                handleSaveChangesdynamic(
                  "createRightsForm",
                  save_update_Package
                );
              }}
            >
              {editStaffDataSelected && editStaffDataSelected.primary_id
                ? "Update"
                : "Create"}
            </button>
          </Button>
        </Modal.Footer>
      </Modal>

      {/**----Delete the template---- */}
      <Modal
        className="deleteModddl"
        show={DelteFaq}
        onHide={handleCancelDelete}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <div className="btttonDelete">
            {" "}
            <button onClick={handleCancelDelete}>No</button>
            <button onClick={handleFaqyDelete}>Yes</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewPackage;
