import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import dropdown from "../assets/arrow_drop_down_24px.svg";

import {
  GuestManagementPageText,
  options_select_drop_feedback,
  Analytics_text,
} from "./../CommonJquery/WebsiteText";
import {
  server_post_data,
  get_Enquiry,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  computeplussevendays,
} from "../CommonJquery/CommonJquery";
function Enquiry() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);

  const [customDateActive, setCustomDateActive] = useState(false);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());

  const [isActive, setIsActive] = useState(false);
  const [filteredData, setfilteredData] = useState([]);
  const [filteredData2, setfilteredData2] = useState([]);

  const [selected, setIsSelected] = useState(options_select_drop_feedback[0]);

  const [activeButton, setActiveButton] = useState("Recent Enquiry");

  const dropdownRef = useRef(null);

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    select_type
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    const current_date = new Date(); // Initialize current_date with the current date
    if (select_type === "today") {
      start_date = start_date_fn(current_date);
      end_date = end_date_fn(current_date);
    } else if (select_type === "last_7_days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      start_date = start_date_fn(sevenDaysAgo);
      end_date = end_date_fn(current_date);
    } else if (select_type === "this_month") {
      const firstDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        0
      );
      start_date = start_date_fn(firstDayOfMonth);
      end_date = end_date_fn(lastDayOfMonth);
    } else if (select_type === "last_month") {
      const firstDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() - 1,
        1
      );
      const lastDayOfLastMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        0
      );
      start_date = start_date_fn(firstDayOfLastMonth);
      end_date = end_date_fn(lastDayOfLastMonth);
    } else if (select_type === "this_year") {
      const firstDayOfYear = new Date(current_date.getFullYear(), 0, 1);

      start_date = start_date_fn(firstDayOfYear);
      end_date = end_date_fn(current_date);
    }

    function start_date_fn(start_date) {
      // Formatting start date
      const start_year = start_date.getFullYear();
      const start_month = (start_date.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const start_day = start_date.getDate().toString().padStart(2, "0");
      return `${start_year}-${start_month}-${start_day}`;
    }

    function end_date_fn(end_date) {
      // Formatting end date
      const end_year = end_date.getFullYear();
      const end_month = (end_date.getMonth() + 1).toString().padStart(2, "0");
      const end_day = end_date.getDate().toString().padStart(2, "0");
      return `${end_year}-${end_month}-${end_day}`;
    }

    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("select_type", select_type);

    await server_post_data(get_Enquiry, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          const dataArray = Response.data.message.data;

          // Initialize arrays for items with branding_or_designer_flag equal to 0 and 1
          const brandingFlagZeroArray = [];
          const brandingFlagOneArray = [];

          // Loop through the data array
          dataArray.forEach((item) => {
            if (item.branding_or_designer_flag == 0) {
              brandingFlagZeroArray.push(item);
            }
            // Check if branding_or_designer_flag is 1
            else if (item.branding_or_designer_flag == 1) {
              brandingFlagOneArray.push(item);
            }
          });
          setfilteredData(brandingFlagZeroArray);
          setfilteredData2(brandingFlagOneArray);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const select_dropdown = (itemsdata) => {
    setIsSelected(itemsdata);
    setIsActive(!isActive);

    if (itemsdata.datepicker_show) {
      setCustomDateActive(true);
    } else {
      setCustomDateActive(false);
      master_data_get("", "", "1", "", itemsdata.value);
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const truncateQuery = (query) => {
    const words = query.split(" ");
    const firstTenWords = words.slice(0, 10).join(" ");
    return firstTenWords + (words.length > 10 ? "..." : "");
  };

  const handleDropdownToggle = () => {
    setIsActive(!isActive);
  };

  const handleClickOutside1 = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  const [expanded, setExpanded] = useState({});

  const toggleMessage = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>Enquiry Management</p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="analyticFIlter">
                  <div className="col-xl-10">
                    <div className="row m-0">
                      <div className="col-md-4">
                        <div className="dropdownCustom" ref={dropdownRef}>
                          <div
                            onClick={handleDropdownToggle}
                            className="dropdownCustom-btn"
                          >
                            {Analytics_text.Period_text}: {selected.label}
                            <span
                              className={
                                isActive
                                  ? "fas fa-caret-up"
                                  : "fas fa-caret-down"
                              }
                            >
                              <img src={dropdown} alt="Barley's Dashboard" />
                            </span>
                          </div>
                          <div
                            className="dropdownCustom-content"
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            {options_select_drop_feedback.map(function (
                              items,
                              index
                            ) {
                              return (
                                <div
                                  onClick={(e) => {
                                    select_dropdown(items);
                                  }}
                                  className="itemDrop"
                                  key={index}
                                >
                                  {items.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {customDateActive && (
                        <>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="startDate"
                                type="date"
                                placeholder="From Date"
                                defaultValue={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="endDate"
                                type="date"
                                placeholder="To Date"
                                defaultValue={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="downloadBtnAna">
                              <button onClick={() => search_data()}>
                                {Analytics_text.Search_text}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="RcentCustom">
                  <div className={`recentEQ `}>
                    {" "}
                    <button
                      onClick={() => handleButtonClick("Recent Enquiry")}
                      className={` ${
                        activeButton === "Recent Enquiry" ? "active_enq" : ""
                      }`}
                    >
                      Recent Enquiry
                    </button>
                  </div>
                  <div className={`CustumerEQ`}>
                    {" "}
                    <button
                      onClick={() => handleButtonClick("Custom Design Request")}
                      className={`${
                        activeButton === "Custom Design Request"
                          ? "active_enq"
                          : ""
                      }`}
                    >
                      Custom Design Request
                    </button>
                  </div>
                </div>
                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              {activeButton === "Recent Enquiry" && (
                                <th scope="col">
                                  <div className="theadStyle imgThead   JusTiFStart2 ">
                                    <span>Entry Date</span>
                                  </div>
                                </th>
                              )}
                              {activeButton === "Custom Design Request" && (
                                <th scope="col">
                                  <div className="theadStyle JusTiFStart">
                                    Entry Date
                                  </div>
                                </th>
                              )}
                              <th scope="col">
                                <div className="theadStyle JusTiFStart">
                                  Name
                                </div>
                              </th>
                              {activeButton === "Recent Enquiry" && (
                                <th scope="col">
                                  <div className="theadStyle JusTiFStart">
                                    Email
                                  </div>
                                </th>
                              )}
                              {activeButton === "Recent Enquiry" && (
                                <th scope="col">
                                  <div className="theadStyle JusTiFStart">
                                    Query
                                  </div>
                                </th>
                              )}
                              {activeButton === "Custom Design Request" && (
                                <th scope="col">
                                  <div className="theadStyle JusTiFStart">
                                    Contact
                                  </div>
                                </th>
                              )}
                              {activeButton === "Custom Design Request" && (
                                <th scope="col">
                                  <div
                                    className="theadStyle JusTiFStart"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Business/Industry
                                  </div>
                                </th>
                              )}
                              {activeButton === "Custom Design Request" && (
                                <th scope="col">
                                  <div className="theadStyle JusTiFStart">
                                    Business Name
                                  </div>
                                </th>
                              )}

                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody className="tbody">
                            {activeButton === "Recent Enquiry" &&
                              (filteredData && filteredData.length > 0 ? (
                                filteredData.slice()
                                .reverse().map((option, index) => (
                                  <React.Fragment key={index}>
                                    <tr className="tableRow tbodyStyle">
                                      <td
                                        className="th1 tabledata col-3"
                                        style={{
                                          textAlign: "left",
                                          paddingLeft: "2rem",
                                        }}
                                      >
                                        {new Date(
                                          option.entry_date
                                        ).toLocaleDateString()}
                                      </td>
                                      <td
                                        className="tabledata col-3"
                                        style={{
                                          textAlign: "left",
                                          paddingLeft: "0.8rem",
                                        }}
                                      >
                                        {option.name}
                                      </td>
                                      <td
                                        className="tabledata col-3"
                                        style={{
                                          textAlign: "left",
                                          paddingLeft: "0.8rem",
                                        }}
                                      >
                                        {option.email_id}
                                      </td>
                                      <td
                                        className="tabledata col-3"
                                        style={{
                                          textAlign: "left",
                                          paddingLeft: "0.9rem",
                                        }}
                                      >
                                        {expanded[option.primary_id]
                                          ? option.query
                                          : option.query.length > 50
                                          ? option.query.substring(0, 50) +
                                            "..."
                                          : option.query}
                                        {option.query.length > 50 && (
                                          <button
                                            onClick={() =>
                                              toggleMessage(option.primary_id)
                                            }
                                            className="TructeSHomoreBtn"
                                          >
                                            {expanded[option.primary_id]
                                              ? "Read Less"
                                              : "Read More"}
                                          </button>
                                        )}
                                      </td>
                                      <td className="tabledata col-3">
                                        {option.business_name}
                                      </td>
                                    </tr>
                                    <tr
                                      key={`spacer-${index}`}
                                      style={{ height: "1rem" }}
                                      className="col-12"
                                    ></tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={5}
                                    className="text_align_center col-12"
                                  >
                                    No Results Found
                                  </td>
                                </tr>
                              ))}

                            {activeButton === "Custom Design Request" &&
                              (filteredData2 && filteredData2.length > 0 ? (
                                filteredData2.map(
                                  (customOption, customIndex) => (
                                    <React.Fragment key={customIndex}>
                                      <tr className="tableRow tbodyStyle">
                                        <td
                                          className="th1 tabledata col-3 tableTestAlign"
                                          style={{
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          {new Date(
                                            customOption.entry_date
                                          ).toLocaleDateString()}
                                        </td>
                                        <td
                                          className="tabledata col-3 tableTestAlign"
                                          style={{
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          {customOption.name}
                                        </td>
                                        <td
                                          className="tabledata col-3 tableTestAlign"
                                          style={{
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          {customOption.phone_number}
                                        </td>
                                        <td
                                          className="tabledata col-3 tableTestAlign"
                                          style={{
                                            paddingLeft: "1rem",
                                          }}
                                        >
                                          {customOption.business_industry}
                                        </td>
                                        <td
                                          className="tabledata col-3 tableTestAlign"
                                          style={{
                                            whiteSpace: "nowrap",
                                            paddingLeft: "0.9rem",
                                          }}
                                        >
                                          {customOption.business_name}
                                        </td>
                                      </tr>
                                      <tr
                                        key={`spacer-${customIndex}`}
                                        style={{ height: "1rem" }}
                                        className="col-12"
                                      ></tr>
                                    </React.Fragment>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={5}
                                    className="text_align_center col-12"
                                  >
                                    No Results Found
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enquiry;
