import React from "react";
import "./Css/Sales.css";
import Header from "./Header";
import upiImg from "../assets/upiImg.png";
import card from "../assets/Credit-card-108.png";
import LayerWeb from "../assets/Layer_x0020_1.png";
import { Pagination } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { Analytics_text } from "../CommonJquery/WebsiteText.js";
import dropdown from "../assets/arrow_drop_down_24px.svg";
import {
  formatDateStringdot,
  handleError,
  computeTodayDate,
  computeplussevendays,
} from "../CommonJquery/CommonJquery.js";

import {
  server_post_data,
  get_all_package_data,
  get_users_with_packages,
} from "../ServiceConnection/serviceconnection.js";

import {
  GuestManagementPageText,
  options_select_drop_feedback,
} from "./../CommonJquery/WebsiteText";

const Sales = () => {
  const tableRef = useRef(null);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isActive, setIsActive] = useState(false);
  const [filteredData, setfilteredData] = useState([]);
  const [SalesData, settotalSalesData] = useState();
  const [SelectedData, setSelectedData] = useState([]);
  const [RupeesLeft, setsRupeesLeft] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [RupeesRight, setsRupeesRight] = useState("");
  const currentItems = filteredData;
  const totalPageCount = Math.ceil(filteredData.length / itemsPerPage);
  const dropdownRef = useRef(null);
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [selected, setIsSelected] = useState(options_select_drop_feedback[0]);
  const [customDateActive, setCustomDateActive] = useState(false);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index, flag) => {
    setSelectedData(guestName);
    setSelectedGuestIndex(flag);
    if (flag === 1) {
      setShowModal(true);
    } else if (flag === 2) {
      setShowModal1(true);
    } else if (flag === 3) {
      setShowModal2(true);
    } else if (flag === 4) {
      setShowModal3(true);
    }
  };
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          // onClick={() => paginate(number)}
          onClick={() => handlePageClick(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  console.log(startDate)
  console.log(endDate)

  const select_dropdown = (itemsdata) => {
    setIsSelected(itemsdata);
    setIsActive(!isActive);

    if (itemsdata.datepicker_show) {
      setCustomDateActive(true);
    } else {
      setCustomDateActive(false);
      master_data_get("", "", "1", "", itemsdata.value);
    }
  };


  const handleDropdownToggle = () => {
    setIsActive(!isActive);
  };

  const handleClickOutside1 = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  const downloadCSV = () => {
    const headers = ["Sales Date", "Client Name", "Package", "Amount"];
    const rows = filteredData.map((item) => [
      formatDateStringdot(item.payment_date),
      item.full_name,
      item.package_name,
      item.package_price,
    ]);
  
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\n";
    rows.forEach((rowArray) => {
      const row = rowArray.join(",");
      csvContent += row + "\n";
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sales_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  const master_data_get = async (start_date, end_date, flag, call_id, select_type) => {
    setshowLoaderAdmin(true);
    
    const fd = new FormData();
    const current_date = new Date(); // Initialize current_date with the current date
    
    // Compute the date range based on select_type
    if (select_type === "today") {
      start_date = start_date_fn(current_date);
      end_date = end_date_fn(current_date);
    } else if (select_type === "last_7_days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      start_date = start_date_fn(sevenDaysAgo);
      end_date = end_date_fn(current_date);
    } else if (select_type === "this_month") {
      const firstDayOfMonth = new Date(current_date.getFullYear(), current_date.getMonth(), 1);
      const lastDayOfMonth = new Date(current_date.getFullYear(), current_date.getMonth() + 1, 0);
      start_date = start_date_fn(firstDayOfMonth);
      end_date = end_date_fn(lastDayOfMonth);
    } else if (select_type === "last_month") {
      const firstDayOfLastMonth = new Date(current_date.getFullYear(), current_date.getMonth() - 1, 1);
      const lastDayOfLastMonth = new Date(current_date.getFullYear(), current_date.getMonth(), 0);
      start_date = start_date_fn(firstDayOfLastMonth);
      end_date = end_date_fn(lastDayOfLastMonth);
    } else if (select_type === "this_year") {
      const firstDayOfYear = new Date(current_date.getFullYear(), 0, 1);
      start_date = start_date_fn(firstDayOfYear);
      end_date = end_date_fn(current_date);
    }
  
    function start_date_fn(date) {
      // Formatting start date
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  
    function end_date_fn(date) {
      // Formatting end date
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  
    // Add parameters to FormData
    fd.append('start_date', start_date !== undefined ? start_date : "");
    fd.append('end_date', end_date !== undefined ? end_date : "");
    fd.append('flag', flag !== undefined ? flag : "");
    fd.append('call_id', call_id !== undefined ? call_id : "");
    try {
      const response = await server_post_data(get_users_with_packages, fd);
      if (response.data.error) {
        handleError(response.data.message);
      } else {
        setfilteredData(response.data.message.data);
        settotalSalesData(response.data.message.total_sales);
      }
    } catch (error) {
      console.log(error);
      handleError("network");
    } finally {
      setshowLoaderAdmin(false);
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);
  return (
    <>
      <Header />

      <div className="container-lg">
        <div className="container-fluid">
          <div className="heroOfsales">
            <div className="paymentHeader">
              <h1>Payment Gateway Collection </h1>
              <p>Total Sales Amount </p>
              <span>₹ {SalesData}/-</span>
            </div>
            <div className="PaymentsMethods">
              {/* <div className="d-flex">
              <img src={upiImg} alt="upi"></img>
              <div className="d-flex  flex-column">
                <label>Card</label>
                <label>₹ 8000/-</label>
              </div>
            </div> */}
              <div className="d-flex">
                <img src={card} alt="upi"></img>
                <div className="d-flex  flex-column">
                  <label>Card</label>
                  <label>₹ {SalesData}/-</label>
                </div>
              </div>
              {/* <div className="d-flex">
              <img src={LayerWeb} alt="upi"></img>
              <div className="d-flex  flex-column">
                <label>Net Banking</label>
                <label>₹ 50000/-</label>
              </div>
            </div> */}
            <button className="btn btn-primary" onClick={downloadCSV}>
                Download CSV
              </button>
            </div>
          </div>
        </div>

        {/* <div className="page_body">
              <div className="viewStaff"> */}
                <div className="analyticFIlter">
                  <div className="col-xl-10">
                    <div className="row m-0">
                      <div className="col-md-4">
                        <div className="dropdownCustom" ref={dropdownRef}>
                          <div
                            onClick={handleDropdownToggle}
                            className="dropdownCustom-btn"
                          >
                            {Analytics_text.Period_text}: {selected.label}
                            <span
                              className={
                                isActive
                                  ? "fas fa-caret-up"
                                  : "fas fa-caret-down"
                              }
                            >
                              <img src={dropdown} alt="Barley's Dashboard" />
                            </span>
                          </div>
                          <div
                            className="dropdownCustom-content"
                            style={{ display: isActive ? "block" : "none" }}
                          >
                            {options_select_drop_feedback.map(function (
                              items,
                              index
                            ) {
                              return (
                                <div
                                  onClick={(e) => {
                                    select_dropdown(items);
                                  }}
                                  className="itemDrop"
                                  key={index}
                                >
                                  {items.label}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      {customDateActive && (
                        <>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="startDate"
                                type="date"
                                placeholder="From Date"
                                defaultValue={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 bottomAlgin">
                            <div className="person__calenderFrame_image image_icon_position1 ">
                              <input
                                autoComplete="off"
                                id="endDate"
                                type="date"
                                placeholder="To Date"
                                defaultValue={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control  input_field_custom4 backcolorWhite"
                                max={computeTodayDate()}
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="downloadBtnAna">
                              <button onClick={() => search_data()}>
                                {Analytics_text.Search_text}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                

        {/*---------Table of payments------*/}
        <div className="container-fluid">
          <div className="feedBackTable">
            <div className="feedBackTable_container" style={{ padding: "0px" }}>
              <h5>Recent Product Sell</h5>
              <div className="tableResponsive" style={{ borderRadius: "10px" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="theadStyle imgThead  ">
                          <span style={{ color: "white" }}>Sales Date</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="theadStyle imgThead  ">
                          <span style={{ color: "white" }}>Client Name</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="theadStyle imgThead  ">
                          <span style={{ color: "white" }}>Package</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="theadStyle imgThead  ">
                          <span style={{ color: "white" }}>Amount</span>
                        </div>
                      </th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="tboday">
                    {!currentItems
                      ? []
                      : currentItems.map((option, index) => (
                          <React.Fragment key={index}>
                            <tr className="tableRow tbodyStyle">
                              <td className="tabledata">
                                <div className="guest_incenter shadowOnlyBottom">
                                  {formatDateStringdot(option.payment_date)}
                                </div>
                              </td>
                              <td className="tabledata">
                                <div className="guest_incenter shadowOnlyBottom">
                                  {option.full_name}
                                </div>
                              </td>
                              <td className="tabledata">
                                <div className="guest_incenter shadowOnlyBottom">
                                  {/* <button
                                    type="button"
                                    className={`markVip Mark_Vip $vipMarked`}
                                    onClick={() => openModal(option, index, 1)}
                                    style={{ cursor: "default" }}
                                  > */}
                                    <p> {option.package_name}</p>
                                  {/* </button> */}
                                </div>
                              </td>
                              <td className="tabledata">
                                <div className="guest_incenter shadowOnlyBottom">
                                  {RupeesLeft}₹ {option.package_price}{" "}
                                  {RupeesRight}
                                </div>
                              </td>
                            </tr>
                            <tr
                              key={`spacer-${index}`}
                              style={{ height: "1rem" }}
                            ></tr>
                          </React.Fragment>
                        ))}
                    {currentItems.length === 0 && (
                      <tr>
                        <td colSpan={5} className="text_align_center">
                          No Results Found
                        </td>{" "}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination>
                <div className="paginationContainer">
                  <div className="nxtBttnTble">
                    {!currentItems && currentPage !== 1 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                        }
                      >
                        {Analytics_text.Previous_text}
                      </button>
                    ) : null}
                  </div>
                  {/* <div className="d-flex gap-2">{renderPaginationItems()}</div> */}
                  {!currentItems && (
                    <div className="nxtBttnTble">
                      <button
                        className="btn btn-primary"
                        disabled={currentPage === totalPageCount}
                        onClick={() =>
                          setCurrentPage((prev) =>
                            prev < totalPageCount ? prev + 1 : prev
                          )
                        }
                      >
                        {Analytics_text.next_text}
                      </button>
                    </div>
                  )}
                </div>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sales;
