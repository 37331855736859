import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "./Components/Css/Login.css";
import "./Components/Css/Analytics.css";
import "./Components/Css/AddStaff.css";
import "./Components/Css/WebsiteMngmt.css";
import "./Components/Css/Settings.css";
import "./Components/Css/ViewTesti.css";
import "./Components/Css/AddIndustry.css";
import "./Components/Css/StaffRights.css";
import "./Components/Css/Header.css";
import "./Components/Css/Loading.css";
import "./Components/Css/SideBar.css";
import "./Components/Css/TempCanvas.css";
import "./Components/Css/Viewtemplate.css";
import "./Components/Css/Canvas.css";
import "./Components/Css/TextEdit.css";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Add_Faqs from "./Components/Add_Faqs";
import ViewFaq from "./Components/ViewFaq";
import Usermanagement from "./Components/Usermanagement";
import AddStaff from "./Components/AddStaff";
import ViewStaff from "./Components/ViewStaff";
import ProfileSettings from "./Components/ProfileSettings";
import WebsiteMngt from "./Components/WebsiteMngmt";
import ViewPackage from "./Components/ViewPackage";
import Viewtestimonial from "./Components/Viewtestimonial";
import AddTestimonial from "./Components/AddTestimonial";
import ViewIndustry from "./Components/ViewIndustry";
import Enquiry from "./Components/Enquiry";
import ViewSearchMaster from "./Components/ViewSearchMaster";
import Add_SearchMaster from "./Components/Add_SearchMaster";
import AddDesignation from "./Components/AddDesignation";
import ViewDesignation from "./Components/ViewDesignation";
import CreateStaffRights from "./Components/CreateStaffRights";
import ViewStaffRights from "./Components/ViewStaffRights";
import CreateSubStaffRights from "./Components/CreateSubStaffRights";
import ViewSubRights from "./Components/ViewSubRights";
import InsidePageRightsView from "./Components/InsidePageRightsView";
import CreateInsidePageRights from "./Components/CreateInsidePageRights";
import Sales from "./Components/Sales";
import Master from "./Components/Master";
import AddMaster from "./Components/AddMaster";
/**old */

import ViewTemplates from "./Components/ViewTemplates";
import CanvasEditor from "./Components/CanvasEditor";

function App() {
  useEffect(() => {
    const inputElements = document.getElementsByTagName("input");
    Array.from(inputElements).forEach((input) => {
      input.setAttribute("autocomplete", "off");
    });
  }, [Array]);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Working Screen */}
          <Route path="/" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Add_Faq" element={<Add_Faqs />} />
          <Route path="/View_Faq" element={<ViewFaq />} />
          <Route path="/Edit_Faq/:id" element={<Add_Faqs />} />
          <Route path="/user_management" element={<Usermanagement />} />
          <Route path="/View_Staff" element={<ViewStaff />} />
          <Route path="/Add_Staff" element={<AddStaff />} />
          <Route path="/edit_staff/:id" element={<AddStaff />} />
          <Route path="/Profile_Settings" element={<ProfileSettings />} />

          <Route path="/Website_Management" element={<WebsiteMngt />} />
          <Route path="/view_package" element={<ViewPackage />} />
          <Route path="/View_testestimonial" element={<Viewtestimonial />} />
          <Route path="/add_testestimonial" element={<AddTestimonial />} />
          <Route path="/edit_testestimonial/:id" element={<AddTestimonial />} />
          <Route path="/view_industry" element={<ViewIndustry />} />
          <Route path="/View_enquiry" element={<Enquiry />} />
          <Route path="/ViewSearchMaster" element={<ViewSearchMaster />} />
          <Route path="/Add_SearchMaster" element={<Add_SearchMaster />} />
          <Route path="/Edit_SearchMaster/:id" element={<Add_SearchMaster />} />
          <Route path="/Add_Designation" element={<AddDesignation />} />
          <Route path="/Edit_Designation/:id" element={<AddDesignation />} />
          <Route path="/ViewDesignation" element={<ViewDesignation />} />
          <Route path="/Create_Staff_Rights" element={<CreateStaffRights />} />
          <Route path="/View_Staff_Rights" element={<ViewStaffRights />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/View_master" element={<Master />} />
          <Route path="/add_master" element={<AddMaster />} />
          <Route path="/edit_master/:id" element={<AddMaster />} />
          <Route
            path="/Edit_Staff_Rights/:id"
            element={<CreateStaffRights />}
          />
          <Route path="/View_Sub_Rights/:id" element={<ViewSubRights />} />
          <Route
            path="/Create_SubStaff_Rights/:id"
            element={<CreateSubStaffRights />}
          />

          <Route
            path="/Edit_SubStaff_Rights/:id"
            element={<CreateSubStaffRights />}
          />
          <Route
            path="/View_Inside_Page_Rights/:id"
            element={<InsidePageRightsView />}
          />
          <Route
            path="/Create_Inside_Page_Rights/:id"
            element={<CreateInsidePageRights />}
          />
          <Route path="/view_templates" element={<ViewTemplates />} />
          {/*not Working Screen */}
          <Route path="/admin_canvas_new" element={<CanvasEditor />} />
          <Route path="/admin_canvas/:id" element={<CanvasEditor />} />
          <Route
            path="/admin_canvas_dupticate/:id"
            element={<CanvasEditor />}
          />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
