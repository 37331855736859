import React, { useEffect, useState, useRef } from "react";
import { Pagination } from "react-bootstrap";
import Header from "./Header";
import Loader from "./Loader.js";
import Delete from "../assets/delete.svg";
import Edit from "../assets/edit_square.png";
import GreyArrow from "../assets/greyLeftAr.png";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select/creatable";
import "./Css/AddIndustry.css";
import {
  server_post_data,
  get_Industry,
  save_update_industry,
  delete_industry,
} from "../ServiceConnection/serviceconnection.js";
import {
  CreateStaffRightText,
  EditStaffPage,
  sidebarpagetext,
} from "../CommonJquery/WebsiteText";
import {
  check_vaild_save,
  combiled_form_data,
  handleConfimDeleteClick,
  handleError,
  handleAphabetsChange,
  handleSuccess,
  formatDateStringdot,
} from "../CommonJquery/CommonJquery";

function ViewIndustry() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editIndustryData, seteditIndustryData] = useState([]);
  const [industryDataSingle, setindustryDataSingle] = useState([]);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [DelteIndustry, setDelteIndustry] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  const tableRef = useRef(null);

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    select_type
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("select_type", select_type);
    await server_post_data(get_Industry, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditIndustryData(Response.data.message.data);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const totalPageCount = Math.ceil(editIndustryData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const master_data_action = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", call_id);
    fd.append("flag", "3");
    await server_post_data(delete_industry, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  const handleButtonClick = (index) => {
    if (index !== null) {
      const industryData = editIndustryData[index];
      setindustryDataSingle(industryData);
      // Parse the saved comma-separated values back into the format the Select component expects
      if (industryData.related_industry_name) {
        const relatedIndustryNames = industryData.related_industry_name
          .split(",")
          .map((value) => ({ value, label: value }));
        setSelectedOptions(relatedIndustryNames);
      }

      setEditorDatMainID(industryData.primary_id);
    } else {
      setindustryDataSingle(null);
      setSelectedOptions(0);
      setEditorDatMainID(0);
    }

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("main_id", editorDataMainID);
      // Extract only the 'value' properties from selectedOptions
      const valuesOnly = selectedOptions
        .map((option) => option.value)
        .join(",");
      if (valuesOnly) {
        handleCloseModal();
        setshowLoaderAdmin(true);
        // Append the comma-separated string to fd_from
        fd_from.append("related_industry_name_data", valuesOnly);
        await server_post_data(url_for_save, fd_from)
          .then((Response) => {
            setshowLoaderAdmin(false);
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              handleSuccess(Response.data.message);
              master_data_get("", "", "1", "0");
            }
          })
          .catch((error) => {
            handleError("network");
            setshowLoaderAdmin(false);
          });
      } else {
        handleError("Please Add Industry keyword");
      }
    }
  };

  //mutli Selector Function
  const handleKeyDown = (event) => {
    try {
      if (!inputValue) return;
      if (event.key === "Enter" && inputValue) {
        event.preventDefault();
        const newOption = { value: inputValue, label: inputValue };
        setSelectedOptions((prevOptions) => [
          ...(Array.isArray(prevOptions) ? prevOptions : []),
          newOption,
        ]);
        setInputValue("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleIndustryModalDelete = (id) => {
    setSelectedItemId(id);

    setDelteIndustry(true);
  };
  const handleCinfirmIndustryDelete = () => {
    if (selectedItemId) {
      master_data_action(selectedItemId);
      setDelteIndustry(false);
    }
  };
  const handleCancelDelete = () => {
    setDelteIndustry(false);
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>View Industry</p>
                <img src={GreyArrow} alt="Barley's Dashboard" />
              </div>
              <div>
                {" "}
                <Button
                  onClick={() => handleButtonClick(null)}
                  variant="primary  btnAddStaff darkBg add_FAQ"
                >
                  Add Industry
                </Button>
              </div>
            </div>

            <div className="page_body">
              <div className="analytics">
                <div className="analytics_container mt-2">
                  <div className="analyticsCardsContainer">
                    <div className="feedBackTable">
                      <div className="feedBackTable_container">
                        <table
                          id="myTable"
                          className="display table"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="th3"
                                style={{ color: "white" }}
                              >
                                S. No.
                              </th>
                              <th scope="col" style={{ color: "white" }}>
                                Entry Date
                              </th>

                              <th scope="col" style={{ color: "white" }}>
                                Industry Name
                              </th>
                              <th scope="col" style={{ color: "white" }}>
                                Industry Keywords
                              </th>
                              <th className="th4" style={{ color: "white" }}>
                                Action
                              </th>
                            </tr>
                            <tr style={{ height: "25px" }}></tr>
                          </thead>
                          <tbody>
                            {!editIndustryData
                              ? []
                              : editIndustryData.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td className="th3">
                                        <div className="recentANme">
                                          <p>{index + 1}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="recentContact">
                                          <p>
                                            {formatDateStringdot(
                                              item.entry_date
                                            )}
                                          </p>
                                        </div>
                                      </td>

                                      <td>
                                        <div className="recentContact">
                                          <p>
                                            {item.industry_name.slice(0, 20)}
                                            {item.industry_name.length > 20
                                              ? `...`
                                              : ``}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="recentContact">
                                          <p>
                                            {item.related_industry_name.slice(
                                              0,
                                              20
                                            )}
                                            {item.related_industry_name.length >
                                            20
                                              ? `...`
                                              : ``}
                                          </p>
                                        </div>
                                      </td>
                                      <td className="th4">
                                        <div className="recentReservBtns">
                                          <button
                                            className="mb-2"
                                            style={{ width: "90px" }}
                                            onClick={() => {
                                              handleButtonClick(index);
                                            }}
                                          >
                                            <p>Edit</p>
                                            <img
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                              }}
                                              src={Edit}
                                              alt="Barley's Dashboard"
                                            />
                                          </button>

                                          <button
                                            className="mb-2"
                                            style={{ width: "90px" }}
                                            onClick={
                                              () =>
                                                handleIndustryModalDelete(
                                                  item.primary_id
                                                )
                                              //master_data_action(item.primary_id)
                                            }
                                          >
                                            <p>Delete</p>
                                            <img
                                              src={Delete}
                                              alt="Barley's Dashboard"
                                            />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr
                                      style={{
                                        height: "1rem",
                                        boxShadow: "none",
                                      }}
                                    ></tr>
                                  </React.Fragment>
                                ))}
                          </tbody>
                        </table>
                        <Pagination>
                          <div className="paginationContainer">
                            <div className="nxtBttnTble">
                              {currentPage !== 1 ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    setCurrentPage((prev) =>
                                      prev > 1 ? prev - 1 : prev
                                    )
                                  }
                                >
                                  Previous
                                </button>
                              ) : null}
                            </div>
                            <div className="d-flex gap-2">
                              {renderPaginationItems()}
                            </div>
                          </div>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {industryDataSingle && industryDataSingle.related_industry_name
              ? EditStaffPage.save_text
              : CreateStaffRightText.Create_text}{" "}
            Industry
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="createRightsForm" id="createRightsForm">
            <div className="row m-0">
              <div className="personalForm">
                <div className="row m-0">
                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <label style={{ color: "#666666" }}>
                        {sidebarpagetext.Industry_Edit_text1}
                        <span>*</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          id="question"
                          name="industry_name_data"
                          className={`  trio_mandatory  input_field_customPadding form-control`}
                          placeholder={sidebarpagetext.Industry_Edit_text1}
                          onInput={handleAphabetsChange}
                          defaultValue={
                            industryDataSingle &&
                            industryDataSingle.industry_name
                              ? industryDataSingle.industry_name
                              : ""
                          }
                        />

                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>

                  <div className={`col-md-12`}>
                    <div className="inpContainer">
                      <label style={{ color: "#666666" }}>
                        Categories
                        <span>*</span>
                      </label>
                      <div>
                        <Select
                          isMulti
                          inputValue={inputValue}
                          onInputChange={handleInputChange}
                          className={`trio_mandatory`}
                          onKeyDown={handleKeyDown}
                          onChange={setSelectedOptions}
                          placeholder="Related Industry"
                          value={selectedOptions}
                          defaultValue={
                            industryDataSingle &&
                            industryDataSingle.industry_name
                              ? industryDataSingle.related_industry_name
                              : []
                          }
                          closeMenuOnSelect={false}
                          menuIsOpen={false}
                          components={{
                            DropdownIndicator: false,
                            IndicatorSeparator: false,
                          }}
                        />

                        <span className="condition_error"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createRightBtnDiv"></div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="createBtn"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
          <Button className="createBtn" variant="primary">
            <button
              className="SaveBtnModal"
              type="button"
              onClick={() =>
                handleSaveChangesdynamic(
                  "createRightsForm",
                  save_update_industry
                )
              }
            >
              {industryDataSingle && industryDataSingle.related_industry_name
                ? EditStaffPage.save_text
                : CreateStaffRightText.Create_text}
            </button>
          </Button>
        </Modal.Footer>
      </Modal>

      {/**----Delete the template---- */}
      <Modal
        className="deleteModddl"
        show={DelteIndustry}
        onHide={handleCancelDelete}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <div className="btttonDelete">
            {" "}
            <button onClick={handleCancelDelete}>No</button>
            <button onClick={handleCinfirmIndustryDelete}>Yes</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewIndustry;
